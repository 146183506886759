<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { useGetMaterialList } from '@/entities/Materials';
import type { Material } from '@/shared/interfaces';
import { BottomButtons, HandleRequest, PageHeading } from '@/shared/ui';

const { materialList, materialListFetchError, reloadFetchRequest } = useGetMaterialList();

const id = ref<string>('');
const name = ref<string>('');
const searchQuery = ref<boolean>(false);
const results = ref<Material[]>([]);

const allowSearch = computed(() => id.value || name.value);

watchEffect(() => {
  if (!id.value && !name.value) {
    results.value = [];
    searchQuery.value = false;
  }
});

const onSearchClick = () => {
  results.value =
    materialList.value?.EX_MATERIAL_LIST.filter(
      material =>
        (id.value && material.MATNR.toLowerCase().includes(id.value.toLowerCase())) ||
        (name.value && material.MAKTX.toLowerCase().includes(name.value.toLowerCase())),
    ) || [];
  searchQuery.value = true;
};
</script>

<template>
  <PageHeading text="Поиск запасов" :goBack="true" />
  <main :class="$style.main">
    <div :class="$style.card">
      <div :class="$style.formField">
        <a-typography-text>Код материала</a-typography-text>
        <a-input v-model:value="id" placeholder="Введите код материала" allow-clear />
      </div>
      <div :class="$style.formField">
        <a-typography-text>Название материала</a-typography-text>
        <a-input v-model:value="name" placeholder="Введите название материала" allow-clear />
      </div>
    </div>
    <div v-if="results.length" :class="$style.results">
      <a-typography-text :class="$style.title">
        <div>Результаты поиска</div>
        <div :class="$style.resultsCount">{{ results.length }}</div>
      </a-typography-text>
      <div v-for="material in results" :key="material.MATNR" :class="$style.card">
        <div :class="$style.cardRow">
          <div>
            <div :class="$style.subtitle">{{ parseInt(material.MATNR) }}</div>
            <div>{{ material.MAKTX }}</div>
            <div :class="$style.subtitle">{{ material.LGOBE }}</div>
          </div>
          <div :class="$style.count">{{ material.LABST }} {{ material.MSEHT }}</div>
        </div>
      </div>
    </div>
    <HandleRequest
      v-if="searchQuery && !results.length"
      :noData="true"
      :errorRequest="materialListFetchError"
      :onRepeat="reloadFetchRequest"
      :noFixed="true"
    />
  </main>
  <BottomButtons>
    <a-button type="primary" :disabled="!allowSearch" size="large" @click="onSearchClick">
      Найти
    </a-button>
  </BottomButtons>
</template>

<style lang="scss" module>
.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;
  padding-bottom: 100px;
}

.card {
  background-color: var(--colorBackground, white);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  .subtitle {
    color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
    font-size: 13px;
    line-height: 1.4;
  }

  .cardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .count {
    white-space: nowrap;
  }
}

.formField {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  input {
    font-size: 16px !important;
    width: 100%;
  }

  span[class^='ant-input-affix-wrapper'] {
    padding: 8px 11px !important;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.results {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resultsCount {
    color: var(--color-weak, #999);
    font-size: 12px;
    line-height: 20px;
    padding: 0px 4px;
    display: flex;
    align-items: center;
    border-radius: var(--Tag-borderRadiusSM, 4px);
    background: #f3f3f3;
  }
}
</style>

import {
  OrderSubTypes,
  OrderTypes,
  OrderTypesName,
  type OrdersHeaders,
  type OrdersHistoryResponse,
  type OrdersResponse,
} from '../interfaces';

export const checkOrderIsInspection = (order: OrdersHeaders) => {
  const { AUART, ILART } = order;
  if (AUART === OrderTypes.CORRECTION || AUART === OrderTypes.PLAN) {
    return ILART === OrderSubTypes.PERIODICAL_REPAIR;
  }
  return false;
};

export const checkOrderIsTechnicalMaintenance = (order: OrdersHeaders) => {
  const { AUART, ILART } = order;
  if (AUART === OrderTypes.CORRECTION || AUART === OrderTypes.PLAN) {
    switch (ILART) {
      case OrderSubTypes.TECHNICAL_MAINTENANCE:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_1:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_2:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_3:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_4:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_5:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_6:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_7:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_8:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_9:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_10:
      case OrderSubTypes.TECHNICAL_MAINTENANCE_11:
        return true;
      default:
        return false;
    }
  }
  return false;
};

export const checkOrderIsCurrentRepair = (order: OrdersHeaders) => {
  const { AUART, ILART } = order;
  if (AUART === OrderTypes.CORRECTION || AUART === OrderTypes.PLAN) {
    switch (ILART) {
      case OrderSubTypes.CURRENT_REPAIR:
      case OrderSubTypes.CURRENT_REPAIR_1:
      case OrderSubTypes.CURRENT_REPAIR_2:
      case OrderSubTypes.CURRENT_REPAIR_3:
      case OrderSubTypes.AVERAGE_REPAIR:
        return true;
      default:
        return false;
    }
  } else if (AUART === OrderTypes.EMERGENCY && ILART === OrderSubTypes.EMERGENCY_REPAIR) {
    return true;
  }
  return false;
};

export const checkOrderMaintenanceOrRepair = (order?: OrdersHeaders) => {
  if (!order) return false;
  return checkOrderIsTechnicalMaintenance(order) || checkOrderIsCurrentRepair(order);
};

export const getOrdersByType = (
  orders: OrdersHistoryResponse['EX_HEADERS'] | undefined,
): {
  [key in OrderTypesName]: OrdersHeaders[];
} => {
  const ordersByType = {
    [OrderTypesName.INSPECTION]: [] as OrdersHeaders[],
    [OrderTypesName.MAINTENANCE]: [] as OrdersHeaders[],
    [OrderTypesName.CURRENT_REPAIR]: [] as OrdersHeaders[],
    [OrderTypesName.ALL]: [] as OrdersHeaders[],
  };

  if (orders) {
    orders.forEach(order => {
      if (checkOrderIsInspection(order)) {
        ordersByType.ALL.push(order); //нельзя добавлять любой заказ в т.к. может прийти заказ который не подходит ни под один из типов
        ordersByType.INSPECTION.push(order);
      } else if (checkOrderIsTechnicalMaintenance(order)) {
        ordersByType.ALL.push(order);
        ordersByType.MAINTENANCE.push(order);
      } else if (checkOrderIsCurrentRepair(order)) {
        ordersByType.ALL.push(order);
        ordersByType.CURRENT_REPAIR.push(order);
      }
    });
  }
  return ordersByType;
};

export const clearOrdersWithIncorrectType = (orders: OrdersResponse) => {
  return {
    ...orders,
    EX_HEADERS: orders.EX_HEADERS.filter(
      order => checkOrderIsInspection(order) || checkOrderMaintenanceOrRepair(order),
    ),
  };
};

import axios from 'axios';
import { createResponseInterceptor } from 'fmptools';

export const httpFmpClient = axios.create({
  adapter: 'fetch',
  baseURL: `${import.meta.env.VITE_FMP_BACKEND}/api/v1/`,
  timeout: 600000,
  headers: {},
  validateStatus: status => status !== 401, //this is provide to return any Status Code except authorization
});

export const httpBackendClient = axios.create({
  adapter: 'fetch',
  baseURL: `${import.meta.env.VITE_ERG_BACKEND}/api/v1/`,
  timeout: 600000,
  headers: {},
  validateStatus: status => status !== 401,
});

export const schema = {};
export const setSchema = (newSchema: Record<string, any>) => Object.assign(schema, newSchema);
export const getSchema = () => schema;

httpFmpClient.interceptors.response.use(createResponseInterceptor(schema));

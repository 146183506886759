import { ref } from 'vue';
import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useStorage } from '@/shared/hooks';
import { STORAGE_ORDERS_OP } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { fetchOrdersOperPlan } from '../api/fetchOrdersOperPlan';
import { type OrdersOperPlan, type OrdersOperPlanRequest } from '../interfaces/Orders';

export const useOrdersOperPlanStore = defineStore('ordersOperPlan', () => {
  const ordersOperPlan = ref<OrdersOperPlan | undefined>(undefined);
  const ordersOperPlanFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setItem } = useStorage();

  const fetchOrdersOperPlanAsync = async (request: OrdersOperPlanRequest) => {
    const tryBlock = async () => {
      ordersOperPlanFetchError.value = false;
      const response: AxiosResponse<OrdersOperPlan> = await fetchOrdersOperPlan(request);
      if (handleResponse(response)) {
        ordersOperPlan.value = response.data || {};
        await setItem(STORAGE_ORDERS_OP, JSON.stringify(response.data));
      } else {
        ordersOperPlanFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchOrdersOperPlanAsync' });
  };

  return {
    ordersOperPlan,
    ordersOperPlanFetchError,
    fetchOrdersOperPlanAsync,
  };
});

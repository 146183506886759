import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@/shared/hooks';
import { STORAGE_USERS } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getUsersList } from '../api/getUsersList';
import type { UsersList } from '../interfaces';

export const useUsersListStore = defineStore('users_list', () => {
  const usersList = ref<UsersList[] | undefined>(undefined);
  const loading = ref(false);
  const usersListFetchError = ref(false);

  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setItem } = useStorage();

  const fetchUsersListAsync = async (areaId: string, silent = false) => {
    const tryBlock = async () => {
      usersListFetchError.value = false;
      const response = await getUsersList(areaId);
      if (handleResponse(response)) {
        usersList.value = response.data.data as UsersList[];
        await setItem(STORAGE_USERS, JSON.stringify(response.data.data));
      } else {
        usersListFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchUsersListAsync', noGlobalLoader: silent });
  };

  return {
    usersList,
    loading,
    fetchUsersListAsync,
  };
});

import type { UserInformationResponse } from '@/shared/interfaces';
import type { DefectsListData } from '../interfaces';

export const mapUserInfoWithDefects = (
  defects: DefectsListData[],
  userInformation: UserInformationResponse,
) => {
  return defects.map(item => {
    const infoItem = userInformation.data.find(info => info.sap === item.QMNAM);
    if (infoItem) {
      return { ...item, userInfo: infoItem };
    }
    return item;
  });
};

<script setup lang="ts">
import { ref, watch } from 'vue';
import { debounce } from 'throttle-debounce';
import { MinusIcon, PlusIcon } from '@/shared/assets/svg';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { HorizontalSwipeAble, InformationDialog } from '@/shared/ui';
import type { FormStateMaterial } from '../..';

interface Props {
  materials: FormStateMaterial[];
  formMaterial: FormStateMaterial;
  index: number;
  showListing?: boolean;
  showStore?: boolean;
  swipeAble?: boolean;
  disabled?: boolean;
  onDismiss?: (id: string) => void;
  onSelect?: (id: string, checked: boolean, quantity?: string) => void;
}

const {
  materials,
  formMaterial,
  index,
  showListing,
  showStore,
  swipeAble,
  disabled,
  onDismiss,
  onSelect,
} = defineProps<Props>();
const errorModalDigits = ref<string | undefined>('');
const handleToggleErrorModal = (value: string) => (errorModalDigits.value = value);

const getValueToFixed = (value: string | number) =>
  Number(value).toFixed(formMaterial.ANDEC && formMaterial.ANDEC);
const getDigitValue = (digit: number) => (!digit ? 1 : Number(1 / 10 ** digit));

const checkingValue = (value: string) => {
  if (
    +(Number(value) * 10 ** formMaterial.ANDEC).toFixed(formMaterial.ANDEC && formMaterial.ANDEC) %
      1 ===
    0
  ) {
    errorModalDigits.value = '';
    if (Number(value) > formMaterial.max) {
      return formMaterial.max;
    } else if (Number(value) <= 0) {
      return 0;
    }

    return !formMaterial.ANDEC ? Math.trunc(+value) : value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else {
    errorModalDigits.value = formMaterial.ANDEC
      ? `Неверное значение для материала. Необходимо ${formMaterial.ANDEC} знака после запятой`
      : `Неверное значение для материала. Необходимо целое число`;
    return getValueToFixed(value);
  }
};

const decrementMaterialCount = (materialId: string) => {
  const material = materials.find(material => material.MATNR === materialId);
  if (material) {
    material.MENGE = +getValueToFixed(material.MENGE - getDigitValue(material.ANDEC));
    onSelect?.(formMaterial.MATNR, formMaterial.isUsing, material.MENGE.toString());
  }
};

const { setAlert } = useAlertStore();

const notifyQuantityOverflow = () => {
  const maxCount = formMaterial.max || formMaterial.MAX || formMaterial.MENGE;
  const unit = formMaterial.MSEHT?.replace('.', '');
  setAlert({
    type: 'error',
    message: `Введенное значение превышает плановое количество. Плановое количество этого материала составляет ${maxCount} ${unit}.`,
  });
};

const incrementMaterialCount = (materialId: string) => {
  const material = materials.find(material => material.MATNR === materialId);
  if (material) {
    const newVal = +getValueToFixed(material.MENGE + getDigitValue(material.ANDEC));
    if (newVal > material.max) {
      material.MENGE = material.max;
    } else {
      material.MENGE = +getValueToFixed(material.MENGE + getDigitValue(material.ANDEC));
    }

    onSelect?.(formMaterial.MATNR, formMaterial.isUsing, material.MENGE.toString());
  }
};

watch(
  () => formMaterial.MENGE,
  debounce(500, newValue => {
    if (newValue > formMaterial.max) notifyQuantityOverflow();
  }),
);

const componentItem = swipeAble ? HorizontalSwipeAble : 'div';
</script>

<template>
  <componentItem
    :class="{ [$style.wrap]: true, [$style.listing]: showListing }"
    :onRightSlotClick="onDismiss"
    :itemId="formMaterial.MATNR"
  >
    <a-checkbox
      v-model="formMaterial.isUsing"
      v-if="!showListing"
      :class="$style.checkbox"
      :disabled="disabled"
      :checked="formMaterial.isUsing"
      @change="
        (e: Event) => {
          onSelect?.(
            formMaterial.MATNR,
            (e.target as HTMLInputElement).checked,
            getValueToFixed(formMaterial.MENGE),
          );
          formMaterial.isUsing = (e.target as HTMLInputElement).checked;
        }
      "
    />
    <div :class="$style.row">
      <div :class="$style.info">
        <a-typography-text :class="$style.label"
          >{{ !showListing ? '№ОЗМ ' : '' }}{{ parseInt(formMaterial.MATNR) }}</a-typography-text
        >
        <a-typography-text :class="$style.text">{{ formMaterial.MAKTX }}</a-typography-text>
        <a-typography-text v-if="showListing" :class="$style.amount"
          >Количество на складе:
          {{ `${formMaterial.max} ${formMaterial.MSEHT}` }}</a-typography-text
        >
        <a-typography-text v-if="showStore" :class="$style.amount"
          >Склад: {{ formMaterial.LGOBE }}</a-typography-text
        >
      </div>
      <div :class="$style.controls">
        <a-button
          size="medium"
          @click="() => decrementMaterialCount(formMaterial.MATNR)"
          :class="$style.button"
          :disabled="disabled || !formMaterial.MENGE"
          ><MinusIcon />
        </a-button>
        <a-input-number
          :class="$style.input"
          v-model:value="formMaterial.MENGE"
          :disabled="disabled"
          size="medium"
          :min="0"
          @change="
            (value: string) => {
              const inputValue = checkingValue(value);
              onSelect?.(formMaterial.MATNR, formMaterial.isUsing, getValueToFixed(inputValue));
              return inputValue;
            }
          "
          :formatter="(value: string) => checkingValue(value.toString())"
        />
        <a-button
          size="medium"
          @click="() => incrementMaterialCount(formMaterial.MATNR)"
          :class="$style.button"
          :disabled="disabled || materials[index].MENGE === formMaterial.max"
          ><PlusIcon />
        </a-button>
        <a-typography-text v-if="!showListing" :class="$style.label">{{
          formMaterial.MSEHT
        }}</a-typography-text>
      </div>
    </div>
  </componentItem>

  <InformationDialog
    v-if="!!errorModalDigits"
    title="Ошибка"
    :text="errorModalDigits"
    :onClose="() => handleToggleErrorModal('')"
  />
</template>

<style lang="scss" module>
.wrap {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.checkbox {
  span[class^='ant-checkbox-inner'] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  span[class^='ant-checkbox-inner']:after {
    top: 11px;
    left: 8px;
  }
}

.label {
  font-size: 15px;
  line-height: 140%;
  color: var(--color-weak, #999);
  text-wrap: nowrap;
}

.amount {
  font-size: 15px;
  line-height: 140%;
  color: var(--color-weak, #999);
}

.text {
  display: block;
  font-size: 17px;
  line-height: 140%;
}

.row {
  background-color: var(--color-white, #fff);
  position: relative;

  .listing & {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    padding: 12px 12px 12px 16px;
    border-radius: 6px;
  }
}

.info {
  display: flex;
  flex-direction: column;
}

.controls {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  .listing & {
    margin-top: 0;
    flex-shrink: 0;
    width: 144px;
  }
}

.input {
  height: 32px;
  max-width: 140px;
}

.button {
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    color: var(--color-text, #333333);
  }
}
</style>

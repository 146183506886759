import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getFiles } from '@/shared/api/getFiles';
import type { FilesListResponse } from '../interfaces';
import type { FilesData } from '../interfaces/FilesListResponse';
import { useAlertStore } from './useAlertStore';
import { useLoadingStore } from './useLoadingStore';

export const useFileStore = defineStore('files', () => {
  const filesList = ref([] as FilesData[]);
  const filesFetchError = ref(false);
  const loading = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const getFilesByIds = (ids: string[]) => {
    return filesList.value.filter(file => ids.includes(file.FILE_ID));
  };

  const fetchFileAsync = async (fileIds: string[], silent = false) => {
    const tryBlock = async () => {
      filesFetchError.value = false;
      const response = await getFiles(fileIds);

      if (handleResponse(response)) {
        const files = response.data as FilesListResponse;

        if (files.EX_FILES_DATA.length) {
          const filesIds = files.EX_FILES_DATA.map(file => file.FILE_ID);

          const findFile = filesList.value.find(file => filesIds.includes(file.FILE_ID));

          if (!findFile) {
            filesList.value.push(...files.EX_FILES_DATA);
          }
        }
      } else {
        filesFetchError.value = true;
      }
    };
    const findFile = filesList.value.find(file => fileIds.includes(file.FILE_ID));
    if (!findFile) {
      await handleLoading({ tryBlock, funcName: 'fetchFileAsync', noGlobalLoader: silent });
    }
  };

  return {
    filesList,
    filesFetchError,
    loading,
    getFilesByIds,
    fetchFileAsync,
  };
});

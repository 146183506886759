import { httpFmpClient } from '@/shared/api';
import type { CreateConfirmInput } from '@/shared/interfaces';
import { API_CREATE_CONFIRM } from '@/shared/lib';

export const fetchCreateConfirm = ({ IM_DATA, IM_VORNR, IM_MATERIALS = [] }: CreateConfirmInput) =>
  httpFmpClient.post(`/rpc/${API_CREATE_CONFIRM}/`, {
    IM_DATA,
    IM_VORNR,
    IM_MATERIALS, //Todo временно работает только на деве пока не перенесут сап на прод
  });

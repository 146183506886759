<script setup lang="ts">
import { ref } from 'vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  EquipmentLinesList,
  useGetEquipmentNodesByParentLines,
  useGetFuncLocksWerksAndAreas,
} from '@/entities/Equipments';
import {
  NAV_DEFECT_CREATE_FROM_EQUIPMENT,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
} from '@/shared/lib';
import { HandleRequest, PageHeading, Search } from '@/shared/ui';
import styles from './EquipmentLines.module.scss';

const router = useRouter();
const { workshopId, areaId } = useRoute().params;

const {
  funcLocByParentNode,
  funcLockFetchError,
  reloadFetchRequest: reloadFuncLocks,
} = useGetFuncLocksWerksAndAreas();

const {
  equipmentList,
  equipmentSearchValue,
  equipmentNodesByParentLines,
  equipmentListFetchError,
  reloadFetchRequest: reloadEquipmentList,
} = useGetEquipmentNodesByParentLines();

const areaName = computed(
  () =>
    funcLocByParentNode?.value?.[workshopId as string].find(area => area.TPLNR === areaId)?.PLTXT,
);

const showEquipmentList = ref<boolean>(false);
const showSearchResult = ref<boolean>(true);
const clearInputValue = ref();

const onFocus = () => {
  showEquipmentList.value = true;
  showSearchResult.value = true;
};

const onBlur = () => {
  equipmentSearchValue.value = clearInputValue.value?.inputValue ?? '';
};

const onReload = () => {
  if (funcLockFetchError) {
    reloadFuncLocks();
  }

  if (equipmentListFetchError) {
    reloadEquipmentList();
  }
};

const equipmentOptions = computed(() =>
  equipmentList.value?.EX_EQUIP_LIST?.map(equipment => ({
    id: equipment.EQUNR,
    name: equipment.EQKTX,
  })),
);

const handleBack = () => {
  if (showEquipmentList.value) {
    showEquipmentList.value = false;
    showSearchResult.value = false;
    clearInputValue.value.resetForm();
  } else {
    router.back();
  }
  equipmentSearchValue.value = '';
};
</script>

<template>
  <div :class="styles.defect">
    <PageHeading :text="areaName || ''" :goBack="true" :onHandleBack="handleBack" />
    <HandleRequest
      :noData="!funcLocByParentNode || !equipmentNodesByParentLines"
      :errorRequest="funcLockFetchError || equipmentListFetchError"
      :onRepeat="onReload"
    />
    <template v-if="funcLocByParentNode && equipmentNodesByParentLines && !funcLockFetchError">
      <Search
        v-if="equipmentOptions"
        placeholder="Поиск"
        :urlName="
          $route.name === NAV_EQUIPMENT_LINES_CREATE_DEFECT
            ? NAV_DEFECT_CREATE_FROM_EQUIPMENT
            : NAV_EQUIPMENT_DETAIL
        "
        :items="equipmentOptions"
        paramName="equipmentId"
        :onFocus="onFocus"
        :onBlur="onBlur"
        :defaultValue="equipmentSearchValue"
        :debounceTime="500"
        :minSearchLength="3"
        ref="clearInputValue"
        :showSearchResult="showSearchResult"
      />
      <div :class="styles.body" v-show="!showEquipmentList">
        <EquipmentLinesList
          v-if="funcLocByParentNode && equipmentNodesByParentLines"
          :areaId="areaId as string"
          :funcLocByParentNode="funcLocByParentNode"
          :equipmentNodesByParentLines="equipmentNodesByParentLines"
        />
      </div>
    </template>
  </div>
</template>

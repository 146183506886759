<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import EmptyData from './EmptyData.vue';
import ErrorRequest from './ErrorRequest.vue';
import styles from './HandleRequest.module.scss';

interface Props {
  noData: boolean;
  errorRequest?: boolean;
  onRepeat?: () => void;
  noFixed?: boolean;
  textEmpty?: string;
}

const { noData, errorRequest, onRepeat, noFixed, textEmpty } = defineProps<Props>();
const loadingStore = useLoadingStore();
const { loading } = storeToRefs(loadingStore);
</script>

<template>
  <EmptyData
    v-if="noData && !errorRequest && !Boolean(loading.length)"
    :noFixed="noFixed"
    :textEmpty="textEmpty"
  />
  <ErrorRequest
    v-if="errorRequest"
    :onRepeat="onRepeat"
    :repeatDisabled="Boolean(loading.length)"
    :noFixed="noFixed"
  />
</template>

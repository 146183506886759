import { OrderStatus, type OrdersOperList } from '../interfaces/Orders';

export const getOrderOperationsByLocalStatus = (
  orderAUFNR: string,
  operations: OrdersOperList[],
) => {
  return operations.reduce(
    (acc, operation) => {
      if (operation.AUFNR === orderAUFNR) {
        acc.totalOperations += 1;
        const statusArray = operation.STATUS?.split(' ') || [];

        if (statusArray.includes(OrderStatus.COMPLETED)) {
          acc.completedOperations += 1;
        } else {
          acc.notCompletedOperations += 1;
        }
      }
      return acc;
    },
    {
      totalOperations: 0,
      completedOperations: 0,
      notCompletedOperations: 0,
    } as {
      totalOperations: number;
      completedOperations: number;
      notCompletedOperations: number;
    },
  );
};

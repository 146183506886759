<script setup lang="ts">
import type { FunctionalComponent, SVGAttributes } from 'vue';
import { CircleFilledIcon, CircleRegularIcon, DismissCircleIcon } from '@/shared/assets/svg';
import { StatusLabels } from '@/shared/interfaces';
import styles from './StatusIcon.module.scss';

interface Props {
  status: StatusLabels;
  className?: string;
}

const { status, className } = withDefaults(defineProps<Props>(), {
  className: '',
});

const iconRenderHelper = (status: string): FunctionalComponent<SVGAttributes, {}, any, {}> => {
  switch (status) {
    case StatusLabels.IN_PROGRESS:
      return CircleRegularIcon;
    case StatusLabels.COMPLETED:
      return CircleFilledIcon;
    case StatusLabels.CANCELED:
      return DismissCircleIcon;
    default:
      return CircleRegularIcon;
  }
};
</script>

<template>
  <div
    :class="{
      [styles.status]: true,
      [styles.progress]: status === StatusLabels.IN_PROGRESS,
      [styles.completed]: status === StatusLabels.COMPLETED,
      [styles.canceled]: status === StatusLabels.CANCELED,
      [className]: className,
    }"
  >
    <component :is="iconRenderHelper(status)" :class="styles.icon" />
  </div>
</template>

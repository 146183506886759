<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { DefectsList } from '@/entities/Defects';
import { DefectStatuses, type DefectsListData } from '@/entities/Defects/interfaces';
import { useStorage } from '@/shared/hooks/useStorage';
import {
  DEFAULT_IM_WERKS,
  NAV_ORDER_IDLE_CREATE,
  STORAGE_IDLES_LIST,
  STORAGE_IDLES_LIST_INFO,
} from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest, InformationDialog, ModalConfirm } from '@/shared/ui';
import { useGetItemsList } from '..';

const router = useRouter();
const { getItem, setItem } = useStorage();
const { profile, userIsSupervisor } = storeToRefs(useUserProfileStore());

const { itemsList, filesList, defectsFetchError, idlesFetchError, idlesList, reloadFetchRequest } =
  useGetItemsList(profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS);

const newIdlesInList = ref<DefectsListData[]>([]);
const newIdlesInListInfo = ref<{ EQUNR: string; EQKTX: string; QMNUM: string }[]>([]);
const openIdlesModal = ref<boolean>(!!newIdlesInListInfo.value.length);
const localIdleValue = ref({} as DefectsListData);

const handleCloseErrorModal = async (EQUNR: string, QMNAM: string) => {
  if (idlesList.value && userIsSupervisor.value) {
    newIdlesInListInfo.value = newIdlesInListInfo.value.filter(
      newValue => newValue.EQUNR !== EQUNR,
    );

    openIdlesModal.value = false;

    const prevSavedIdles = await getItem(STORAGE_IDLES_LIST);
    const parsedPrevSavedIdles = JSON.parse(prevSavedIdles || '');
    const newIdles = [...parsedPrevSavedIdles, QMNAM];
    await setItem(STORAGE_IDLES_LIST, JSON.stringify(newIdles));
  }
};
const handleCreateOrderDefect = (id: string) => {
  router.replace({ name: NAV_ORDER_IDLE_CREATE, params: { idleId: id } });
};

const isShowActionButton = (QMNUM: string) => {
  if (idlesList.value) {
    const currentIdle = idlesList.value.EX_PROSTOI_LIST.find(item => item.QMNUM === QMNUM);

    if (currentIdle) {
      const showStatuses = [DefectStatuses.IN_PROGRESS, DefectStatuses.NEW];

      if (localIdleValue.value?.localStatus?.includes(DefectStatuses.CREATED)) {
        return false;
      }

      if (
        !currentIdle.STAT.includes(DefectStatuses.CREATED) &&
        !currentIdle.localStatus?.includes(DefectStatuses.CREATED)
      ) {
        if (showStatuses.some(status => currentIdle?.STAT.includes(status))) {
          return true;
        } else if (currentIdle.STAT.includes(DefectStatuses.COMPLETED)) {
          return false;
        }
      }
    }
  }

  return false;
};

watchEffect(async () => {
  if (idlesList.value && userIsSupervisor.value) {
    //Для супервайзера нужно сравнивать сохраненные при прошлом старте смены простои с вновь загруженными
    const prevSavedIdles = await getItem(STORAGE_IDLES_LIST);
    const parsedPrevSavedIdles = JSON.parse(prevSavedIdles || '');
    if (parsedPrevSavedIdles && parsedPrevSavedIdles.length) {
      const newIdles = idlesList.value.EX_PROSTOI_LIST.filter(
        item => !parsedPrevSavedIdles?.includes(item.QMNUM),
      );

      if (newIdles.length) {
        newIdlesInList.value = newIdles;
        newIdlesInListInfo.value = newIdles?.map(item => ({
          EQUNR: item.EQUNR,
          EQKTX: item.EQKTX,
          QMNUM: item.QMNUM,
          STAT: item.STAT,
        }));
      }
    }
  }
});

watchEffect(async () => {
  await setItem(STORAGE_IDLES_LIST_INFO, JSON.stringify(newIdlesInListInfo.value));

  if (newIdlesInListInfo.value?.length) {
    openIdlesModal.value = true;
  }
});
</script>

<template>
  <HandleRequest
    :noData="!itemsList?.length"
    :errorRequest="defectsFetchError || idlesFetchError"
    :onRepeat="reloadFetchRequest"
  />
  <DefectsList v-if="itemsList" :items="itemsList" :filesList="filesList" />
  <template v-if="openIdlesModal">
    <InformationDialog
      v-if="!isShowActionButton(newIdlesInListInfo[0].QMNUM)"
      title="Обнаружены новые простои оборудования"
      :text="newIdlesInListInfo[0].EQKTX"
      :onClose="
        () => handleCloseErrorModal(newIdlesInListInfo[0].EQUNR, newIdlesInListInfo[0].QMNUM)
      "
    />
    <ModalConfirm
      v-else
      :cancel-text="'Ок'"
      :ok-text="'Создать ремонтное задание'"
      :onOk="() => handleCreateOrderDefect(newIdlesInListInfo[0].QMNUM)"
      :onCancel="
        () => handleCloseErrorModal(newIdlesInListInfo[0].EQUNR, newIdlesInListInfo[0].QMNUM)
      "
      :showIcon="true"
    >
      <a-typography-title :level="5" :class="$style.title"
        >Обнаружены новые простои оборудования</a-typography-title
      >
      <a-typography-text :class="$style.text">
        {{ newIdlesInListInfo[0].EQKTX }}
      </a-typography-text>
    </ModalConfirm>
  </template>
</template>

<style lang="scss" module>
.title,
.text {
  font-size: 15px;
  line-height: 140%;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useGetSectors } from '@/entities/Sectors';
import { SearchIcon } from '@/shared/assets/svg';
import { NAV_DASHBOARD, NAV_DEFECTS } from '@/shared/lib';
import { BottomButtons, PageHeading, Search, SelectCustomize } from '@/shared/ui';
import { HandleRequest } from '@/shared/ui';
import styles from './Sectors.module.scss';

const router = useRouter();

const { sectors, sectorsFetchError, reloadFetchRequest } = useGetSectors();

const selectedSector = ref<string | undefined>(undefined);
const openSelect = ref<boolean>(false);
const submitDisabled = computed(() => selectedSector.value === undefined);

const search = ref(false);
const onSearchOpen = () => {
  search.value = !search.value;
};

const onSubmit = () => {
  router.push({ name: NAV_DEFECTS, params: { sectorId: selectedSector.value } });
};
const handleModalSelect = (value: boolean) => (openSelect.value = value);

const handleSelect = (item: any) => {
  selectedSector.value = item;
  handleModalSelect(false);
};

const handleClose = () => handleModalSelect(false);

const handleBack = () => {
  if (search.value) {
    search.value = false;
  } else {
    router.push(NAV_DASHBOARD);
  }
};

const dataAvailable = computed(() => sectors.value?.EX_TECHPLACE.length);

const sectorOptions = computed(() =>
  sectors.value?.EX_TECHPLACE.map(sector => ({ id: sector.TPLNR, name: sector.PLTXT })),
);

const titlePage = computed(() => (search.value ? 'Поиск оборудования' : 'Дефекты'));
</script>

<template>
  <div :class="styles.defect">
    <PageHeading :text="titlePage" :goBack="true" :onHandleBack="handleBack">
      <button v-if="dataAvailable" type="button" :class="styles.search" @click="onSearchOpen">
        <SearchIcon />
      </button>
    </PageHeading>

    <HandleRequest
      :noData="!sectors?.EX_TECHPLACE.length"
      :errorRequest="sectorsFetchError"
      :onRepeat="reloadFetchRequest"
    />
    <template v-if="dataAvailable">
      <Search
        v-if="search && sectorOptions"
        :placeholder="'Введите название оборудования'"
        :urlName="NAV_DEFECTS"
        :items="sectorOptions"
        :paramName="'sectorId'"
        :showSearchResult="true"
      />
      <div :class="styles.body" v-else>
        <div :class="styles.wrap">
          <SelectCustomize
            v-if="sectorOptions"
            :items="sectorOptions"
            :onModalSelect="handleModalSelect"
            :onSelect="handleSelect"
            :open="openSelect"
            :selected="selectedSector"
            :onClose="handleClose"
            :title="'Выберите учаcток'"
            :label="'Выбор из списка'"
            size="lg"
          />
        </div>
        <BottomButtons>
          <a-button type="primary" :disabled="submitDisabled" size="large" @click="onSubmit"
            >Найти</a-button
          ></BottomButtons
        >
      </div>
    </template>
  </div>
</template>

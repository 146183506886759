<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDefectManagementStore } from '@/features/DefectManagement';
import {
  EditMeasureList,
  useGroupedMeasureList,
  useMeasureManagementStore,
} from '@/features/MeasureManagement';
import type {
  CreateMeasureInput,
  GroupedMeasureItems,
} from '@/features/MeasureManagement/interfaces';
import { useConfirmOrder } from '@/features/RejectionReasons';
import {
  OrderStatus,
  type OrdersOperListWithVps,
  type OrdersVps,
  useGetOrderInfo,
  useOrdersStore,
} from '@/entities/Orders';
import { checkCanCreateDefectForOperation } from '@/entities/Orders/lib';
import { CheckIcon, PlusIcon } from '@/shared/assets/svg';
import {
  NAV_DEFECT_CREATE_FROM_OPERATION,
  getCurrentUTC0Date,
  getCurrentUTC0Time,
  nDivide,
} from '@/shared/lib';
import { BottomButtons, InformationDialog, PageHeading } from '@/shared/ui';
import styles from './OperationsMeasure.module.scss';

const route = useRoute();
const router = useRouter();
const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);
const { createMeasureAsync } = useMeasureManagementStore();
const { addLocalVps, updateLocalOperationStatusAndDefects } = useOrdersStore();
const createDefectStore = useDefectManagementStore();

const { confirmOrder } = useConfirmOrder();

const orderOperations = ref<OrdersOperListWithVps[]>([]);
const currentSlide = ref(0);
const currentOperation = ref<OrdersOperListWithVps | undefined>(undefined);
const errorModalOpen = ref(false);
const errorDefectModalOpen = ref(false);
const slickRef = ref();

const { getGroupedMeasureListFromVps, measureValues } = useGroupedMeasureList(orderId as string);
const measureValuesRef = ref<Record<string, Record<string, CreateMeasureInput>>>(
  measureValues.value,
);

watchEffect(() => {
  measureValuesRef.value = { ...measureValues.value };
});

watchEffect(() => {
  orderOperations.value =
    order.value?.EX_OPER_LIST?.filter(operation => operation.STATUS !== OrderStatus.COMPLETED) ||
    [];
  currentOperation.value = orderOperations.value[0];
});

const submitDisabled = computed(
  () => currentOperation.value && !checkAllPointsFilled(currentOperation.value.VORNR),
);
const isOrderInProgress = computed(() => order.value?.STTXT.includes(OrderStatus.IN_PROGRESS));

const onAfterChange = (current: number) => {
  currentOperation.value = orderOperations.value[current];
  currentSlide.value = current;
};

const onEditMeasure = (inputValue: string, POINT: string) => {
  if (currentOperation.value) {
    const operationVORNR = currentOperation.value.VORNR;
    measureValuesRef.value[operationVORNR][POINT].CNTRC =
      inputValue?.toString().replace(/\./g, ',') || '';
  }
};

const checkAllPointsFilled = (VORNR: string) => {
  if (measureValuesRef.value?.[VORNR]) {
    return Object.values(measureValuesRef.value[VORNR]).every(value => value.CNTRC !== '');
  }
  return false;
};

const handleToggleErrorModal = (value: boolean) => (errorModalOpen.value = value);
const handleToggleErrorDefectModal = (value: boolean) => (errorDefectModalOpen.value = value);

const handleLocalVps = (flatValues: CreateMeasureInput[]) => {
  const newLocalVps: OrdersVps[] = [];
  flatValues.forEach(item => {
    const measureItemBase = currentOperation.value?.EX_VPS.find(vps => vps.POINT === item.POINT);
    if (measureItemBase) {
      newLocalVps.push({
        ...measureItemBase,
        CNTRC: item.CNTRC,
        IDATE: getCurrentUTC0Date(),
        ITIME: getCurrentUTC0Time(),
      });
    }
  });
  addLocalVps(newLocalVps);
};

const getFlatValues = async () => {
  const flatValues: CreateMeasureInput[] = [];
  Object.keys(measureValuesRef.value).forEach(VORNR => {
    const operationValues = measureValuesRef.value[VORNR];

    Object.values(operationValues).forEach(value => {
      flatValues.push(value);
    });
  });
  const valuesHasZeroValue = flatValues.some(item => item.CNTRC === '0');
  if (valuesHasZeroValue) {
    handleToggleErrorModal(true);
    return;
  }

  const allMeasureCreated = await createMeasureAsync(flatValues);
  if (allMeasureCreated) {
    handleLocalVps(flatValues);
  }
  measureValuesRef.value = {};
};

const onSubmit = async () => {
  if (orderOperations.value.length > 1) {
    if (currentOperation.value) {
      updateLocalOperationStatusAndDefects({
        orderId: currentOperation.value.AUFNR,
        operationId: currentOperation.value.VORNR,
        localStatus: OrderStatus.COMPLETED,
      });

      await getFlatValues();
    }
  } else {
    await getFlatValues();
    confirmOrder(order.value);
  }
};

const handleBack = () => {
  router.go(-2);
};

const getCurrentOperationSlider = () => {
  if (!slickRef.value?.innerSlider) return;
  const { currentSlide } = slickRef.value?.innerSlider;
  return orderOperations.value[currentSlide];
};

const isOperationWithoutNotesOrWithDefects = computed<boolean>(
  () =>
    order.value?.EX_OPER_LIST?.some(operation => {
      const currentOperation = getCurrentOperationSlider();
      return (
        operation.VORNR === currentOperation?.VORNR &&
        (operation.STATUS.includes(OrderStatus.COMPLETED) || operation.localDefects?.length)
      );
    }) || false,
);

const handleButtonNoRemark = async () => {
  const currentOperation = getCurrentOperationSlider();

  if (currentOperation) {
    updateLocalOperationStatusAndDefects({
      orderId: currentOperation.AUFNR,
      operationId: currentOperation.VORNR,
      localStatus: OrderStatus.COMPLETED,
    });
  }

  if (orderOperations.value.length === 1 || !orderOperations.value.length) {
    order.value && confirmOrder(order.value);
  }
};

const handleButtonRemark = async () => {
  const currentOperation = getCurrentOperationSlider();
  if (!currentOperation) return;
  if (!checkCanCreateDefectForOperation(currentOperation)) {
    handleToggleErrorDefectModal(true);
  } else {
    createDefectStore.clearCreateDefectValues();
    router.push({
      name: NAV_DEFECT_CREATE_FROM_OPERATION,
      params: { orderId, operationId: currentOperation.VORNR },
    });
  }
};
</script>

<template>
  <PageHeading :text="currentOperation?.LTXA1 || ''" :goBack="true" :onHandleBack="handleBack" />
  <div :class="styles.wrap">
    <div :class="styles.slider" v-if="orderOperations">
      <a-carousel :after-change="onAfterChange" dot-position="top" ref="slickRef">
        <div v-for="item in orderOperations" :key="item.AUFNR + item.VORNR" :class="styles.item">
          <div :class="styles.info">
            <a-typography-text :class="styles.name">Описание</a-typography-text>
            <ul :class="styles.list">
              <li
                v-for="(text, index) in nDivide(item.DESCR)"
                :key="index"
                :class="styles.listItem"
              >
                {{ text }}
              </li>
            </ul>
          </div>
        </div>
      </a-carousel>
    </div>
    <EditMeasureList
      v-if="currentOperation"
      :items="getGroupedMeasureListFromVps(currentOperation?.EX_VPS, currentOperation.VORNR)"
      :onEditMeasure="onEditMeasure"
    />
  </div>
  <BottomButtons v-if="isOrderInProgress && currentOperation?.EX_VPS?.length">
    <a-button @click="onSubmit" type="primary" :disabled="submitDisabled" size="large">
      {{ orderOperations.length > 1 ? 'Далее' : 'Сохранить' }}
    </a-button>
  </BottomButtons>
  <BottomButtons v-else>
    <a-button size="large" :class="[styles.button, styles.remark]" @click="handleButtonRemark">
      <PlusIcon />
      Замечание
    </a-button>

    <a-button
      v-if="!isOperationWithoutNotesOrWithDefects"
      size="large"
      :class="[styles.button, styles.noRemark]"
      @click="handleButtonNoRemark"
      ><CheckIcon />Без замечаний</a-button
    ></BottomButtons
  >
  <InformationDialog
    v-if="errorModalOpen"
    title="Ошибка"
    text="Нулевое значение быть не может"
    :onClose="() => handleToggleErrorModal(false)"
  />
  <InformationDialog
    v-if="errorDefectModalOpen"
    title="Ошибка"
    text="Невозможно создать дефект к данной операции"
    :onClose="() => handleToggleErrorDefectModal(false)"
  />
</template>

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@/shared/hooks';
import { STORAGE_IDLES_INFO } from '@/shared/lib';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getIdlesList } from '../api/getIdlesList';
import type { DefectStatuses, IdlesListResponse } from '../interfaces';
import type { IdlesListRequestParams } from '../interfaces/IdlesListRequestParams';

export const useIdlesListStore = defineStore('idlesList', () => {
  const idlesList = ref<IdlesListResponse | undefined>(undefined);
  const idlesFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setItem } = useStorage();

  const fetchIdlesListAsync = async (input: IdlesListRequestParams) => {
    const tryBlock = async () => {
      idlesFetchError.value = false;
      const response = await getIdlesList(input);
      if (handleResponse(response)) {
        idlesList.value = response.data as IdlesListResponse;
      } else {
        idlesFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchIdlesListAsync' });
  };

  const updateLocalIdleStatus = (idleId: string, status: DefectStatuses) => {
    if (!idlesList.value) return;

    const index = idlesList.value.EX_PROSTOI_LIST.findIndex(idle => idle.QMNUM === idleId);

    if (index !== -1) {
      const idle = idlesList.value.EX_PROSTOI_LIST[index];

      const updateDefect = {
        ...idle,
        localStatus: status,
      };

      idlesList.value.EX_PROSTOI_LIST[index] = updateDefect;

      // Запоминаем простой с изменением статуса для скрытия кнопки "Создать ремонтное задание" после обновления
      setItem(`${STORAGE_IDLES_INFO}_${idleId}`, JSON.stringify(updateDefect));
    }
  };

  return {
    idlesList,
    idlesFetchError,
    fetchIdlesListAsync,
    updateLocalIdleStatus,
  };
});

<script setup lang="ts">
import { Teleport, computed, ref } from 'vue';
import { HistoryIcon } from '@/shared/assets/svg';
import { getDateWithTimeInUserTimeZone } from '@/shared/lib/dateUtils';
import { HandleRequest, SwipeModal } from '@/shared/ui';
import type { EditMeasureItem } from '../../interfaces';
import styles from './MeasuringPointsHistory.module.scss';

interface Props {
  historyList: EditMeasureItem[];
  className?: string;
}

const { historyList, className } = defineProps<Props>();

const sortedHistoryList = computed(() => [
  ...historyList
    .filter(history => history.IDATE && history.CNTRC && history.READC)
    .sort((a, b) =>
      new Date(`${b.IDATE}T${b.ITIME}`) > new Date(`${a.IDATE}T${a.ITIME}`) ? 1 : -1,
    ),
]);

const isShowHistoryModal = ref<boolean>(false);
const handleShowHistoryModal = (show: boolean) => (isShowHistoryModal.value = show);
</script>

<template>
  <button
    :class="`${styles.button} ${className}`"
    type="button"
    @click="handleShowHistoryModal(true)"
  >
    <HistoryIcon />
  </button>
  <Teleport to="body">
    <SwipeModal
      v-if="isShowHistoryModal"
      :onShowModal="handleShowHistoryModal"
      :isShowModal="isShowHistoryModal"
    >
      <HandleRequest :noData="!sortedHistoryList.length" noFixed />
      <ul :class="styles.list" v-if="sortedHistoryList.length">
        <li v-for="item in sortedHistoryList" :key="item.MDOCM" :class="styles.item">
          <a-typography-text :class="styles.value">{{
            item.CNTRC || item.READC
          }}</a-typography-text>
          <a-typography-text :class="styles.date"
            >{{ getDateWithTimeInUserTimeZone(`${item.IDATE} ${item.ITIME}`, 'DD.MM.YYYY') }}
          </a-typography-text>
        </li>
      </ul>
    </SwipeModal>
  </Teleport>
</template>

import { httpBackendClient } from '@/shared/api';
import { API_TEAMS } from '@/shared/lib';
import type { TeamsRequest } from '../interfaces';

export const fetchTeams = async (request: TeamsRequest) => {
  return await httpBackendClient.get(
    API_TEAMS.replace(':supervisorId', request.supervisorId)
      .replace(':startDate', request.startDate)
      .replace(':endDate', request.endDate),
  );
};

import { EquipmentStatuses } from '@/shared/interfaces';
import { type OrdersOperList } from '../interfaces/Orders';

export const checkCanCreateDefectForOperation = (operation: OrdersOperList) => {
  if (
    !operation.EQUNR ||
    !operation.TPLNR ||
    !operation.STTXU.includes(EquipmentStatuses.DEFECTS_LEVEL)
  ) {
    return false;
  }
  return true;
};

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import type { UsersList } from '@/entities/Users/interfaces';
import { AddUserIcon } from '@/shared/assets/svg';
import { useStorage } from '@/shared/hooks';
import { STORAGE_ORDER_SUPERVISOR, STORAGE_ORDER_USERS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { SwipeModal } from '@/shared/ui';
import { useGetUsersList } from '../../hooks/useGetUsersList';
import { SelectUsers } from '../SelectUsers';
import { UserItem } from '../UserItem';
import styles from './UsersList.module.scss';

interface Props {
  orderId?: string;
  addUser?: boolean;
  swipeAble?: boolean;
  showPosition?: boolean;
  supervisor?: boolean;
}

const { orderId, addUser, swipeAble, showPosition, supervisor } = withDefaults(
  defineProps<Props>(),
  {
    addUser: false,
    swipeAble: false,
  },
);

const title = computed(() => {
  if (supervisor) {
    return 'Мастер';
  }
  return 'Исполнители';
});

const { getItem, setItem } = useStorage();
const storageKey = `${STORAGE_ORDER_USERS}_${orderId}`;
const supervisorStorageKey = `${STORAGE_ORDER_SUPERVISOR}_${orderId}`;

const { usersList } = useGetUsersList();
const { profile } = storeToRefs(useUserProfileStore());
const isShowModal = ref<boolean>(false);

const orderUsersRef = ref<UsersList[]>([]);

onMounted(async () => {
  const users = await getItem(supervisor ? supervisorStorageKey : storageKey);
  orderUsersRef.value = (users ? JSON.parse(users) : []).filter((user: UsersList) => user.working);
});

const orderUsers = computed({
  get: () => orderUsersRef.value,
  set: newValue => {
    orderUsersRef.value = newValue
      .filter(user => user.working)
      .sort(a => {
        return a.personnel_number === profile.value.personnel_number ? -1 : 1;
      });
  },
});

const handleDismiss = async (id: string) => {
  const updatedOrderUsers = orderUsers.value.filter(user => user.personnel_number !== id);
  orderUsers.value = updatedOrderUsers;
  await setItem(storageKey, JSON.stringify(updatedOrderUsers));
};
const handleSelected = async (tabNumbers: string[]) => {
  const users = usersList.value?.filter(user => tabNumbers.includes(user.personnel_number)) ?? [];
  orderUsers.value = users;
  await setItem(storageKey, JSON.stringify(users));
};

const handleShowModal = (show: boolean) => (isShowModal.value = show);
</script>

<template>
  <div :class="styles.users">
    <a-typography-title v-if="title" :class="styles.title" :level="4">
      {{ title }}
      <component
        v-if="addUser"
        :class="styles.addUser"
        :is="AddUserIcon"
        @click="handleShowModal(true)"
      >
      </component>
    </a-typography-title>
    <div>
      <div :class="styles.list">
        <UserItem
          v-for="(user, index) in orderUsers"
          :key="user.personnel_number"
          :user="user"
          :index="index"
          :swipeAble="swipeAble && user.personnel_number !== profile?.personnel_number"
          :onDismiss="handleDismiss"
          :showPosition="showPosition"
          :hidePhone="user.personnel_number === profile?.personnel_number"
        />
      </div>
    </div>
    <SwipeModal v-if="isShowModal" :onShowModal="handleShowModal" :isShowModal="isShowModal">
      <SelectUsers
        :onShowModal="handleShowModal"
        :selected="orderUsers.map(user => user.personnel_number)"
        :onSelected="handleSelected"
      />
    </SwipeModal>
  </div>
</template>

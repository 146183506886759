<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';

const updateViewportMeta = (content: string) => {
  let viewportMeta = document.querySelector('meta[name="viewport"]');
  if (viewportMeta) {
    viewportMeta.setAttribute('content', content);
  }
};

onMounted(() => {
  updateViewportMeta('width=device-width, initial-scale=1.0');
});

onBeforeUnmount(() => {
  updateViewportMeta('width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
});
</script>

<template>
  <slot></slot>
</template>

import { imagesExtension, videosExtension } from '@/shared/lib';
import type { FileItem, FilesData } from '../interfaces';

export const getAttachmentMineType = (attachmentExtension: string): string => {
  const attachmentExtensionLowerCase = attachmentExtension?.toLowerCase();
  let mimeType = 'text/plain'; // Default MIME type
  if (attachmentExtensionLowerCase === 'svg') {
    mimeType = 'image/svg+xml';
  } else if (imagesExtension.includes(attachmentExtensionLowerCase)) {
    mimeType = `image/${attachmentExtensionLowerCase}`;
  } else if (videosExtension.includes(attachmentExtensionLowerCase)) {
    mimeType = `video/mp4`;
  }
  return mimeType;
};

export const getAttachmentData = (filesList: FileItem[], currentFile: FilesData) => {
  if (!currentFile) return null;
  const attachment = filesList.find(file => file.FILE_ID === currentFile.FILE_ID);
  if (!attachment) return null;
  return {
    binaryString: currentFile.FILE_CONTENT,
    attachmentExtension: attachment.FILE_EXT ?? '',
    attachmentName: attachment.FILE_NAME,
  };
};

export const getAttachmentIsImage = (attachmentExtension: string | undefined) => {
  if (!attachmentExtension) return false;
  return imagesExtension.includes(attachmentExtension?.toLowerCase());
};
export const getAttachmentIsVideo = (attachmentExtension: string | undefined) => {
  if (!attachmentExtension) return false;
  return videosExtension.includes(attachmentExtension?.toLowerCase());
};

export const getSrcFromBinaryString = (binaryString: string, attachmentExtension: string) => {
  return `data:${getAttachmentMineType(attachmentExtension)};base64,${binaryString}`;
};

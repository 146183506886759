<script setup lang="ts">
import { nDivide } from '@/shared/lib';
import type { OrdersOperList } from '../../interfaces/Orders';
import styles from './OperationDescription.module.scss';

interface Props {
  description: OrdersOperList['DESCR'];
}

const { description } = defineProps<Props>();
const dividedDescription = nDivide(description);
</script>

<template>
  <div :class="styles.description">
    <a-typography-text :class="styles.title">Описание</a-typography-text>
    <ul :class="styles.list">
      <li v-for="(item, index) in dividedDescription" :key="index" :class="styles.item">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useDefectManagementStore } from '@/features/DefectManagement';
import {
  EquipmentSearchFilter,
  useGetEquipList,
  useGetFuncLocksWerksAndAreas,
} from '@/entities/Equipments';
import {
  NAV_DASHBOARD,
  NAV_DEFECT_CREATE_FROM_EQUIPMENT,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_SEARCH_NO_NFC,
} from '@/shared/lib';
import { useShowSilentLoading } from '@/shared/model/useLoadingStore';
import { HandleRequest, PageHeading, Search } from '@/shared/ui';
import styles from './EquipmentSearch.module.scss';

const router = useRouter();
const { equipmentList, equipmentSearchValue, lastSearchRoute } = useGetEquipList();

const createDefectStore = useDefectManagementStore();

const { funcLocByParentNode, funcLockFetchError, reloadFetchRequest } =
  useGetFuncLocksWerksAndAreas();

const showEquipmentList = ref<boolean>(false);
const showSearchResult = ref<boolean>(true);
const clearInputValue = ref();

const equipmentOptions = computed(
  () =>
    equipmentList.value?.EX_EQUIP_LIST?.map(equipment => ({
      id: equipment.EQUNR,
      name: equipment.EQKTX,
    })) || [],
);

const onFocus = () => {
  showEquipmentList.value = true;
  showSearchResult.value = true;
};

const onBlur = (e: FocusEvent) => {
  const value = (e.target as HTMLInputElement)?.value;
  const hasEquipments = equipmentList.value?.EX_EQUIP_LIST?.length;
  if (!value && hasEquipments && showEquipmentList.value) {
    showEquipmentList.value = false;
  }
  equipmentSearchValue.value = clearInputValue.value?.inputValue ?? '';
};

const handleBack = () => {
  if (showEquipmentList.value) {
    showEquipmentList.value = false;
    showSearchResult.value = false;
    clearInputValue.value.resetForm();
  } else {
    router.push(NAV_DASHBOARD);
  }
  equipmentSearchValue.value = '';
};

onMounted(() => {
  showEquipmentList.value = !!equipmentSearchValue.value;
  createDefectStore.clearCreateDefectValues();
});

const getSearchRoute = () =>
  router.currentRoute.value.name === NAV_EQUIPMENT_SEARCH_NO_NFC
    ? NAV_DEFECT_CREATE_FROM_EQUIPMENT
    : NAV_EQUIPMENT_DETAIL;

watch(
  () => router.currentRoute.value,
  () => {
    const newSearchRoute = getSearchRoute();
    if (lastSearchRoute.value === newSearchRoute) return;
    lastSearchRoute.value = newSearchRoute;
    if (equipmentSearchValue.value) {
      showEquipmentList.value = false;
      showSearchResult.value = false;
      equipmentSearchValue.value = '';
    }
  },
  { immediate: true },
);

useShowSilentLoading('fetchEquipmentListAsync');
</script>

<template>
  <div :class="styles.page">
    <PageHeading text="Поиск оборудования" :goBack="true" :onHandleBack="handleBack" />

    <HandleRequest
      :noData="!funcLocByParentNode"
      :errorRequest="funcLockFetchError"
      :onRepeat="reloadFetchRequest"
    />

    <template v-if="funcLocByParentNode && !funcLockFetchError">
      <Search
        ref="clearInputValue"
        placeholder="Поиск"
        paramName="equipmentId"
        :urlName="
          $route.name === NAV_EQUIPMENT_SEARCH_NO_NFC
            ? NAV_DEFECT_CREATE_FROM_EQUIPMENT
            : NAV_EQUIPMENT_DETAIL
        "
        :items="equipmentOptions"
        :debounceTime="500"
        :minSearchLength="3"
        :showSearchResult="showSearchResult"
        :disabled="!equipmentOptions"
        :onFocus="onFocus"
        :onBlur="onBlur"
        :defaultValue="equipmentSearchValue" />
      <div :class="styles.body" v-if="!showEquipmentList">
        <EquipmentSearchFilter
          v-if="funcLocByParentNode"
          :funcLocByParentNode="funcLocByParentNode"
        /></div
    ></template>
  </div>
</template>

import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useSymptomsStore } from '../model/useSymptomsStore';

export const useGetSymptoms = () => {
  const symptomsStore = useSymptomsStore();

  const { symptoms, symptomsFetchError } = storeToRefs(symptomsStore);

  const reloadFetchRequest = () => {
    symptomsStore.fetchSymptomsAsync();
  };

  onMounted(() => {
    if (!symptoms.value) {
      reloadFetchRequest();
    }
  });
  return { symptoms, symptomsFetchError, reloadFetchRequest };
};

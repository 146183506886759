import { ref } from 'vue';
import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import type { Alert } from '@/shared/interfaces';
import type { ExErrorResponse } from '../interfaces';

export const useAlertStore = defineStore('alert', () => {
  const alert = ref<Alert>();

  const clearAlert = () => {
    alert.value = undefined;
  };

  const setAlert = ({ type, message }: Alert) => {
    alert.value = {
      type,
      message,
    };
    setTimeout(clearAlert, 3000);
  };

  const handleResponse = (response: AxiosResponse<any, any>, successMessage?: string) => {
    const responseOk = response.status === 200 || response.status === 201;
    if (!responseOk) {
      setAlert({
        type: 'error',
        message: response.data?.description || `Произошла ошибка в запросе ${response.config.url} `,
      });
      return false;
    } else if (responseOk && response.data?.EX_ERROR?.length) {
      response.data.EX_ERROR.forEach((error: ExErrorResponse) => {
        setAlert({ type: error.RETCODE === 'I' ? 'success' : 'error', message: error.ERROR_TEXT });
      });
    } else if (responseOk && successMessage) {
      setAlert({ type: 'success', message: successMessage });
    }
    return true;
  };

  return {
    alert,
    clearAlert,
    setAlert,
    handleResponse,
  };
});

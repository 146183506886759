import { ref } from 'vue';
import {
  getKeycloakToken,
  type getKeycloakTokenPayload,
  getMe,
  type getMePayload,
} from '@orta/bridge';
import { defineStore } from 'pinia';
import { isInsideMobileApp } from '@/app/bridge';
import { httpBackendClient, httpFmpClient } from '@/shared/api';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { auth } from '../api/auth';
import { fetchFmpToken } from '../api/fetchFmpToken';
import { fetchKeycloakToken } from '../api/fetchKeycloakToken';

const GET_ME_RESPONSE: getMePayload = {
  id: '8f231fbc-1b1c-11ea-902d-4a3c07821c93',
  name: 'Ильяс',
  lastname: 'Рамазанов',
  avatar: '',
  avatarThumb: '',
  phone: '77000217182',
  iin: '960929350501',
  status: 'active',
  user_type: 'employee',
  sign: 'RVBGNZhfknX-NPYG9l_1pt3DgilDX5vlz4GHWLDB0Z0=',
};

export const useAuthModel = defineStore('auth', () => {
  const { handleResponse } = useAlertStore();
  const fmpToken = ref<string>('');
  const keycloakToken = ref<string>('');
  const { handleLoading } = useLoadingStore();

  // Метод авторизации в клиенте httpFmpClient
  const fetchFmpTokenAsync = async (initial = false) => {
    const tryBlock = async () => {
      const response = await fetchFmpToken();
      httpFmpClient.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      fmpToken.value = response.data.token;
    };
    await handleLoading({
      tryBlock,
      funcName: 'fetchFmpTokenAsync',
      initialData: initial,
    });
  };

  // Метод авторизации в клиенте httpBackendClient
  const authPythonBackendAsync = async (initial = false) => {
    const tryBlock = async () => {
      const getMeResponse = isInsideMobileApp ? await getMe() : GET_ME_RESPONSE; //Получение данных о юзере из приложения орта

      const handleAfterResponse = async (accessToken: string) => {
        httpBackendClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const authResponse = await auth();

        if (handleResponse(authResponse)) {
          keycloakToken.value = accessToken;
        }
      };

      if (getMeResponse) {
        const request = { json: getMeResponse as getMePayload, app: 'mob_toro' };

        if (isInsideMobileApp) {
          const { accessToken } = (await getKeycloakToken()) as getKeycloakTokenPayload;
          accessToken && handleAfterResponse(accessToken);
        } else {
          const keycloakTokenResponse = await fetchKeycloakToken(request);
          if (handleResponse(keycloakTokenResponse)) {
            handleAfterResponse(keycloakTokenResponse.data.access_token);
          }
        }
      }
    };
    await handleLoading({
      tryBlock,
      funcName: 'authPythonBackendAsync',
      initialData: initial,
    });
  };

  return {
    fmpToken,
    keycloakToken,
    fetchFmpTokenAsync,
    authPythonBackendAsync,
  };
});

import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getSchema } from '../api';
import { setSchema } from '../api/baseApi';
import type { Schema } from '../interfaces';
import { useAlertStore } from './useAlertStore';
import { useLoadingStore } from './useLoadingStore';

export const useSchemaStore = defineStore('schema', () => {
  const schema = ref<Record<string, Schema> | undefined>(undefined);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchSchemaAsync = async (initial = false) => {
    const tryBlock = async () => {
      const response = await getSchema();
      if (response.data) setSchema(response.data);
      if (handleResponse(response)) schema.value = response?.data;
    };
    await handleLoading({
      tryBlock,
      funcName: 'fetchSchemaAsync',
      initialData: initial,
    });
  };

  return {
    schema,
    fetchSchemaAsync,
  };
});

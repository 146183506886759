import { ref, watchEffect } from 'vue';
import { useGetDefectsList, useGetIdlesList } from '@/entities/Defects';
import type { DefectsListData } from '@/entities/Defects/interfaces';
import type { FileItem } from '@/shared/interfaces';
import { getAttachmentIsImage, getAttachmentIsVideo } from '@/shared/lib';

export const useGetItemsList = (IM_WERKS: string) => {
  const itemsList = ref<DefectsListData[]>([]);
  const filesList = ref<FileItem[]>([]);
  const {
    defectsList,
    defectsFetchError,
    reloadFetchRequest: reloadDefectsFetchRequest,
  } = useGetDefectsList({
    IM_WERKS: IM_WERKS,
    IM_TPLNR: '',
    IM_EQUNR: [],
    IM_EQUNR_HISTORY: [],
    IM_QMNUM: '',
  });
  const {
    idlesList,
    idlesFetchError,
    reloadFetchRequest: reloadIdlesFetchRequest,
  } = useGetIdlesList(IM_WERKS);

  const reloadFetchRequest = () => {
    if (defectsFetchError.value) {
      reloadDefectsFetchRequest();
    }
    if (idlesFetchError.value) {
      reloadIdlesFetchRequest();
    }
  };

  const isHaveItem = (defect: DefectsListData) => {
    const findItem = itemsList.value.find(item => item.QMNUM === defect.QMNUM);

    if (!findItem) {
      itemsList.value = [...itemsList.value, defect];
    }
  };

  watchEffect(() => {
    if (defectsList.value && idlesList.value) {
      defectsList.value?.EX_DEFECT_LIST?.forEach(defect => isHaveItem(defect));

      defectsList.value?.EX_FILES_LIST?.forEach(file => {
        if (getAttachmentIsImage(file.FILE_EXT) || getAttachmentIsVideo(file.FILE_EXT)) {
          filesList.value = [...filesList.value, file];
        }
      });

      idlesList.value?.EX_PROSTOI_LIST?.forEach(defect => {
        isHaveItem(defect);
      });

      idlesList.value?.EX_FILES_LIST?.forEach(file => {
        if (getAttachmentIsImage(file.FILE_EXT) || getAttachmentIsVideo(file.FILE_EXT)) {
          filesList.value = [...filesList.value, file];
        }
      });

      itemsList.value.sort((a, b) => {
        return a.QMDAT > b.QMDAT ? -1 : 1;
      });
    }
  });

  return {
    itemsList,
    filesList,
    defectsFetchError,
    idlesFetchError,
    idlesList,
    reloadFetchRequest,
  };
};

export enum StatusLabels {
  NEW = 'NEW',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  TRANSFERRED = 'TRANSFERRED',
}

export interface Label {
  status: StatusLabels;
  statusText: string;
  className?: string;
}

import type { OrdersResponse } from '@/entities/Orders';
import type { TechnologistsResponse } from '@/entities/Users/interfaces';
import type { TechnologistsDataWithFmpOrders } from '../interfaces';

export const getTechnologistWithFmpOrders = (
  orders: OrdersResponse,
  technologist: TechnologistsResponse[],
): TechnologistsDataWithFmpOrders[] => {
  return technologist.map(item => {
    const teamUniqueOrderIds = new Set(item.work_orders.map(order => order.order_id));
    const fmpOrders = orders.EX_HEADERS.filter(order => teamUniqueOrderIds.has(order.AUFNR));
    const fmpOrdersWithOperations = fmpOrders.map(order => {
      const operationsOfOrder = orders.EX_OPER_LIST.filter(
        operation => operation.AUFNR === order.AUFNR,
      );
      return { ...order, EX_OPER_LIST: operationsOfOrder };
    });
    return {
      ...item,
      fmpOrders: fmpOrdersWithOperations,
    };
  });
};

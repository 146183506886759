import { type UnwrapRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthModel } from '@/features/Auth';
import { useGetOrdersOperPlan } from '@/entities/Orders';
import { useOrdersStore } from '../model/useOrdersStore';

export const getOrdersFromOperPlan = (
  ordersOperPlan: UnwrapRef<ReturnType<typeof useGetOrdersOperPlan>['ordersOperPlan']>,
) => {
  if (!ordersOperPlan) return [];
  const requestData =
    ordersOperPlan?.orders?.reduce((sum: string[][], current) => {
      const operations = current.operations?.split(',').filter(item => item !== 'all') || [];
      current.order_id &&
        operations.length &&
        sum.push(...operations.map(operation => [current.order_id, operation]));
      return sum;
    }, []) ?? [];
  return requestData;
};

export const useGetOrders = () => {
  const ordersStore = useOrdersStore();
  const { orders, ordersFetchError } = storeToRefs(ordersStore);
  const { ordersOperPlan } = useGetOrdersOperPlan();
  const { fmpToken } = useAuthModel();

  const reloadOrders = () => {
    if (!fmpToken || !ordersOperPlan.value) return;
    const requestData = getOrdersFromOperPlan(ordersOperPlan.value);
    return ordersStore.fetchOrdersAsync(requestData);
  };

  return { orders, ordersFetchError, reloadOrders };
};

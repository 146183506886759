<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDefectManagementStore } from '@/features/DefectManagement';
import { EditMeasureList, useGroupedMeasureList } from '@/features/MeasureManagement';
import { useCreateConfirmStore } from '@/features/RejectionReasons';
import { useGetOrderInfo, useOrdersStore } from '@/entities/Orders';
import { OrderStatus, type OrdersOperList } from '@/entities/Orders/interfaces/Orders';
import { checkCanCreateDefectForOperation } from '@/entities/Orders/lib';
import { CheckIcon, PlusIcon } from '@/shared/assets/svg';
import {
  NAV_DASHBOARD,
  NAV_DEFECT_CREATE_FROM_OPERATION,
  NAV_NFC_LIST,
  nDivide,
} from '@/shared/lib';
import { BottomButtons, DrawerCustomize, InformationDialog } from '@/shared/ui';
import PageHeading from '@/shared/ui/PageHeading/PageHeading.vue';
import styles from './OperationsSliderWidget.module.scss';

const route = useRoute();
const { orderId, operationId, tagId } = route.params;
const { order } = useGetOrderInfo(orderId as string, tagId as string);
const createConfirmStore = useCreateConfirmStore();
const { updateLocalOrderStatus, updateLocalOperationStatusAndDefects } = useOrdersStore();
const createDefectStore = useDefectManagementStore();

const openModalComment = ref<boolean>(false);
const commentValue = ref<string>('');
const operations = ref<OrdersOperList[]>([]);
const currentSlideValue = ref(0);
const initialSlide = computed(() =>
  operations.value.findIndex(operation => operation.VORNR === operationId),
);

const isOperationWithoutNotesOrWithDefects = ref<boolean>(
  order.value?.EX_OPER_LIST?.some(
    operation =>
      operation.VORNR === operationId &&
      (operation.STATUS.includes(OrderStatus.COMPLETED) || operation.localDefects?.length),
  ) || false,
);

const router = useRouter();

const scrollTop = ref<HTMLElement | null>(null);
const slickRef = ref();
const titleSlider = computed(() => operations.value[currentSlideValue.value]?.LTXA1 || '');

const errorModalOpen = ref(false);
const handleToggleErrorModal = (value: boolean) => (errorModalOpen.value = value);

const handleButtonNoRemark = () => {
  const { currentSlide } = slickRef.value.innerSlider;
  const currentOperation = operations.value[currentSlide];
  onScrollToRef();

  if (currentOperation) {
    updateLocalOperationStatusAndDefects({
      orderId: currentOperation.AUFNR,
      operationId: currentOperation.VORNR,
      localStatus: OrderStatus.COMPLETED,
    });
  }

  if (operations.value.length === 1 || !operations.value.length) {
    openModalComment.value = true;
  }
};

const handleSubmit = async (withComment: boolean) => {
  if (order.value) {
    if (!tagId) {
      await createConfirmStore.fetchCreateConfirmAsync(
        order.value.AUFNR,
        order.value.EX_OPER_LIST,
        '',
        '',
        order.value?.timeBegin,
        withComment ? commentValue.value : undefined,
      );
      updateLocalOrderStatus(orderId as string, OrderStatus.COMPLETED);
    }

    router.push(tagId ? `${NAV_NFC_LIST}/${orderId}` : NAV_DASHBOARD);
  }
};

const handleButtonRemark = async () => {
  const { currentSlide } = slickRef.value.innerSlider;
  const currentOperation = operations.value[currentSlide];
  if (!checkCanCreateDefectForOperation(currentOperation)) {
    handleToggleErrorModal(true);
  } else {
    createDefectStore.clearCreateDefectValues();
    router.push({
      name: NAV_DEFECT_CREATE_FROM_OPERATION,
      params: { orderId, operationId: currentOperation.VORNR },
    });
  }
};

const onScrollToRef = () => {
  if (scrollTop.value) {
    scrollTop.value?.scrollIntoView({ behavior: 'smooth' });
  }
};

const onAfterChange = (current: number) => {
  onScrollToRef();
  currentSlideValue.value = current !== -1 ? current : initialSlide.value;
};

const handleClose = (submit?: boolean) => {
  openModalComment.value = false;
  setTimeout(() => {
    commentValue.value = '';
  }, 300);
};

onMounted(() => {
  if (isOperationWithoutNotesOrWithDefects.value) {
    operations.value =
      order.value?.EX_OPER_LIST?.filter(
        operation =>
          operation.VORNR === operationId &&
          (operation.STATUS.includes(OrderStatus.COMPLETED) || operation.localDefects?.length),
      ) || [];
  } else {
    operations.value =
      order.value?.EX_OPER_LIST?.filter(
        operation =>
          !operation.STATUS.includes(OrderStatus.COMPLETED) && !operation.localDefects?.length,
      ) || [];
  }
});

watchEffect(() => {
  if (initialSlide.value !== -1) {
    slickRef.value?.goTo(initialSlide.value);
    onAfterChange(initialSlide.value);
  }
});

watchEffect(() => {
  operations.value =
    order.value?.EX_OPER_LIST?.filter(
      operation => operation.STATUS !== OrderStatus.COMPLETED && !operation.localDefects?.length,
    ) || [];
});

const { getGroupedMeasureListFromVps, measureValues } = useGroupedMeasureList(orderId as string);

const onEditMeasure = (inputValue: string, POINT: string, operationId: string) => {
  operations.value = operations.value.map(operation => {
    if (operation.VORNR === operationId) {
      measureValues.value[operationId][POINT].CNTRC =
        inputValue?.toString().replace(/\./g, ',') || '';
    }
    return operation;
  });
};
</script>

<template>
  <div :class="styles.scrollBlock" ref="scrollTop" />
  <PageHeading :text="titleSlider" :goBack="true" />
  <div :class="styles.wrap">
    <div :class="styles.slider">
      <a-carousel :after-change="onAfterChange" dot-position="top" ref="slickRef">
        <div v-for="item in operations" :key="item.AUFNR + item.VORNR" :class="styles.wrapItem">
          <div :class="styles.item">
            <a-typography-text :class="styles.name">Описание</a-typography-text>
            <ul :class="styles.list">
              <li
                v-for="(text, index) in nDivide(item.DESCR)"
                :key="index"
                :class="styles.listItem"
              >
                {{ text }}
              </li>
            </ul>
          </div>
          <EditMeasureList
            v-if="item"
            :items="getGroupedMeasureListFromVps(item.EX_VPS || [], item.VORNR)"
            :onEditMeasure="(inputValue, point) => onEditMeasure(inputValue, point, item.VORNR)"
          />
        </div>
      </a-carousel>
    </div>
  </div>
  <BottomButtons>
    <a-button size="large" :class="[styles.button, styles.remark]" @click="handleButtonRemark">
      <PlusIcon />
      Замечание
    </a-button>

    <a-button
      v-if="!isOperationWithoutNotesOrWithDefects"
      size="large"
      :class="[styles.button, styles.noRemark]"
      @click="handleButtonNoRemark"
      ><CheckIcon />Без замечаний</a-button
    ></BottomButtons
  >
  <InformationDialog
    v-if="errorModalOpen"
    title="Ошибка"
    text="Невозможно создать дефект к данной операции"
    :onClose="() => handleToggleErrorModal(false)"
  />
  <DrawerCustomize :title="'Комментарий'" :open="openModalComment" @close="handleClose">
    <a-textarea v-model:value="commentValue" show-count :bordered="false" maxlength="40" />
    <div :class="styles.buttons">
      <a-button size="large" :class="styles.button" @click="() => handleSubmit(false)"
        >Пропустить</a-button
      >
      <a-button type="primary" size="large" :class="styles.button" @click="() => handleSubmit(true)"
        >Отправить</a-button
      >
    </div>
  </DrawerCustomize>
</template>

export enum modalsResponseNFCTypes {
  IS_DEFAULT = 'IS_DEFAULT',
  IS_NOT_SUPPORTING = 'IS_NOT_SUPPORTING',
  IS_DISABLED = 'IS_DISABLED',
  IS_NOT_READING = 'IS_NOT_READING',
  IS_NOT_READING_BUTTON = 'IS_NOT_READING_BUTTON',
  IS_NOT_MATCHES = 'IS_NOT_MATCHES',
  IS_NOT_MATCHES_EQUIPS = 'IS_NOT_MATCHES_EQUIPS',
  IS_CANNOT_CREATE_DEFECT = 'IS_CANNOT_CREATE_DEFECT',
  IS_CANNOT_CREATE_ORDER = 'IS_CANNOT_CREATE_ORDER',
}

export const modalsResponseNFC = {
  [modalsResponseNFCTypes.IS_DEFAULT]: {
    title: 'NFC Метка',
    text: `Поднесите устройство <br />к NFC-метке`,
  },
  [modalsResponseNFCTypes.IS_NOT_SUPPORTING]: {
    title: 'Сканирование метки недоступно',
    text: 'Ваше устройство не поддерживает NFC',
  },
  [modalsResponseNFCTypes.IS_DISABLED]: {
    title: 'NFC неактивен',
    text: 'Для считывания NFC меток необходимо включить NFC в "Настройках" телефона',
  },
  [modalsResponseNFCTypes.IS_NOT_READING]: {
    title: 'Не удалось считать NFC метку',
    text: 'Повторите попытку',
  },
  [modalsResponseNFCTypes.IS_NOT_MATCHES]: {
    title: 'Нет совпадений по отсканированной метке',
    text: 'Операций по метке не найдено',
  },
  [modalsResponseNFCTypes.IS_NOT_READING_BUTTON]: {
    title: 'Отклонение заказа',
    text: 'Вы уверены, что хотите отклонить заказ?',
  },
  [modalsResponseNFCTypes.IS_NOT_MATCHES_EQUIPS]: {
    title: 'Нет совпадений по отсканированной метке',
    text: 'Оборудование по метке не найдено',
  },
  [modalsResponseNFCTypes.IS_CANNOT_CREATE_DEFECT]: {
    title: 'Ошибка',
    text: 'Невозможно создать дефект на данное оборудование',
  },
  [modalsResponseNFCTypes.IS_CANNOT_CREATE_ORDER]: {
    title: 'Ошибка',
    text: 'Невозможно создать заказ на оборудование',
  },
};

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
  getDefectStatusData,
  getDefectStatusName,
  useGetDefectInfo,
  useGetOrderDefectInfo,
} from '@/entities/Defects';
import { DefectStatuses, type DefectsListData } from '@/entities/Defects/interfaces';
import { PhoneIcon } from '@/shared/assets/svg';
import { useStorage } from '@/shared/hooks';
import {
  NAV_ORDER_DEFECT_CREATE,
  NAV_ORDER_DEFECT_DETAIL,
  STORAGE_DEFECT_INFO,
} from '@/shared/lib';
import { getCorrectDate } from '@/shared/lib/dateUtils';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons, HandleRequest, PageHeading, SliderMedia, StatusLabel } from '@/shared/ui';
import styles from './DefectDetail.module.scss';

const route = useRoute();
const router = useRouter();
const { getItem } = useStorage();
const { id } = route.params;

const localDefectValue = ref({} as DefectsListData);

const getFunc = route.name === NAV_ORDER_DEFECT_DETAIL ? useGetOrderDefectInfo : useGetDefectInfo;

const { defectInfo, filesList, defectsFetchError, reloadFetchRequest } = getFunc(
  {
    IM_WERKS: '',
    IM_TPLNR: '',
    IM_EQUNR: [],
    IM_EQUNR_HISTORY: [],
    IM_QMNUM: id as string,
  },
  id as string,
);

const userProfileStore = useUserProfileStore();
const { userIsSupervisor } = storeToRefs(userProfileStore);

const handleCreateButton = () => {
  router.replace({ name: NAV_ORDER_DEFECT_CREATE, params: { defectId: id } });
};

watchEffect(async () => {
  const storageKey = `${STORAGE_DEFECT_INFO}_${id}`;
  const localDefect = (await getItem(storageKey)) || null;

  localDefectValue.value = localDefect ? JSON.parse(localDefect) : {};
});

const showButtonCreateRepairTask = computed(() => {
  const showStatuses = [DefectStatuses.IN_PROGRESS, DefectStatuses.NEW];

  if (localDefectValue.value?.localStatus?.includes(DefectStatuses.CREATED)) {
    return false;
  }

  if (
    defectInfo.value &&
    !defectInfo.value.STAT.includes(DefectStatuses.CREATED) &&
    !defectInfo.value.localStatus?.includes(DefectStatuses.CREATED)
  ) {
    if (showStatuses.some(status => defectInfo.value?.STAT.includes(status))) {
      return true;
    } else if (defectInfo.value.STAT.includes(DefectStatuses.COMPLETED)) {
      return false;
    }
  }

  return false;
});

const showButtonDefectCreated = computed(
  () =>
    defectInfo.value &&
    (defectInfo.value?.STAT.includes(DefectStatuses.CREATED) ||
      defectInfo.value.localStatus?.includes(DefectStatuses.CREATED) ||
      localDefectValue.value?.localStatus?.includes(DefectStatuses.CREATED)),
);
</script>

<template>
  <div :class="styles.defect">
    <PageHeading text="Дефект" :goBack="true" />
    <HandleRequest
      :noData="!defectInfo"
      :errorRequest="defectsFetchError"
      :onRepeat="reloadFetchRequest"
    />

    <template v-if="!defectsFetchError && defectInfo">
      <div v-if="filesList?.length" :class="styles.imagesWrapper">
        <SliderMedia
          v-if="filesList"
          :className="styles.images"
          :filesList="filesList"
          :fullscreenTitle="defectInfo.EQKTX"
          :miniSlider="true"
          :autoLoadFiles="true"
        />
      </div>

      <div :class="styles.header">
        <StatusLabel
          :className="styles.status"
          :status="getDefectStatusData(defectInfo.STAT).status"
          :statusText="
            getDefectStatusData(
              defectInfo.STAT,
              getDefectStatusName(defectInfo.EAUSZT, defectInfo.MAUEH_TXT),
            ).statusText
          "
        />
        <a-typography-title :level="4" :class="styles.title">{{
          defectInfo.QMTXT
        }}</a-typography-title>
      </div>
      <div :class="styles.body">
        <div :class="styles.list">
          <div :class="styles.row">
            <a-typography-text :class="styles.name">Описание</a-typography-text>
            <a-typography-text :class="styles.text">{{
              defectInfo.DESCR || `-`
            }}</a-typography-text>
          </div>
          <div :class="styles.row">
            <a-typography-text :class="styles.name">Плановая дата устранения</a-typography-text>
            <a-typography-text :class="styles.text">{{
              getCorrectDate(defectInfo.LTRMN || '') || '-'
            }}</a-typography-text>
          </div>
          <div :class="styles.row">
            <a-typography-text :class="styles.name">Узел</a-typography-text>
            <a-typography-text :class="styles.text">{{ defectInfo.EQKTX }}</a-typography-text>
          </div>
          <div :class="styles.row">
            <a-typography-text :class="styles.name">Зафиксировал</a-typography-text>
            <a-typography-text :class="styles.text">
              <div v-if="defectInfo.userInfo" :class="styles.userInfo">
                {{
                  `${defectInfo.userInfo?.lastName} ${defectInfo.userInfo?.firstName.charAt(0)}. ${defectInfo.userInfo?.middleName.charAt(0)}.`
                }}
                <a v-if="defectInfo.userInfo?.mobile" :href="`tel:${defectInfo.userInfo?.mobile}`">
                  <PhoneIcon />
                </a>
              </div>
              <template v-else>{{ defectInfo.QMNAM }}</template>
            </a-typography-text>
          </div>
          <div :class="styles.row">
            <a-typography-text :class="styles.name">Приоритет</a-typography-text>
            <a-typography-text :class="styles.text">{{ defectInfo.PRIOKX }} </a-typography-text>
          </div>
        </div>
      </div>
      <BottomButtons v-if="userIsSupervisor">
        <a-button
          v-if="showButtonCreateRepairTask"
          type="primary"
          size="large"
          :class="styles.button"
          @click="handleCreateButton"
          >Создать ремонтное задание</a-button
        >
        <a-button
          v-if="showButtonDefectCreated"
          type="primary"
          size="large"
          :class="styles.button"
          :disabled="true"
          >Заказ на сообщение уже создан</a-button
        >
      </BottomButtons>
    </template>
  </div>
</template>

import type { FileItem, LinksListItem } from '../interfaces';
import { NAV_ORDER_DOCUMENT_DETAIL } from './constants';
import { toRuDate } from './dateUtils';
import { fileIconRenderHelper } from './fileIconRenderHelper';

export const convertFileListToLinkListItems = (
  fileList: FileItem[] | undefined,
  url = NAV_ORDER_DOCUMENT_DETAIL,
): LinksListItem[] | undefined => {
  if (!fileList) {
    return;
  }
  return fileList.map(file => ({
    id: file.FILE_ID,
    label: `${toRuDate(file.CREADATE)}, ${file.FILE_SIZE} ${file.FILE_UNIT}`,
    value: file.FILE_NAME,
    url: `${url}/${file.FILE_ID}`,
    icon: fileIconRenderHelper(file.FILE_EXT),
  }));
};

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import router from '@/app/providers/router';
import { useGetOrderInfo } from '@/entities/Orders';
import { useStorage } from '@/shared/hooks/useStorage';
import { NAV_DASHBOARD, STORAGE_END_ACCEPTED_SHIFT } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons, PageTabs } from '@/shared/ui';
import PageHeading from '@/shared/ui/PageHeading/PageHeading.vue';
import { tabs } from '../lib/tabs';

const { orderId } = useRoute().params;
const { setItem } = useStorage();

const { order } = useGetOrderInfo(orderId as string);
const { profile } = storeToRefs(useUserProfileStore());

const orderName = computed(() => {
  return order.value?.KTEXT || '';
});
const onAcceptShift = async () => {
  await setItem(STORAGE_END_ACCEPTED_SHIFT, profile.value.end_date || '');
  await router.push(NAV_DASHBOARD);
};
</script>

<template>
  <PageHeading :text="orderName" :goBack="true" />
  <PageTabs :tabs="tabs" />
  <BottomButtons :noPanel="true">
    <a-button type="primary" html-type="submit" size="large" @click="onAcceptShift"
      >Начать смену</a-button
    >
  </BottomButtons>
</template>

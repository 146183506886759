<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { TeamsWidget } from '@/widgets/TeamsWidget';
import { useUserProfileStore } from '@/shared/model';
import { PageHeading } from '@/shared/ui';

const userProfileStore = useUserProfileStore();
const { userIsTechnologSupervisor } = storeToRefs(userProfileStore);
</script>

<template>
  <PageHeading :text="userIsTechnologSupervisor ? 'Исполнители' : 'Бригады'" :goBack="true" />
  <div :class="$style.page">
    <TeamsWidget />
  </div>
</template>

<style lang="scss" module>
.page {
  padding: 16px 12px 56px;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useCreatedDefectsStore } from '@/entities/Defects';
import { declOfNum } from '@/shared/lib';
import styles from './DefectsPanel.module.scss';

const { createdDefects } = storeToRefs(useCreatedDefectsStore());
const totalDefects = computed(() => createdDefects.value.new + createdDefects.value.eliminated);
</script>

<template>
  <div :class="styles.panel">
    <a-typography-text :class="styles.text">Панель дефектов</a-typography-text>
    <a-typography-title :level="3" style="margin: 0" :class="styles.title"
      >{{ totalDefects }}
      {{ declOfNum(totalDefects, ['Дефект', 'Дефекта', 'Дефектов']) }}
    </a-typography-title>
    <ul :class="styles.list">
      <li :class="styles.item">
        <b :class="styles.count">{{ createdDefects.new }}</b
        ><span :class="styles.status">Новых</span>
      </li>
      <li :class="styles.item">
        <b :class="styles.count">{{ createdDefects.eliminated }}</b
        ><span :class="styles.status">Устранено</span>
      </li>
    </ul>
  </div>
</template>

import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { DEFAULT_IM_WERKS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getFuncLockList } from '../api/getFuncLockList';
import type { FucLockResponse } from '../interfaces';

export const useFuncLocStore = defineStore('funcLoc', () => {
  const funcLockList = ref<FucLockResponse | undefined>(undefined);
  const funcLockFetchError = ref(false);

  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchFuncLockListAsync = async (silent = false) => {
    const tryBlock = async () => {
      funcLockFetchError.value = false;
      const response = await getFuncLockList(
        profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS,
      );
      if (handleResponse(response)) {
        funcLockList.value = response.data as FucLockResponse;
      } else {
        funcLockFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchFuncLockListAsync', noGlobalLoader: silent });
  };

  return {
    funcLockList,
    funcLockFetchError,
    fetchFuncLockListAsync,
  };
});

import type { UserInformationResponse } from '@/shared/interfaces';
import type { OrdersHistory } from '../interfaces';

export const mapUserInfoWithHistory = (
  history: OrdersHistory[],
  userInformation: UserInformationResponse,
) => {
  return history.map(item => {
    const infoItem = userInformation.data.find(info => info.sap === item.TABN);
    if (infoItem) {
      return { ...item, userInfo: infoItem };
    }
    return item;
  });
};

<script setup lang="ts">
import type { Label } from '@/shared/interfaces';
import { StatusLabels } from '@/shared/interfaces';
import styles from './StatusLabel.module.scss';

const { status, statusText, className } = withDefaults(defineProps<Label>(), {
  className: '',
});
</script>

<template>
  <div
    :class="{
      [styles.status]: true,
      [styles.new]: status === StatusLabels.NEW,
      [styles.progress]: status === StatusLabels.IN_PROGRESS,
      [styles.completed]: status === StatusLabels.COMPLETED,
      [styles.canceled]: status === StatusLabels.CANCELED,
      [className]: className,
    }"
  >
    {{ statusText }}
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  type CreateMeasureInput,
  EditMeasureList,
  type GroupedMeasureItems,
  useMeasureManagementStore,
} from '@/features/MeasureManagement';
import { type EquipmentMeasure, useEquipmentStore, useGetEquipList } from '@/entities/Equipments';
import { useGetEquipmentByParentNodes } from '@/entities/Equipments/hooks/useGetEquipmentByParentNodes';
import type { EquipmentResponse } from '@/entities/Equipments/interfaces';
import {
  getEquipmentChildAndChildrenChildEQUNRs,
  getMeasurePointsByEQUNRs,
} from '@/entities/Equipments/lib';
import { getCurrentUTC0Date, getCurrentUTC0Time } from '@/shared/lib';
import { BottomButtons, HandleRequest, InformationDialog } from '@/shared/ui';

const { equipmentId } = useRoute().params;
const { createMeasureAsync } = useMeasureManagementStore();

const { equipmentList, equipmentListFetchError, reloadFetchRequest } = useGetEquipList();
const { addLocalMeasureItems } = useEquipmentStore();

const { equipmentsNodesByParentNodes } = useGetEquipmentByParentNodes();

const measureValues = ref({} as Record<string, CreateMeasureInput>);

const getGroupedMeasureListFromEquipmentMeasure = (
  measure: EquipmentMeasure[] | undefined,
): GroupedMeasureItems[] => {
  if (!measure) return [];
  const groupedObj = measure?.reduce(
    (acc, item) => {
      if (!acc[+item.POINT]) {
        acc[+item.POINT] = {
          DECIM: item.DECIM,
          measureItems: [],
          recentMeasure: item,
        };
        measureValues.value[item.POINT] = {
          POINT: item.POINT,
          CNTRC: '',
          UNITR: item.MSEHL,
          itemId: equipmentId as string,
        };
      }
      acc[+item.POINT].measureItems.push(item);
      acc[+item.POINT].recentMeasure = { ...item, itemId: equipmentId as string }; //Последний записанный элемент будет самым актуальным по дате
      return acc;
    },
    {} as Record<number, GroupedMeasureItems>,
  );
  return Object.values(groupedObj);
};

const groupedMeasureList = computed(() => {
  const equipmentChildAndChildrenChildEQUNRs = getEquipmentChildAndChildrenChildEQUNRs(
    equipmentsNodesByParentNodes.value,
    equipmentId as string,
  );

  const allPoints = getMeasurePointsByEQUNRs(
    equipmentList.value?.EX_MEASURE,
    [equipmentId as string, ...equipmentChildAndChildrenChildEQUNRs.flat()], //Так же нужно добавить EQUNR самого оборудования
  );

  return getGroupedMeasureListFromEquipmentMeasure(allPoints);
});

const onEditMeasure = (inputValue: string, POINT: string) => {
  measureValues.value[POINT].CNTRC = inputValue?.toString().replace(/\./g, ',') || '';
};

const errorModalOpen = ref(false);
const handleToggleErrorModal = (value: boolean) => (errorModalOpen.value = value);

const handleLocalMeasure = () => {
  const newLocalMeasureItems: EquipmentResponse['EX_MEASURE'] = [];
  Object.values(measureValues.value).forEach(item => {
    const measureItemBase = equipmentList.value?.EX_MEASURE.find(
      measure => measure.POINT === item.POINT,
    );
    if (measureItemBase) {
      newLocalMeasureItems.push({
        ...measureItemBase,
        CNTRC: item.CNTRC,
        IDATE: getCurrentUTC0Date(),
        ITIME: getCurrentUTC0Time(),
      });
    }
  });
  addLocalMeasureItems(newLocalMeasureItems);
};

const onSubmit = async () => {
  if (measureValues.value) {
    const valuesHasZeroValue = Object.values(measureValues.value).some(item => item.CNTRC === '0');
    if (valuesHasZeroValue) {
      handleToggleErrorModal(true);
      return;
    }
    const allMeasureCreated = await createMeasureAsync(Object.values(measureValues.value));
    if (allMeasureCreated) {
      handleLocalMeasure();
    }
    measureValues.value = {};
  }
};

const checkAllPointsFilled = () => {
  if (measureValues.value) {
    const arr = Object.values(measureValues.value);
    return arr.every(item => Boolean(item.CNTRC));
  }
  return false;
};

const submitDisabled = computed(() => !checkAllPointsFilled());
</script>

<template>
  <HandleRequest
    :noData="!groupedMeasureList.length"
    :errorRequest="equipmentListFetchError"
    :onRepeat="reloadFetchRequest"
  />
  <div v-if="Boolean(groupedMeasureList.length)" :class="$style.wrap">
    <EditMeasureList :items="groupedMeasureList" :onEditMeasure="onEditMeasure" />
    <BottomButtons>
      <a-button type="primary" :disabled="submitDisabled" size="large" @click="onSubmit"
        >Сохранить
      </a-button>
    </BottomButtons>
  </div>
  <InformationDialog
    v-if="errorModalOpen"
    title="Ошибка"
    text="Нулевое значение быть не может"
    :onClose="() => handleToggleErrorModal(false)"
  />
</template>

<style lang="scss" module>
.wrap {
  padding-bottom: 56px;
}
</style>

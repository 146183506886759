import { Roles } from '@/shared/interfaces';

const baseTheme = Object.freeze({
  token: {
    colorPrimary: '#EF7F1A',
    colorPrimaryBg: '#FDF2E8',
    colorPrimaryBgHover: '#FBE0C8',
    colorPrimaryBorder: '#F8C89D',
    colorPrimaryBorderHover: '#F5AE6F',
    colorPrimaryHover: '#F29643',
    colorPrimaryBase: '#EF7F1A',
    colorPrimaryActive: '#CB6C16',
    colorPrimaryTextHover: '#AA5A12',
    colorPrimaryText: '#88480F',
    colorText: '#333',
    fontFamily: `'SF Pro Display', sans-serif`,
  },
  components: {
    Form: {
      marginLG: '0px',
    },
    Upload: {
      marginXS: '0px',
      colorText: '#EF7F1A',
    },
    Switch: { colorTextQuaternary: '#F5F5F5' },
  },
});

export const technologistTheme = Object.freeze({
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorPrimary: '#EF7F1A',
  },
});

export const RepairerTheme = Object.freeze({
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorPrimary: '#157CDB',
    colorPrimaryBg: '#D0E3F4',
    colorPrimaryBgHover: '#A3CBF1',
    colorPrimaryBorder: '#9BCAF6',
    colorPrimaryBorderHover: '#6EB4F4',
    colorPrimaryHover: '#409CF0',
    colorPrimaryBase: '#1A89EF',
    colorPrimaryActive: '#136DC0',
    colorPrimaryTextHover: '#115FA7',
    colorPrimaryText: '#0E4B84',
  },
});

export const SupervisorTheme = Object.freeze({
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorPrimary: '#9254DE',
    colorPrimaryBg: '#ddccf3',
    colorPrimaryBgHover: '#c7a3f3',
    colorPrimaryBorder: '#c097f3',
    colorPrimaryBorderHover: '#a668f2',
    colorPrimaryHover: '#8b3bed',
    colorPrimaryBase: '#7616eb',
    colorPrimaryActive: '#5f12bd',
    colorPrimaryTextHover: '#510fa3',
    colorPrimaryText: '#420d82',
  },
});

export const getTheme = (role: Roles | null) => {
  switch (role) {
    case Roles.TECHNOLOGIST:
    case Roles.EMPLOYEE:
      return technologistTheme;
    case Roles.REPAIRER:
      return RepairerTheme;
    case Roles.REPAIR_SUPERVISOR:
    case Roles.TECHNOLOG_SUPERVISOR:
      return SupervisorTheme;
    default:
      return technologistTheme;
  }
};

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RejectionReasons } from '@/features/RejectionReasons';
import { useGetOrderInfo } from '@/entities/Orders';
import { useOrdersStore } from '@/entities/Orders';
import { OrderStatus } from '@/entities/Orders/interfaces/Orders';
import { NAV_NFC_LIST, NAV_NFC_ORDER } from '@/shared/lib';
import { PageHeading, PageTabs } from '@/shared/ui';
import { BottomButtons } from '@/shared/ui';
import { tabs } from '../lib/tabs';
import styles from './Inspection.module.scss';

const openReasons = ref<boolean>(false);

const router = useRouter();
const route = useRoute();
const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);
const orderName = computed(() => {
  return order.value?.KTEXT || '';
});

const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};

const { updateLocalOperationStatusAndDefects } = useOrdersStore();

const handleInspectionStart = () => {
  const path = order.value && order.value.countTags > 1 ? NAV_NFC_LIST : NAV_NFC_ORDER;
  router.push({ name: path, params: { orderId } });
};

const handleAfterSubmit = () => {
  order.value?.EX_OPER_LIST.forEach(operation => {
    updateLocalOperationStatusAndDefects({
      orderId: operation.AUFNR,
      operationId: operation.VORNR,
      localStatus: OrderStatus.CANCELED,
    });
  });
};
</script>

<template>
  <div :class="styles.inspection" v-if="order">
    <PageHeading :text="orderName" :goBack="true" />
    <PageTabs :tabs="tabs" :noWrap="[0]" />
    <BottomButtons>
      <a-button size="large" :class="styles.button" @click="handleModalReasons(true)"
        >Отклонить</a-button
      >
      <a-button
        v-if="order.STTXT === OrderStatus.IN_PROGRESS"
        type="primary"
        size="large"
        :class="styles.button"
        @click="handleInspectionStart"
        >Продолжить</a-button
      ><a-button
        v-else
        type="primary"
        size="large"
        :class="styles.button"
        @click="handleInspectionStart"
        >Начать осмотр</a-button
      >
    </BottomButtons>
  </div>
  <RejectionReasons
    v-if="openReasons"
    :onClose="() => handleModalReasons(false)"
    :onAfterSubmit="handleAfterSubmit"
    :open="openReasons"
    :orderId="orderId as string"
  />
</template>

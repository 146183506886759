import type { AntUploadItem } from '@/shared/interfaces';
import type { CreateDefectInput } from '../interfaces/DefectManagement';

export type ValidateFileResult = { ok: true } | { ok: false; error: string };

export const validateFileType = async (file: File) => {
  return new Promise<ValidateFileResult>((resolve, reject) => {
    // Проверка MIME-типа
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4'];
    if (!validTypes.includes(file.type)) {
      reject({ ok: false, error: 'Тип файла не поддерживается' });
      return;
    }
    // Чтение содержимого файла
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target?.result as ArrayBuffer | null;
      if (!data) return;
      const arr = new Uint8Array(data).subarray(0, 12);
      let header = '';
      for (let i = 0; i < arr.length; i++) header += arr[i].toString(16);
      // Проверка сигнатуры файла
      let isValid = false;
      if (header.startsWith('89504e47')) {
        isValid = file.type === 'image/png';
      } else if (header.startsWith('ffd8ff')) {
        isValid = file.type === 'image/jpeg' || file.type === 'image/jpg';
      } else if (
        header.startsWith('000') &&
        (header.includes('66747970') || header.toLowerCase().includes('6d703432'))
      ) {
        isValid = file.type === 'video/mp4';
      }
      if (isValid) {
        resolve({ ok: true });
      } else {
        reject({ ok: false, error: 'Тип файла не поддерживается' });
      }
    };
    if (file) reader.readAsArrayBuffer(file);
    else reject({ ok: false, error: 'Файл не выбран' });
  });
};

export const validateFileSize = (file: AntUploadItem): ValidateFileResult => {
  const maxImageSize = 10;
  const maxVideoSize = 19;
  switch (file.type) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/jpg': {
      // для изображений максимальный размер соответствует 10 МБ
      const ok = file.size < maxImageSize * 1024 * 1024;
      const error = `Размер изображения должен быть не более ${maxImageSize} МБ`;
      if (ok) return { ok: true };
      return { ok: false, error };
    }
    case 'video/mp4': {
      // для видео максимальный размер соответствует 19 МБ
      const error = `Размер видео должен быть не более ${maxVideoSize} МБ`;
      const ok = file.size < maxVideoSize * 1024 * 1024;
      if (ok) return { ok: true };
      return { ok: false, error };
    }
    default:
      return { ok: false, error: 'Тип файла не поддерживается' };
  }
};

export const validateFile = async (
  file: AntUploadItem,
): Promise<{ ok: true } | { ok: false; error: string }> => {
  const result = await validateFileType(file.originFileObj).catch(error => error);
  if (!result.ok) return result;
  return validateFileSize(file);
};

export const formatFile = async (files: File[]): Promise<CreateDefectInput['files']> => {
  const filePromises = files.map(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          resolve({
            FILE_NAME: file.name,
            // @ts-ignore
            FILE_CONTENT: reader.result.split('base64,')[1] || '',
          });
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  });

  // Wait for all promises to be resolved
  const fileInfos = await Promise.all(filePromises);
  return fileInfos as CreateDefectInput['files'];
};

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { OrderCommentsList, useGetOrderInfo } from '@/entities/Orders';
import { HandleRequest } from '@/shared/ui';

const route = useRoute();
const { orderId } = route.params;
const { order, ordersFetchError, reloadOrders } = useGetOrderInfo(orderId as string);
</script>

<template>
  <div :class="$style.widget">
    <HandleRequest
      :noData="!order?.EX_COMMENT?.length"
      :errorRequest="ordersFetchError"
      :onRepeat="reloadOrders"
    />
    <OrderCommentsList v-if="order?.EX_COMMENT" :comments="order.EX_COMMENT" />
  </div>
</template>

<style lang="scss" module>
.widget {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  padding-bottom: 76px;
}
</style>

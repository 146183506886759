import { createApp } from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import { VueQueryPlugin } from '@tanstack/vue-query';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './index.css';
import router from './providers/router';

export const app = createApp(App);

app.use(VueQueryPlugin);
app.use(createPinia());
app.use(router);
app.use(Antd);
app.component(VueCountdown.name as string, VueCountdown);

import { ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { useEquipmentStore } from '../model/useEquipmentStore';

export const useGetEquipList = () => {
  const equipmentStore = useEquipmentStore();

  const { equipmentList, equipmentListFetchError, equipmentSearchValue, lastSearchRoute } =
    storeToRefs(equipmentStore);
  const clearedEquipmentList = ref(equipmentList.value);

  watchEffect(() => {
    if (equipmentList.value) {
      clearedEquipmentList.value = {
        EX_EQUIP_LIST:
          equipmentList.value.EX_EQUIP_LIST?.filter(item => item.NOACTIVE !== 'X') || [],
        EX_FILES_LIST: equipmentList.value.EX_FILES_LIST,
        EX_MEASURE: equipmentList.value.EX_MEASURE,
      };
    }
  });

  const reloadFetchRequest = () => {
    equipmentStore.fetchEquipmentListAsync();
  };

  return {
    equipmentList: clearedEquipmentList,
    equipmentListFetchError,
    equipmentSearchValue,
    lastSearchRoute,
    reloadFetchRequest,
  };
};

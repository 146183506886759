<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useBackClick } from '@/app/bridge';
import { ArrowBackIcon } from '../assets/svg';
import { NAV_DASHBOARD, NAV_ROOT, nfcStaticPages } from '../lib';

const { onClick } = defineProps<{ onClick?: () => void }>();

const route = useRoute();
const router = useRouter();

const handleClick = () => {
  if (onClick) {
    onClick();
  } else {
    const prevRoute = (router.options.history.state.back || '').toString();
    if (nfcStaticPages.includes(prevRoute)) {
      router.push(NAV_DASHBOARD);
    } else if (route.path === NAV_DASHBOARD || route.path === NAV_ROOT) {
      return; // Не переходить назад из дашборда или корневой страницы (актуально для МП при отображаемой кнопки Назад)
    } else {
      router.back();
    }
  }
};

useBackClick(handleClick);
</script>

<template>
  <button type="button" :class="$style.back" @click="handleClick">
    <ArrowBackIcon />
  </button>
</template>

<style lang="scss" module>
.back {
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: start;
  color: var(--color-white, #fff);
  svg {
    width: 24px;
    height: 24px;
  }
}
</style>

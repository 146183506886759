<script setup lang="ts">
import { EmptyData } from '@/shared/assets/svg';
import styles from './HandleRequest.module.scss';

interface Props {
  noFixed: boolean;
  textEmpty?: string;
}

const { noFixed, textEmpty } = withDefaults(defineProps<Props>(), {
  textEmpty: 'Нет данных',
});
</script>

<template>
  <div :class="{ [styles.wrap]: true, [styles.wrapEmpty]: true, [styles.noFixed]: noFixed }">
    <EmptyData :class="styles.icon" />
    <div :class="styles.title">{{ textEmpty }}</div>
  </div>
</template>

<script setup lang="ts">
import { MAX_QUEUE_SIZE, useQueueStore } from '@/shared/hooks/useQueue';
import Counter from '@/shared/ui/Counter.vue';

defineProps<{ withPrefixComma?: boolean }>();

const queueStore = useQueueStore();
</script>

<template>
  <Counter
    :value="queueStore.queue?.length ?? 0"
    :max="MAX_QUEUE_SIZE"
    :with-prefix-comma="withPrefixComma"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCreateConfirmStore } from '@/features/RejectionReasons';
import { OperationsList, OrderStatus, useOrdersStore } from '@/entities/Orders';
import { useGetOrderInfo } from '@/entities/Orders';
import { checkOrderIsInspection } from '@/entities/Orders/lib';
import { NAV_DASHBOARD, NAV_INSPECTION_DETAIL, NAV_OPERATIONS_SLIDER } from '@/shared/lib';
import { DrawerCustomize, PageHeading } from '@/shared/ui';
import { BottomButtons } from '@/shared/ui';

const route = useRoute();
const router = useRouter();
const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);
const orderName = order.value?.KTEXT || '';
const createConfirmStore = useCreateConfirmStore();

const openModalComment = ref<boolean>(false);
const commentValue = ref<string>('');

const { updateLocalOrderStatus } = useOrdersStore();

const handleSubmit = async (withComment: boolean) => {
  if (order.value) {
    await createConfirmStore.fetchCreateConfirmAsync(
      order.value.AUFNR,
      order.value.EX_OPER_LIST,
      '',
      '',
      order.value?.timeBegin,
      withComment ? commentValue.value : undefined,
    );

    updateLocalOrderStatus(orderId as string, OrderStatus.COMPLETED);
    router.push(NAV_DASHBOARD);
  }
};

const handleButton = () => (openModalComment.value = true);

const handleBack = () => {
  router.push({
    name: NAV_INSPECTION_DETAIL,
    params: { orderId },
  });
};

const handleClose = (submit?: boolean) => {
  openModalComment.value = false;
  setTimeout(() => {
    commentValue.value = '';
  }, 300);
};
</script>

<template>
  <div :class="$style.page">
    <PageHeading :text="orderName" :goBack="true" :onHandleBack="handleBack" />
    <div>
      <OperationsList
        :withTitle="false"
        v-if="order?.EX_OPER_LIST"
        :operations="order.EX_OPER_LIST"
        :url="NAV_OPERATIONS_SLIDER"
        showStatus
        :isInspectionOrder="checkOrderIsInspection(order)"
        isLink
      />
    </div>
    <BottomButtons>
      <a-button @click="handleButton" type="primary" size="large">Все в порядке</a-button>
    </BottomButtons>
  </div>
  <DrawerCustomize :title="'Комментарий'" :open="openModalComment" @close="handleClose">
    <a-textarea v-model:value="commentValue" show-count :bordered="false" maxlength="40" />
    <div :class="$style.buttons">
      <a-button size="large" :class="$style.button" @click="() => handleSubmit(false)"
        >Пропустить</a-button
      >
      <a-button type="primary" size="large" :class="$style.button" @click="() => handleSubmit(true)"
        >Отправить</a-button
      >
    </div>
  </DrawerCustomize>
</template>

<style lang="scss" module>
.page {
  padding-bottom: 76px;
}

.buttons {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  background: var(--color-white, #fff);
  border-top: 1px solid #eeeeee;

  button {
    width: 100%;
  }
}
</style>

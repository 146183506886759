import { httpBackendClient } from '@/shared/api';
import { API_TECHNOLOGIST } from '@/shared/lib';
import type { TechnologistsRequest } from '../interfaces';

export const fetchTechnologists = async (request: TechnologistsRequest) => {
  return await httpBackendClient.get(
    API_TECHNOLOGIST.replace(':startDate', request.startDate)
      .replace(':endDate', request.endDate)
      .replace(':supervisorTechnologistId', request.supervisorTechnologistId),
  );
};

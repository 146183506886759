import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useTabsStore = defineStore('tabs', () => {
  const activeTabsByNamesHash = ref<{
    [key: string]: number;
  }>({});

  const setActiveTabsByNamesHash = (namesHash: string, activeId: number) => {
    activeTabsByNamesHash.value[namesHash] = activeId;
  };

  return {
    activeTabsByNamesHash,
    setActiveTabsByNamesHash,
  };
});

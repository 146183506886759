import { type Defect, StatusLabels } from '@/shared/interfaces';

export const defectsList: Defect[] = [
  {
    id: 1,
    type: 1, // дефект
    status: StatusLabels.COMPLETED,
    statusText: 'Устранен',
    defectNumber: '84772962',
    priorityId: 1,
    name: 'Посторонний шум в работе привода',
    discoveryDate: '09.03.24',
    equipmentName: 'Роликовая дробилка 223-СR-01',
  },
  {
    id: 2,
    type: 1,
    status: StatusLabels.CANCELED,
    statusText: 'Отклонено',
    defectNumber: '84772962',
    priorityId: 2,
    discoveryDate: '09.03.24',
    eliminationDate: '09.03.24',
    name: 'Течь из под штуцера. ГЦ подворота',
    equipmentName: 'Роликовая дробилка 223-СR-01',
    images: [
      {
        id: 1,
        url: '',
        preview:
          'https://raw.githubusercontent.com/vueComponent/ant-design-vue/main/components/carousel/demo/abstract02.jpg',
      },
    ],
  },
  {
    id: 3,
    type: 1,
    status: StatusLabels.IN_PROGRESS,
    statusText: 'В работе',
    defectNumber: '84772962',
    name: 'Течь из под штуцера. ГЦ подворота',
    equipmentName: 'Роликовая дробилка 223-СR-01',
    priorityId: 3,
    discoveryDate: '09.03.24',
    eliminationDate: '09.03.24',
  },
  {
    id: 4,
    type: 1, // дефект
    status: StatusLabels.IN_PROGRESS,
    statusText: 'В работе',
    defectNumber: '84772962',
    priorityId: 4,
    name: 'Посторонний шум в работе привода',
    equipmentName: 'Роликовая дробилка 223-СR-01',
    discoveryDate: '09.03.24',
    eliminationDate: '09.03.24',
    images: [
      {
        id: 1,
        url: '',
        preview:
          'https://raw.githubusercontent.com/vueComponent/ant-design-vue/main/components/carousel/demo/abstract01.jpg',
      },
      {
        id: 2,
        url: '',
        preview:
          'https://raw.githubusercontent.com/vueComponent/ant-design-vue/main/components/carousel/demo/abstract02.jpg',
      },
      {
        id: 3,
        url: '',
        preview:
          'https://raw.githubusercontent.com/vueComponent/ant-design-vue/main/components/carousel/demo/abstract03.jpg',
      },
    ],
  },
  {
    id: 5,
    type: 1,
    status: StatusLabels.IN_PROGRESS,
    statusText: 'В работе',
    defectNumber: '84772962',
    priorityId: 5,
    name: 'Течь из под штуцера. ГЦ подворота',
    equipmentName: 'Роликовая дробилка 223-СR-01',
    discoveryDate: '09.03.24',
    eliminationDate: '09.03.24',
    images: [
      {
        id: 1,
        url: '',
        preview:
          'https://raw.githubusercontent.com/vueComponent/ant-design-vue/main/components/carousel/demo/abstract02.jpg',
      },
    ],
  },
  {
    id: 6,
    type: 1,
    status: StatusLabels.IN_PROGRESS,
    statusText: 'В работе',
    defectNumber: '84772962',
    priorityId: 6,
    name: 'Течь из под штуцера. ГЦ подворота',
    equipmentName: 'Роликовая дробилка 223-СR-01',
    discoveryDate: '09.03.24',
    eliminationDate: '09.03.24',
  },
  {
    id: 7,
    type: 2,
    status: StatusLabels.CANCELED,
    statusText: '6 мин.',
    defectNumber: '84772962',
    downTimeStart: '09.03.24, 16:32', // простой
    name: 'Течь из под штуцера. ГЦ подворота',
    equipmentName: 'Роликовая дробилка 223-СR-01',
  },
];

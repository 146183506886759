import type { OrderStatus } from './Orders';

export interface OrderConfirmationsRequest {
  OrderNumber: string;
  Operations: string[];
}

interface OrderConfirmationMaterial {
  MaterialNumber: string;
  MaterialPosnr: number;
  MaterialQuantity: number;
  MaterialUnit: string;
}

interface OrderConfirmationsOperation {
  OperationNumber: string;
  StatusIdOperation: number;
  StatusTextOperation: OrderStatus;
  ReasonId: number | null;
  Comment: string | null;
  FlFinalConf: boolean;
  OperationArbpl: number;
  TeamNumber: string;
  Materials: OrderConfirmationMaterial[];
}

export interface OrderConfirmationsResponse {
  OrderNumber: string;
  People: number;
  StatusId: number;
  StatusText: OrderConfirmationStatuses;
  StartDateWork: string;
  FinishDateWork: string;
  SumTimeOrder: number;
  TabNumber: string;
  SapErrorDescr: string | null;
  SapErrorTime: string | null;
  Operations: OrderConfirmationsOperation[];
}

export enum OrderConfirmationStatuses {
  PENDING = 1, // 'На подтверждении'
  CONFIRMED = 2, // 'Подтвержден'
  CANCELED = 3, // 'Отклонен'
}

interface WorkerData {
  user_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  work_duration: number;
  position: string;
  personnel_number: string;
  fullname: string;
  phone_number: string;
  photo_url: string;
}

export interface BrigadesWidgetInfo {
  [key: string]: {
    totalOrders: number;
    completedOrders: number;
    canceledOrders: number;
    workerData?: WorkerData[];
    seniorName?: string;
    capacity?: {
      [key: string]: {
        workgroup_capacity: number;
        team_name: string;
        teamMaxCapacity: number;
      };
    };
    id?: string;
  };
}

import { onMounted, onUnmounted, reactive } from 'vue';
import { defineStore } from 'pinia';

const DB_VERSION = 3;
const DB_NAME = 'workbox-background-sync';
const REQUEST_OBJECT_STORE_NAME = 'requests';
const QUEUE_NAME_INDEX = 'queueName';

export const MAX_QUEUE_SIZE = 60; // кол-во в штуках
const QUEUE_REFRESH_INTERVAL = 5_000; // миллисекунды

const openDB = async () => {
  const request = indexedDB.open(DB_NAME, DB_VERSION);
  const db = await new Promise<IDBDatabase>((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
  return db;
};

const deleteDB = async () => {
  const request = indexedDB.deleteDatabase(DB_NAME);
  const result = await new Promise<IDBDatabase>((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
    request.onblocked = () => reject(request.error);
  });
  return result;
};

const upgradeDB = async () => {
  const request = indexedDB.open(DB_NAME, DB_VERSION);
  const db = await new Promise<IDBDatabase>((resolve, reject) => {
    request.onupgradeneeded = event => {
      const objStore = request.result.createObjectStore(REQUEST_OBJECT_STORE_NAME, {
        autoIncrement: true,
        keyPath: 'id',
      });
      objStore.createIndex(QUEUE_NAME_INDEX, QUEUE_NAME_INDEX, {
        unique: false,
      });
      request.result.close();
    };
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const recreateDB = async (db: IDBDatabase) => {
  db.close();
  await deleteDB();
  await upgradeDB();
};

export const store = reactive({
  queue: null as any[] | null,
  queueInterval: null as NodeJS.Timeout | null,
});

export const useQueueStore = defineStore('queue', {
  state: () => ({ queue: null as any[] | null, queueInterval: null as NodeJS.Timeout | null }),
  actions: {
    async refresh() {
      const db = await openDB();
      try {
        const transaction = db.transaction(['requests']);
        const objectStore = transaction.objectStore('requests');
        const request = objectStore.getAll();
        const results = await new Promise<any[]>((resolve, reject) => {
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
        this.queue = results.filter(result => result.queueName === 'write-queue');
        db.close();
      } catch (e) {
        db.close();
        this.queue = [];
        // Багфикс: на задеплоеном сервере не создаётся таблица в БД, что провоцирует ошибку при попытке записи запроса в очередь
        // поэтому удаляем БД и создаём заново, добавляя таблицу вручную
        const hasTable = db.objectStoreNames.length > 0;
        if (!hasTable) await recreateDB(db);
      } finally {
        db.close();
      }
    },
    async clear() {
      try {
        const db = await openDB();
        const transaction = db.transaction(['requests'], 'readwrite');
        const objectStore = transaction.objectStore('requests');
        const request = objectStore.clear();
        await new Promise<void>((resolve, reject) => {
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        });
        db.close();
      } catch (e) {}
    },
  },
});

export const useQueueRefresh = () => {
  const queueStore = useQueueStore();

  onMounted(() => {
    queueStore.refresh();
    const interval = setInterval(() => queueStore.refresh(), QUEUE_REFRESH_INTERVAL);
    queueStore.queueInterval = interval;
  });

  onUnmounted(() => {
    if (queueStore.queueInterval !== null) clearInterval(queueStore.queueInterval);
  });

  return queueStore;
};

import { ref, watchEffect } from 'vue';
import type { FucLockByParentNode } from '../interfaces';
import { useGetFuncLocList } from './useGetFuncLocList';

export const useGetFuncLocksWerksAndAreas = () => {
  const { funcLockList, funcLockFetchError, reloadFetchRequest } = useGetFuncLocList();
  const funcLocByParentNode = ref<FucLockByParentNode | undefined>(undefined);
  watchEffect(() => {
    if (funcLockList.value) {
      funcLockList.value.EX_FUNC_LOC?.forEach(item => {
        if (!funcLocByParentNode.value) {
          funcLocByParentNode.value = {};
        }
        if (!funcLocByParentNode.value[item.TPLMA]) {
          funcLocByParentNode.value[item.TPLMA] = [];
        }
        funcLocByParentNode.value[item.TPLMA].push(item);
      });
    }
  });

  return {
    funcLocByParentNode,
    funcLockFetchError,
    reloadFetchRequest,
  };
};

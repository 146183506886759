import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { StatusLabels } from '@/shared/interfaces';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getCurrentOrders } from '../api/getCurrentOrders';
import type { Order } from '../interfaces';

export const useCurrentOrdersStore = defineStore('currentOrders', () => {
  //todo удалить
  const currentOrders = ref([] as Order[]);
  const { handleLoading } = useLoadingStore();

  const fetchCurrentRepairsAsync = async (silent = false) => {
    const tryBlock = async () => {
      currentOrders.value = await getCurrentOrders();
    };
    await handleLoading({ tryBlock, funcName: 'fetchCurrentRepairsAsync', noGlobalLoader: silent });
  };

  const setOrdersStatus = (id: number, status: StatusLabels) => {
    currentOrders.value = currentOrders.value.map(order => {
      if (order.id === id) {
        return {
          ...order,
          status,
        };
      }

      return order;
    });
  };

  const setStartTime = (id: number, startTime: string) => {
    currentOrders.value = currentOrders.value.map(order => {
      if (order.id === id) {
        return {
          ...order,
          startTime,
        };
      }

      return order;
    });
  };

  const setEndTime = (id: number, endTime: string) => {
    currentOrders.value = currentOrders.value.map(order => {
      if (order.id === id) {
        return {
          ...order,
          endTime,
        };
      }

      return order;
    });
  };

  return {
    currentOrders,
    fetchCurrentRepairsAsync,
    setOrdersStatus,
    setStartTime,
    setEndTime,
  };
});

import { StatusLabels } from '@/shared/interfaces';
import { type Operation } from '../interfaces';

export const operations: Operation[] = [
  {
    id: 1,
    name: '1. Проверь утечки масла',
    status: StatusLabels.COMPLETED,
  },
  {
    id: 2,
    name: 'Визуальный осмотр состояния роликов',
    status: StatusLabels.CANCELED,
  },
  {
    id: 3,
    name: 'Проверка исправности звуковой сигнализации',
    status: StatusLabels.IN_PROGRESS,
  },
  {
    id: 4,
    name: 'Проверка заземления корпуса ЭД',
  },
];

import { storeToRefs } from 'pinia';
import { useFuncLocStore } from '../model/useFuncLocStore';

export const useGetFuncLocList = () => {
  const funcLocStore = useFuncLocStore();

  const { funcLockList, funcLockFetchError } = storeToRefs(funcLocStore);

  const reloadFetchRequest = () => {
    funcLocStore.fetchFuncLockListAsync();
  };

  return { funcLockList, funcLockFetchError, reloadFetchRequest };
};

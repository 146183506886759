import { STORAGE_KEY } from '@/shared/lib';
import type { OrdersHeaders, OrdersOperList } from '../interfaces';

const getKeys = () => {
  const keys: string[] = [];
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i);
    if (key) keys.push(key);
  }
  return keys;
};

const getOrderKeys = () => {
  return getKeys()
    .filter(key => key.includes(STORAGE_KEY + 'order_'))
    .map(key => ({ key, orderId: key.split('_').at(1)! }));
};

export const getLocalOrders = (orderIds?: string[]) => {
  const orders: OrdersHeaders[] = [];
  const keys = getOrderKeys();
  for (const { key, orderId } of keys) {
    if (orderIds?.length && !orderIds.includes(orderId)) continue;
    const item = localStorage.getItem(key);
    if (!item) continue;
    const order = JSON.parse(item);
    orders.push(order);
  }
  return orders;
};

export const clearLocalOrders = (orderIds?: string[]) => {
  const keys = getOrderKeys();
  for (const { key, orderId } of keys) {
    if (orderIds?.length && !orderIds.includes(orderId)) continue;
    localStorage.removeItem(key);
  }
};

const getOperationKeys = () => {
  return getKeys()
    .filter(key => key.includes(STORAGE_KEY + 'operation_'))
    .map(key => ({ key, orderId: key.split('_').at(1)!, operationId: key.split('_').at(2)! }));
};

export const getLocalOperations = (orderIds?: string[], operationIds?: string[]) => {
  const keys = getOperationKeys();
  const operations: OrdersOperList[] = [];
  for (const { key, orderId, operationId } of keys) {
    if (orderIds?.length && !orderIds.includes(orderId)) continue;
    if (operationIds?.length && !operationIds.includes(operationId)) continue;
    const item = localStorage.getItem(key);
    if (!item) continue;
    const operation = JSON.parse(item);
    operations.push(operation);
  }
  return operations;
};

export const clearLocalOperations = (orderIds?: string[], operationIds?: string[]) => {
  const keys = getOperationKeys();
  for (const { key, orderId, operationId } of keys) {
    if (orderIds?.length && !orderIds.includes(orderId)) continue;
    if (operationIds?.length && !operationIds.includes(operationId)) continue;
    localStorage.removeItem(key);
  }
};

<script setup lang="ts">
import { type UnwrapRef, reactive } from 'vue';
import type { Material } from '@/shared/interfaces';
import { type FormStateMaterial, MaterialsFormItem } from '../..';

interface Props {
  materials: Material[];
  selectedMaterials: FormStateMaterial[];
  showListing?: boolean;
  swipeAble?: boolean;
  disabled?: boolean;
  onDismiss?: (id: string) => void;
  onSelect?: (id: string, checked: boolean, quantity?: string) => void;
}

const { materials, selectedMaterials, showListing, swipeAble, disabled, onDismiss, onSelect } =
  defineProps<Props>();

export interface FormState {
  materials: FormStateMaterial[];
}

const extendMaterials = (materials: Material[]) => {
  return materials.map(material => {
    const selectedMaterial = selectedMaterials?.find(
      item => +item.MATNR === parseInt(material.MATNR, 10),
    );

    return {
      ...material,
      MENGE: selectedMaterial ? selectedMaterial.MENGE : material.MENGE,
      max: material.LABST ? +material.LABST : material.MAX || material.MENGE,
      isUsing: !!selectedMaterial?.isUsing,
    };
  });
};

const formState: UnwrapRef<FormState> = reactive({
  materials: extendMaterials(materials),
});
</script>

<template>
  <div :class="$style.container">
    <a-typography-title :level="4" :class="$style.title">Материалы</a-typography-title>
    <a-form
      name="materialsListForm"
      :model="formState"
      layout="vertical"
      :requiredMark="false"
      :class="$style.form"
    >
      <a-form-item
        v-for="(formMaterial, index) in formState.materials"
        :key="formMaterial.MATNR"
        :class="$style.item"
      >
        <MaterialsFormItem
          :materials="formState.materials"
          :formMaterial="formMaterial"
          :selectedMaterials="selectedMaterials"
          :index="index"
          :showListing="showListing"
          :swipeAble="swipeAble"
          :onDismiss="onDismiss"
          :onSelect="onSelect"
          :disabled="disabled"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
  padding: 12px 12px 12px 8px;
  background-color: var(--colorBgContainer, #fff);
  border-radius: 8px;
}
</style>

import {
  OrderConfirmationStatuses,
  OrderStatus,
  type OrdersHeaders,
  type OrdersHistory,
} from '../interfaces';

export const getHistoryOfOrders = (
  orders: OrdersHeaders[] | undefined,
  history: OrdersHistory[] | undefined,
) => {
  if (!orders || !history) {
    return [];
  }
  return orders.map(order => {
    const orderHistoryItem = history.find(item => item.AUFNR === order.AUFNR);
    if (orderHistoryItem) {
      return orderHistoryItem;
    }
  });
};
export const confirmationStatusesMap = {
  [OrderConfirmationStatuses.PENDING]: OrderStatus.PENDING,
  [OrderConfirmationStatuses.CANCELED]: OrderStatus.CANCELED,
  [OrderConfirmationStatuses.CONFIRMED]: OrderStatus.COMPLETED,
};

export const defectPriorities = [
  {
    name: 'Устранить незамедлительно',
    id: '1',
    color: 'var(--color-danger, #ff3141)',
  },
  {
    name: 'Устранить в течение 3-7 суток',
    id: '2',
    color: 'var(--volcano-5, #007bff)',
  },
  {
    name: 'Устранить в течение 8-14 суток',
    id: '3',
    color: 'var(--gold-5, #ffc53d)',
  },
  {
    name: 'Устранить не позднее 3х месяцев',
    id: '4',
    color: 'var(--yellow-5, #ffec3d)',
  },
  {
    name: 'Устранить не позднее 12-ти месяцев',
    id: '5',
    color: 'var(--cyan-4, #5cdbd3)',
  },
  {
    name: 'Устранить без останова в процессе эксплуатации',
    id: '6',
    color: 'var(--color-success, #00b578)',
  },
];

import { httpFmpClient } from '@/shared/api';
import { API_GET_ORDER_URL, STORAGE_MOCK_ORDERS } from '@/shared/lib';

export const getOrders = (data: string[][], sendHistory = true) => {
  const mockOrders = JSON.parse(window.localStorage.getItem(STORAGE_MOCK_ORDERS) || '[]');
  const request = {
    IV_HISTORY: sendHistory ? 'X' : '',
    IM_AUFNR: [...data, ...mockOrders],
  };

  return httpFmpClient.post(`/rpc/${API_GET_ORDER_URL}/`, request);
};

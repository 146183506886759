<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import BrigadesOrdersWidget from '@/widgets/BrigadesOrdersWidget/ui/BrigadesOrdersWidget.vue';
import { OrdersByTeamsWidget } from '@/widgets/OrdersByTeamsWidget';
import {
  DefectsPanel,
  NoOrders,
  OrderStatus,
  OrderTypesName,
  OrdersGroupInfo,
  OrdersList,
  TasksPanel,
  useGetOrderByType,
} from '@/entities/Orders';
import { NAV_SHIFT_FINISHED } from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { InformationDialog, PageHeading } from '@/shared/ui';
import { BottomButtons } from '@/shared/ui';
import styles from './ShiftFinish.module.scss';

const router = useRouter();
const { ordersByType, ordersFetchError } = useGetOrderByType();
const { userIsRepairer, userIsSupervisor } = storeToRefs(useUserProfileStore());
const infoModalOpen = ref(false);
const finishedStatuses = [
  OrderStatus.COMPLETED,
  OrderStatus.CLOSED,
  OrderStatus.CLOSED_WITH_ERRORS,
  OrderStatus.CANCELED,
];

const finishShiftHandler = () => {
  if (userIsRepairer.value) {
    finishedStatuses.push(OrderStatus.PENDING);
  }
  const allOrdersFinished = ordersByType.value?.[OrderTypesName.ALL].every(order =>
    finishedStatuses.includes(order.STTXT),
  );
  if (allOrdersFinished) {
    router.push({
      name: NAV_SHIFT_FINISHED,
    });
  } else {
    infoModalOpen.value = true;
  }
};
</script>

<template>
  <PageHeading text="Сдать смену" :goBack="true" />
  <div :class="styles.page">
    <BrigadesOrdersWidget v-if="userIsSupervisor" :shiftView="true" />
    <TasksPanel v-else :shiftView="true" />
    <DefectsPanel v-if="userIsRepairer" />
    <NoOrders v-if="!ordersFetchError && !ordersByType?.[OrderTypesName.ALL].length" />
    <OrdersByTeamsWidget v-if="userIsSupervisor" :title="'Все заказы'" />
    <div v-if="!ordersFetchError && ordersByType?.[OrderTypesName.ALL].length && !userIsSupervisor">
      <div v-if="Boolean(ordersByType?.[OrderTypesName.INSPECTION].length)">
        <a-typography-title v-if="!userIsSupervisor" :level="4" :class="styles.title"
          >Осмотры</a-typography-title
        >
        <OrdersList
          v-if="Boolean(ordersByType?.[OrderTypesName.INSPECTION].length)"
          :orders="ordersByType?.[OrderTypesName.INSPECTION]"
        />
      </div>
      <template v-if="userIsRepairer">
        <div v-if="Boolean(ordersByType?.[OrderTypesName.MAINTENANCE].length)">
          <a-typography-title :level="4" :class="styles.title"
            >Техническое обслуживание</a-typography-title
          >
          <OrdersList :orders="ordersByType?.[OrderTypesName.MAINTENANCE]" />
        </div>
        <div v-if="Boolean(ordersByType?.[OrderTypesName.CURRENT_REPAIR].length)">
          <a-typography-title :level="4" :class="styles.title">Текущие ремонты</a-typography-title>
          <OrdersList :orders="ordersByType?.[OrderTypesName.CURRENT_REPAIR]" />
        </div>
      </template>
    </div>
  </div>
  <BottomButtons>
    <a-button @click="finishShiftHandler" type="primary" size="large">Сдать смену</a-button>
  </BottomButtons>
  <InformationDialog
    v-if="infoModalOpen"
    text="Для сдачи смены необходимо закрыть все задания"
    :onClose="() => (infoModalOpen = false)"
  />
</template>

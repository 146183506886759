import { defineStore, storeToRefs } from 'pinia';
import type { CreateMeasureInput } from '@/features/MeasureManagement/interfaces/EditMeasureItem';
import type { ExErrorResponse } from '@/shared/interfaces';
import { getCurrentUTC0Date, getCurrentUTC0Time } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { createMeasure } from '../api/createMeasure';

export const useMeasureManagementStore = defineStore('measureManagement', () => {
  const { handleLoading } = useLoadingStore();
  const { setAlert } = useAlertStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const createMeasureAsync = async (input: CreateMeasureInput[]) => {
    const addCommonDataToInput = (input: CreateMeasureInput[]) => {
      return input.map(item => {
        const date = getCurrentUTC0Date();
        const time = getCurrentUTC0Time();

        return [
          item.POINT, //POINT
          profile.value?.personnel_number ?? '', // DFRDR Табельный номер
          date, //IDATE Текущую дату клиента переводим в UTC0, формат «2022-12-09»
          time, // ITIME //Текущее время переводим в UTC0, формат “ 13:30:07”
          item.CNTRC, //CNTRC
          item.UNITR, //UNITR
        ];
      });
    };
    const responseStatuses: { status: number; data: any }[] = [];
    const tryBlock = async () => {
      for (const item of addCommonDataToInput(input)) {
        const createConfirmResponse = await createMeasure([item]).catch(() => ({
          status: window.navigator.onLine ? 0 : 200,
          data: {},
        }));
        responseStatuses.push({
          status: createConfirmResponse.status,
          data: createConfirmResponse.data,
        });
      }
      const responseHaveErrors = responseStatuses.find(response => response.data?.EX_ERROR?.length);

      if (responseStatuses.some(response => response.status !== 200)) {
        setAlert({
          type: 'error',
          message: `Произошла ошибка в запросе createMeasureAsync`,
        });
      } else if (responseHaveErrors) {
        responseHaveErrors.data.EX_ERROR.forEach((error: ExErrorResponse) => {
          setAlert({
            type: error.RETCODE === 'I' ? 'success' : 'error',
            message: error.ERROR_TEXT,
          });
        });
      } else {
        setAlert({ type: 'success', message: 'Документ измерения создан' });
      }
    };

    await handleLoading({ tryBlock, funcName: 'createMeasureAsync' });
    return !responseStatuses.find(response => response.data?.EX_ERROR?.length);
  };

  return {
    createMeasureAsync,
  };
});

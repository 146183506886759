import { storeToRefs } from 'pinia';
import { useMaterialStore } from '../model/useMaterialStore';

export const useGetMaterialList = () => {
  const materialStore = useMaterialStore();

  const { materialList, materialListFetchError } = storeToRefs(materialStore);

  const reloadFetchRequest = () => {
    materialStore.fetchMaterialListAsync();
  };

  return { materialList, materialListFetchError, reloadFetchRequest };
};

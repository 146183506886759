export enum Roles {
  TECHNOLOGIST = 'MOBTORO_TECHNOLOG', // Технолог
  REPAIRER = 'MOBTORO_REPAIRMAN', // Ремонтник
  EMPLOYEE = 'MOBTORO_ASSET_EMPLOYEE', // Сотрудник актива
  REPAIR_SUPERVISOR = 'MOBTORO_REPAIR_SUPERVISOR',
  TECHNOLOG_SUPERVISOR = 'MOBTORO_TECHNOLOG_SUPERVISOR',
}

export interface Profile {
  user_id: string;
  error: null;
  photo_url: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  enterprise: string;
  enterprise_id: number;
  enterprise_sap_code: string;
  position: string; // Название должности сотрудника
  personnel_number: string; // Табельный номер сотрудника
  shift_schedule_key: string;
  shift_schedule_name: string;
  shift_schedule_type: string;
  schedule_error: string;
  start_time: string | null; // Время начала смены
  end_time: string | null; // Время окончания смены
  working: boolean;
  start_date: string | null;
  startDatetimeWithOffset: string | null; // Время начала смены + 0.5 часа в течение которых можно начать смену
  end_date: string | null;
  endDatetimeWithOffset: string | null; // Время окончания смены + 2 часа в течение которых можно начать смену
  absence_reason: null;
  role_id: Roles | null;
  area_id: number | null;
  area_name: string | null;
  area_sap_code: string | null;
  shift_start_date: string | null; // Дата начала смены
  shift_end_date: string | null; // Дата окончания смены
  work_duration: number;
  break_info: string;
  shift_type: string;
  last_sync_date: string; // Дата последней синхронизации данных
  workgroup: {
    name: string;
    id: string;
    number: string;
  };
}

import { ref, watchEffect } from 'vue';
import type { FileItem } from '@/shared/interfaces';
import { useGetOrders } from './useGetOrders';

export const useGetOrderFileById = (fileId: string) => {
  const { orders } = useGetOrders();
  const orderFile = ref<FileItem | undefined>(undefined);

  watchEffect(() => {
    orders.value?.EX_FILES_LIST.forEach(fileItem => {
      if (fileItem.FILE_ID === fileId) {
        orderFile.value = fileItem;
      }
    });
  });
  return { orderFile };
};

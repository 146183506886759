import { ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { DEFAULT_IM_WERKS } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getSectors } from '../api/getSectors';
import type { SectorsResponse } from '../interfaces';

export const useSectorsStore = defineStore('sectors', () => {
  const sectors = ref<SectorsResponse | undefined>(undefined);
  const sectorsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { profile } = storeToRefs(useUserProfileStore());

  const fetchSectorsAsync = async (silent = false) => {
    const tryBlock = async () => {
      sectorsFetchError.value = false;

      const response = await getSectors(profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS);
      if (handleResponse(response)) {
        sectors.value = response.data as SectorsResponse;
      } else {
        sectorsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchSectorsAsync', noGlobalLoader: silent });
  };

  return {
    sectors,
    sectorsFetchError,
    fetchSectorsAsync,
  };
});

import { onMounted, ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import type { DefectsListInput, FileItem } from '@/shared/interfaces';
import { getAttachmentIsImage, getAttachmentIsVideo } from '@/shared/lib';
import type { DefectStatuses, DefectsListData } from '../interfaces';
import { useDefectsListStore } from '../model/useDefectsListStore';

export const useGetDefectInfo = (input: DefectsListInput, defectId: string) => {
  const defectsListStore = useDefectsListStore();
  const { defectsList, defectsFetchError } = storeToRefs(defectsListStore);
  const filesList = ref<FileItem[]>([]);
  const defectInfo = ref<DefectsListData | undefined>(undefined);

  watchEffect(() => {
    if (defectsList.value) {
      defectInfo.value = defectsList.value?.EX_DEFECT_LIST.find(
        defect => defect.QMNUM === defectId,
      );
      defectsList.value?.EX_FILES_LIST?.forEach(file => {
        if (getAttachmentIsImage(file.FILE_EXT) || getAttachmentIsVideo(file.FILE_EXT)) {
          const findCurrentFile = filesList.value.find(
            fileList => fileList.FILE_ID === file.FILE_ID,
          );

          if (!findCurrentFile) {
            if ((defectId && file.QMNUM && defectId.includes(file.QMNUM)) || !defectId) {
              filesList.value = [...filesList.value, file];
            }
          }
        }
      });
    }
  });

  const reloadFetchRequest = () => {
    defectsListStore.fetchDefectsListAsync(input);
  };

  const updatedLocalDefectStatus = (
    defectId: string,
    status: DefectStatuses,
    statusDefault?: DefectStatuses,
  ) => {
    defectsListStore.updateLocalDefectStatus(defectId, status, statusDefault);
  };

  onMounted(() => {
    if (!defectsList.value && defectId) {
      reloadFetchRequest();
    }
  });
  return { defectInfo, filesList, defectsFetchError, reloadFetchRequest, updatedLocalDefectStatus };
};

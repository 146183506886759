import { ref } from 'vue';
import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getOrderConfirmations } from '../api/getOrderConfirmations';
import { type OrderConfirmationsRequest, type OrderConfirmationsResponse } from '../interfaces';

export const useOrderConfirmationsStore = defineStore('orderConfirmations', () => {
  const orderConfirmations = ref<OrderConfirmationsResponse[] | undefined>(undefined);
  const orderConfirmationsFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchOrderConfirmationsAsync = async (request: OrderConfirmationsRequest[]) => {
    const tryBlock = async () => {
      orderConfirmationsFetchError.value = false;
      const response: AxiosResponse<OrderConfirmationsResponse[]> =
        await getOrderConfirmations(request);
      if (handleResponse(response)) {
        orderConfirmations.value = response.data || {};
      } else {
        orderConfirmationsFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchOrderConfirmationsAsync' });
  };

  return {
    orderConfirmations,
    orderConfirmationsFetchError,
    fetchOrderConfirmationsAsync,
  };
});

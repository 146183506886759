<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBrigadesWidgetData } from '@/entities/Orders';
import { SubdivisionInfo } from '@/entities/Users';
import type { UsersList } from '@/entities/Users/interfaces';
import { UserItem } from '@/entities/Users/ui/UserItem';
import { ArrowBackIcon, ErrorOutlineIcon } from '@/shared/assets/svg';
import { useUserProfileStore } from '@/shared/model';
import { DefaultSlider } from '@/shared/ui';
import styles from './BrigadesOrdersWidget.module.scss';

interface Props {
  shiftView?: boolean;
}

const { shiftView } = defineProps<Props>();

const { brigadesWidgetInfo, teamSelected } = useBrigadesWidgetData();
const userProfileStore = useUserProfileStore();
const { userIsRepairSupervisor } = storeToRefs(userProfileStore);

const someBrigadeHasOvertime = computed(() => {
  return Object.values(brigadesWidgetInfo.value['Все бригады'].capacity || {})?.some(
    item => item.teamMaxCapacity > item.workgroup_capacity,
  );
});

const activeKey = ref();
const onAfterChange = (current: number) => {
  activeKey.value = null;
  const currentTeam = Object.values(brigadesWidgetInfo.value)[current];

  if (currentTeam && userIsRepairSupervisor.value) {
    teamSelected.value = currentTeam.id;
  }
};

onMounted(() => {
  teamSelected.value = undefined;
});
</script>

<template>
  <DefaultSlider :onAfterChange="onAfterChange">
    <div
      :class="styles.panel"
      v-for="brigadesWidgetInfoKey in Object.keys(brigadesWidgetInfo)"
      :key="brigadesWidgetInfoKey"
    >
      <div :class="styles.wrap">
        <a-typography-text :class="styles.text"
          >Панель задач
          <ErrorOutlineIcon :class="styles.errorIcon" v-if="someBrigadeHasOvertime && !shiftView" />
        </a-typography-text>
        <a-typography-title :level="3" style="margin-top: 0" :class="styles.title">{{
          brigadesWidgetInfoKey
        }}</a-typography-title>
        <a-typography-text
          :class="styles.subtitle"
          v-if="brigadesWidgetInfo[brigadesWidgetInfoKey].seniorName"
        >
          Старший бригады
          <span>{{ brigadesWidgetInfo[brigadesWidgetInfoKey].seniorName }}</span></a-typography-text
        >
        <ul :class="styles.list">
          <li
            :class="{
              [styles.item]: true,
              [styles.highlight]:
                brigadesWidgetInfo[brigadesWidgetInfoKey].totalOrders > 0 && shiftView,
            }"
          >
            <b :class="styles.count">{{ brigadesWidgetInfo[brigadesWidgetInfoKey].totalOrders }}</b
            ><span :class="styles.status">Заданий</span>
          </li>
          <li :class="styles.item">
            <b :class="styles.count">{{
              brigadesWidgetInfo[brigadesWidgetInfoKey].completedOrders
            }}</b
            ><span :class="styles.status">Выполнено</span>
          </li>
          <li :class="styles.item">
            <b :class="styles.count">{{
              brigadesWidgetInfo[brigadesWidgetInfoKey].canceledOrders
            }}</b
            ><span :class="styles.status">Отклонено</span>
          </li>
        </ul>
      </div>
      <a-collapse v-model:activeKey="activeKey" :bordered="false" :class="styles.collapse">
        <template #expandIcon="{ isActive }">
          <ArrowBackIcon :class="{ [styles.icon]: true, [styles.rotate]: isActive }" />
        </template>
        <a-collapse-panel :key="brigadesWidgetInfoKey">
          <template v-if="brigadesWidgetInfo[brigadesWidgetInfoKey].capacity">
            <ul :class="styles.brigadesInfo">
              <li
                :class="styles.collapseItem"
                v-for="capacityKey in Object.keys(
                  brigadesWidgetInfo?.[brigadesWidgetInfoKey]?.capacity || {},
                )"
                :key="capacityKey"
              >
                <SubdivisionInfo
                  :capacity="brigadesWidgetInfo?.[brigadesWidgetInfoKey]?.capacity?.[capacityKey]"
                />
              </li>
            </ul>
          </template>
          <template v-else>
            <li
              :class="styles.collapseItem"
              v-for="(user, index) in brigadesWidgetInfo[brigadesWidgetInfoKey].workerData"
              :key="user.personnel_number"
            >
              <UserItem
                :user="{ ...user, phone_number: [user.phone_number] } as UsersList"
                :index="index"
                :swipeAble="false"
                :showUserPosition="false"
                :avatarSize="40"
                textDismiss="Отсутствует"
                hidePhone
              />
            </li>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </DefaultSlider>
</template>

import { DefectsList, defectsList } from '@/entities/Defects';
import { UnitParams } from '@/entities/Equipments';
import { type PageTab } from '@/shared/interfaces';

export const tabs: PageTab[] = [
  {
    name: 'Характеристики',
    component: UnitParams,
    id: 1,
  },
  {
    name: 'Дефекты',
    component: DefectsList,
    id: 2,
    props: {
      items: defectsList,
      isLink: true,
    },
  },
];

import type { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useTeamsStore, useTechnologistStore } from '@/entities/Users';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { fetchUserUpdate } from '../api/fetchUserUpdate';
import type { UserUpdateInput } from '../interfaces/UserUpdateInput';

export const useUserUpdateStore = defineStore('userUpdate', () => {
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();
  const { setTeamWorkerAbsence } = useTeamsStore();
  const { setTechnologistAbsence } = useTechnologistStore();

  const updateUserAsync = async ({
    input,
    userId,
    teamId,
  }: {
    input: UserUpdateInput;
    userId: string;
    teamId?: string;
  }) => {
    const tryBlock = async () => {
      const createConfirmResponse = await fetchUserUpdate(input).catch(() => ({
        //если приложение не подключено к интернету то показываем уведомление об успешной отправке
        status: window.navigator.onLine ? 0 : 200,
      }));
      handleResponse(createConfirmResponse as AxiosResponse, 'Данные успешно изменены');
      if (teamId) setTeamWorkerAbsence(userId, teamId);
      else setTechnologistAbsence(userId);
    };
    await handleLoading({ tryBlock, funcName: 'createMeasureAsync' });
  };

  return { updateUserAsync };
});

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import {
  OrderTypesName,
  getHistoryOfOrders,
  getOrdersByType,
  useGetOrdersHistory,
} from '@/entities/Orders';
import OrderHistoryList from '@/entities/Orders/ui/OrderHistoryList/OrderHistoryList.vue';
import type { PageTab } from '@/shared/interfaces';
import { HandleRequest } from '@/shared/ui';
import { SubTabs } from '@/shared/ui';

const { orderId } = useRoute().params;

const { ordersHistory, equipmentId, ordersHistoryFetchError, reloadFetchRequest } =
  useGetOrdersHistory(orderId as string);

const subTabs = computed(() => {
  // Для вывода табов берем только те заказы по оборудованию которых получили историю
  const filteredOrders =
    ordersHistory.value?.EX_HEADERS?.filter(item => item.EQUNR === equipmentId.value) || [];
  const ordersByType = getOrdersByType(filteredOrders);

  const subTabs: PageTab[] = [
    {
      name: 'Осмотры',
      component: OrderHistoryList,
      id: 1,
      props: {
        historyList: getHistoryOfOrders(
          ordersByType[OrderTypesName.INSPECTION],
          ordersHistory?.value?.EX_HISTORY_ORDER,
        ),
        isLink: true,
      },
    },
    {
      name: 'ТО',
      component: OrderHistoryList,
      id: 2,
      props: {
        historyList: getHistoryOfOrders(
          ordersByType[OrderTypesName.MAINTENANCE],
          ordersHistory?.value?.EX_HISTORY_ORDER,
        ),
        isLink: true,
      },
    },
    {
      name: 'Ремонты',
      component: OrderHistoryList,
      id: 3,
      props: {
        historyList: getHistoryOfOrders(
          ordersByType[OrderTypesName.CURRENT_REPAIR],
          ordersHistory?.value?.EX_HISTORY_ORDER,
        ),
        isLink: true,
      },
    },
  ];
  return subTabs;
});
</script>

<template>
  <HandleRequest
    :noData="!ordersHistory?.EX_HEADERS.length"
    :errorRequest="ordersHistoryFetchError"
    :onRepeat="reloadFetchRequest"
  />

  <div :class="$style.widget">
    <SubTabs :tabs="subTabs" />
  </div>
</template>

<style lang="scss" module>
.widget {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
}
</style>

import { OrderCommentsWidget } from '@/widgets/OrderCommentsWidget';
import { OrderOperationsWidget } from '@/widgets/OrderOperationsWidget';
import type { PageTab } from '@/shared/interfaces';

export const tabs: PageTab[] = [
  {
    name: 'Комментарий',
    component: OrderCommentsWidget,
    id: 1,
  },
  {
    name: 'Операции',
    component: OrderOperationsWidget,
    id: 2,
  },
];

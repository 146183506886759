import { STORAGE_KEY } from '@/shared/lib';

export const useStorage = () => {
  const appStorage =
    // isInsideMobileApp ? storage :
    window.localStorage;

  const setItem = async (key: string, value: string) => {
    try {
      await appStorage.setItem(`${STORAGE_KEY}${key}`, value);
    } catch (e) {
      return null;
    }
  };

  const getItem = async (key: string) => {
    try {
      const item = await appStorage.getItem(`${STORAGE_KEY}${key}`);
      return item;
    } catch (e) {
      return null;
    }
  };

  const clear = async () => appStorage.clear();

  return { setItem, getItem, clear };
};

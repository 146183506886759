<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { RejectionReasons } from '@/features/RejectionReasons';
import { useOrderInputStore } from '@/features/RejectionReasons';
import {
  ChangeShift,
  OrderStatus,
  checkOrderHasCompletedOperations,
  useCurrentOrdersStore,
  useGetOrderInfo,
  useOrderConfirmationsStore,
  useOrdersStore,
} from '@/entities/Orders';
import { checkAllOperationsRejectedAfterConfirmSync } from '@/entities/Orders/lib';
import { NAV_DASHBOARD, NAV_NFC_LIST, NAV_NFC_ORDER } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { DrawerCustomize, PageHeading, PageTabs } from '@/shared/ui';
import { BottomButtons, ModalConfirm } from '@/shared/ui';
import { tabs } from '../lib/tabs';

const route = useRoute();
const router = useRouter();
const { setEndTime } = useCurrentOrdersStore();
const orderInputStore = useOrderInputStore();
const { updateLocalOrderStatus } = useOrdersStore();
const { userIsSupervisor } = storeToRefs(useUserProfileStore());

const orderConfirmationsStore = useOrderConfirmationsStore();
const { orderConfirmations } = storeToRefs(orderConfirmationsStore);

const currentOrderConfirmation = computed(() =>
  orderConfirmations.value?.find(orderConfirmation => orderConfirmation.OrderNumber === orderId),
);

const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);

const orderTabs = computed(() => (order.value?.EX_COMMENT?.length ? tabs : tabs.slice(0, -1)));

const orderName = computed(() => {
  return order.value?.KTEXT || '';
});

const orderHasCompletedOperations = computed(() => {
  return checkOrderHasCompletedOperations(order.value);
});

const openModalComment = ref<boolean>(false);
const commentValue = ref<string>('');
const openReasons = ref<boolean>(false);
const openChangeShift = ref<boolean>(false);
const openFinish = ref<boolean>(false);

const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};

const handleModalChangeShift = (value: boolean) => {
  openChangeShift.value = value;
};

const handleModalFinish = (value: boolean) => {
  setEndTime(+orderId, new Date().getTime().toString());
  openFinish.value = value;
};

const onModalCancel = () => {
  setEndTime(+orderId, '');
  handleModalFinish(false);
};

const handleFinishButton = async (withComment: boolean) => {
  handleSubmit('', order.value?.timeBegin, true, order.value?.timeDuration, withComment);
};

const handleSubmit = async (
  comment: string = '',
  timeBegin?: number,
  finish?: boolean,
  timeDuration?: number,
  withComment?: boolean,
) => {
  if (order.value) {
    await orderInputStore.fetchOrderInputAsync(
      order.value.AUFNR,
      order.value.EX_OPER_LIST,
      null,
      comment,
      timeBegin,
      finish,
      timeDuration,
      withComment ? commentValue.value : undefined,
    );

    updateLocalOrderStatus(orderId as string, OrderStatus.COMPLETED);
  }
  router.push(NAV_DASHBOARD);
};

const handleWorkStart = () => {
  const path = order.value && order.value.countTags > 1 ? NAV_NFC_LIST : NAV_NFC_ORDER;
  router.push({ name: path, params: { orderId } });
};

const handleFinishOrder = async () => {
  handleModalFinish(false);
  openModalComment.value = true;
};

const handleSubmitChangeShift = async (comment: string) => {
  handleSubmit(comment, order.value?.timeBegin);
};

const handleApprove = async () => {
  handleSubmit('', undefined, true, order.value?.timeDuration);
};

const handleModalClose = (submit?: boolean) => {
  openModalComment.value = false;
  setTimeout(() => {
    commentValue.value = '';
  }, 300);
};

const isOrderStatusNotStarted =
  order.value?.STTXT &&
  [OrderStatus.IN_PROGRESS, OrderStatus.NOT_STARTED].includes(order.value?.STTXT);
</script>

<template>
  <div :class="$style.page">
    <PageHeading :text="orderName" :goBack="true" />
    <PageTabs :tabs="orderTabs" :noWrap="[1]" />
    <template v-if="!currentOrderConfirmation && isOrderStatusNotStarted">
      <BottomButtons
        v-if="!orderHasCompletedOperations && order?.STTXT !== OrderStatus.IN_PROGRESS"
      >
        <a-button size="large" :class="$style.button" @click="handleModalReasons(true)"
          >Отклонить заказ</a-button
        >
        <a-button type="primary" size="large" :class="$style.button" @click="handleWorkStart"
          >Начать работу</a-button
        >
      </BottomButtons>
      <BottomButtons v-else>
        <a-button size="large" :class="$style.button" @click="handleModalChangeShift(true)"
          >Передать по смене</a-button
        >
        <a-button
          type="primary"
          size="large"
          :class="$style.button"
          @click="() => handleModalFinish(true)"
          >Завершить</a-button
        ></BottomButtons
      >
    </template>
    <template v-if="currentOrderConfirmation && userIsSupervisor"
      ><BottomButtons>
        <a-button
          v-if="!checkAllOperationsRejectedAfterConfirmSync(order)"
          size="large"
          :class="$style.button"
          @click="handleModalReasons(true)"
          >Отклонить
        </a-button>
        <a-button type="primary" size="large" :class="$style.button" @click="handleApprove"
          >Подтвердить</a-button
        >
      </BottomButtons></template
    >
  </div>
  <RejectionReasons
    v-if="openReasons"
    :onClose="() => handleModalReasons(false)"
    :open="openReasons"
    maintenanceOrRepair
    :orderId="orderId as string"
  />
  <ChangeShift
    :onModal="handleModalChangeShift"
    :open="openChangeShift"
    :onSubmitChangeShift="handleSubmitChangeShift"
  />
  <ModalConfirm
    v-if="openFinish"
    :cancel-text="'Отмена'"
    :ok-text="'Завершить'"
    :onOk="handleFinishOrder"
    :onCancel="onModalCancel"
  >
    Все ли выполненные операции, использованные материалы вы указали?
  </ModalConfirm>
  <DrawerCustomize :title="'Комментарий'" :open="openModalComment" @close="handleModalClose">
    <a-textarea v-model:value="commentValue" show-count :bordered="false" maxlength="40" />
    <div :class="$style.buttons">
      <a-button size="large" :class="$style.button" @click="() => handleFinishButton(false)"
        >Пропустить</a-button
      >
      <a-button
        type="primary"
        size="large"
        :class="$style.button"
        @click="() => handleFinishButton(true)"
        >Отправить</a-button
      >
    </div>
  </DrawerCustomize>
</template>

<style lang="scss" module>
.page {
  padding-bottom: 76px;
}

.button {
  width: 100%;
}

.buttons {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  background: var(--color-white, #fff);
  border-top: 1px solid #eeeeee;

  button {
    width: 100%;
  }
}
</style>

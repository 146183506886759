<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useVirtualList } from '@vueuse/core';
import { type FormStateMaterial, useGetMaterialList } from '@/entities/Materials';
import { SearchIcon } from '@/shared/assets/svg';
import { NAV_RESERVES_SEARCH } from '@/shared/lib/constants';
import { HandleRequest, PageHeading } from '@/shared/ui';

const router = useRouter();

const onSearchClick = () => router.push(NAV_RESERVES_SEARCH);

const { materialList, materialListFetchError, reloadFetchRequest } = useGetMaterialList();

const warehouses = computed(() =>
  materialList.value
    ? Object.groupBy(materialList.value.EX_MATERIAL_LIST, ({ LGOBE }: any) => LGOBE)
    : {},
);

const materials = computed(
  () =>
    Object.values(warehouses.value)
      .flat()
      .sort((a, b) => (a.LGORT === '2003' ? 1 : -1)), // Отображать склад 2003 (ТЦ расходн) с материалами в самом низу
);

const shouldShowWarehouse = (index: number) => {
  const material = materials.value[index];
  return material.LGOBE !== materials.value[index - 1]?.LGOBE;
};

const getItemHeight = (index: number) => {
  const material = materials.value[index];
  let height = 64;
  if (shouldShowWarehouse(index)) height = 93;
  if (material.MAKTX.length > 30) height = height + 18;
  return height;
};

const { list, containerProps, wrapperProps } = useVirtualList<FormStateMaterial>(materials, {
  itemHeight: getItemHeight,
});
</script>

<template>
  <PageHeading text="Запасы" :goBack="true">
    <SearchIcon @click="onSearchClick" />
  </PageHeading>
  <HandleRequest
    :noData="!materialList?.EX_MATERIAL_LIST.length"
    :errorRequest="materialListFetchError"
    :onRepeat="reloadFetchRequest"
  />
  <main :class="$style.main">
    <div v-bind="containerProps" style="height: 79vh">
      <div v-bind="wrapperProps">
        <div v-for="item in list" :key="item.index" :class="$style.container">
          <a-typography-text v-if="shouldShowWarehouse(item.index)" :class="$style.title">
            {{ item.data.LGOBE }}
          </a-typography-text>
          <div :class="$style.card">
            <div>
              <a-typography-text :class="$style.subtitle">
                {{ parseInt(item.data.MATNR) }}
              </a-typography-text>
              <div>{{ item.data.MAKTX }}</div>
            </div>
            <div :class="$style.count">{{ item.data.LABST }} {{ item.data.MSEHT }}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" module>
.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;
}

.title {
  color: var(--colorTextLabel, rgba(0, 0, 0, 0.65));
}

.container:not(:first-child) {
  margin-top: 8px;
}

.card {
  background-color: var(--colorBackground, white);
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subtitle {
    color: var(--colorTextDescription, rgba(0, 0, 0, 0.45));
    font-size: 13px;
  }

  .count {
    white-space: nowrap;
  }
}
</style>

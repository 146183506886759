<script setup lang="ts">
import { useRoute } from 'vue-router';
import { OrderHistoryList, useGetOrderHistory } from '@/entities/Orders';

const { orderId } = useRoute().params;
const { history } = useGetOrderHistory(orderId as string);
</script>

<template>
  <OrderHistoryList v-if="history" :historyList="history" />
</template>

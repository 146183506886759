<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { TmTrInfo, useGetOrderFiles, useGetOrderInfo } from '@/entities/Orders';
import { UsersList } from '@/entities/Users';
import { convertFileListToLinkListItems } from '@/shared/lib';
import { LinksList } from '@/shared/ui';
import styles from './WorksDetailsWidget.module.scss';

const route = useRoute();
const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);
const { files } = useGetOrderFiles(orderId as string);
const fileItems = computed(() => convertFileListToLinkListItems(files.value));
</script>

<template>
  <div :class="styles.widget">
    <div :class="styles.wrap">
      <LinksList v-if="fileItems" :title="'Документы'" :items="fileItems" />
      <UsersList
        :orderId="orderId as string"
        :showPosition="false"
        :executorsIds="[2]"
        supervisor
      />
      <UsersList :orderId="orderId as string" :showPosition="true" :swipeAble="true" />
    </div>
    <TmTrInfo v-if="order" :order="order" />
  </div>
</template>

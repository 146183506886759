import { ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { useTeamsStore } from '@/entities/Users';
import { getHoursBetween } from '@/shared/lib';
import { OrderStatus } from '../interfaces';
import type { BrigadesWidgetInfo } from '../interfaces/OrderConfirmations';
import { useOrdersStore } from '../model/useOrdersStore';

export const useBrigadesWidgetData = () => {
  const { orders } = storeToRefs(useOrdersStore());
  const { teams, teamSelected } = storeToRefs(useTeamsStore());
  const initialInfoValues = {
    totalOrders: 0,
    completedOrders: 0,
    canceledOrders: 0,
    capacity: {},
    id: undefined,
  };
  const initialInfo = {
    ['Все бригады']: {
      ...initialInfoValues,
    },
  };
  const brigadesWidgetInfo = ref<BrigadesWidgetInfo>(initialInfo as BrigadesWidgetInfo);

  watchEffect(() => {
    if (!orders.value || !teams.value) return;
    const brigadesInfo = {
      ['Все бригады']: {
        ...initialInfoValues,
      },
    } as BrigadesWidgetInfo;
    teams.value.data.forEach(team => {
      const teamOrdersIds = team.work_orders.map(order => order.order_id);
      const fmOrdersOfTeam = orders?.value?.EX_HEADERS.filter(order =>
        teamOrdersIds.includes(order.AUFNR),
      );
      brigadesInfo[team.name] = {
        totalOrders: 0,
        completedOrders: 0,
        canceledOrders: 0,
        workerData: team.workers_data,
        seniorName: `${team.supervisor.last_name} ${team.supervisor.first_name.charAt(0)}. ${team.supervisor.middle_name.charAt(0)}.`,
        id: team.id,
      };
      if (!brigadesInfo['Все бригады'].capacity) {
        brigadesInfo['Все бригады'].capacity = {};
      }
      brigadesInfo['Все бригады'].capacity[team.name] = {
        workgroup_capacity: +team.workgroup_capacity,
        team_name: team.name,
        teamMaxCapacity: 0,
      };
      fmOrdersOfTeam?.forEach(order => {
        brigadesInfo['Все бригады'].totalOrders += 1;
        brigadesInfo[team.name].totalOrders += 1;
        if (!brigadesInfo['Все бригады'].capacity) {
          brigadesInfo['Все бригады'].capacity = {};
        }
        brigadesInfo['Все бригады'].capacity[team.name].teamMaxCapacity +=
          getHoursBetween(
            new Date(`${order.GLTRP} ${order.GLUZP}`),
            new Date(`${order.GSTRP} ${order.GSUZP}`),
          ) * team.workers_count;
        const orderIsCompleted = [
          OrderStatus.COMPLETED,
          OrderStatus.CLOSED,
          OrderStatus.CLOSED_WITH_ERRORS,
        ].includes(order.STTXT);
        if (orderIsCompleted) {
          brigadesInfo['Все бригады'].completedOrders += 1;
          brigadesInfo[team.name].completedOrders += 1;
        }
        if (order.STTXT === OrderStatus.CANCELED) {
          brigadesInfo['Все бригады'].canceledOrders += 1;
          brigadesInfo[team.name].canceledOrders += 1;
        }
      });
    });
    brigadesWidgetInfo.value = brigadesInfo;
  });

  return { brigadesWidgetInfo, teamSelected, teams };
};

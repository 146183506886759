<script setup lang="ts">
const props = defineProps<{
  class?: string;
  value: number;
  max: number;
  withPrefixComma?: boolean;
}>();
</script>

<template>
  <div v-if="value" :class="`${$style.counter} ${props.class}`">
    <div>{{ withPrefixComma ? ', ' : '' }}{{ value }}</div>
    <div :class="$style.max">/{{ max }}</div>
  </div>
</template>

<style lang="scss" module>
.counter {
  color: white;
  font-size: 14px;
  display: inline-flex;
}

.max {
  opacity: 0.5;
}
</style>

import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useSectorsStore } from '../model/useSectorsStore';

export const useGetSectors = () => {
  const sectorsStore = useSectorsStore();

  const { sectors, sectorsFetchError } = storeToRefs(sectorsStore);

  const reloadFetchRequest = () => {
    sectorsStore.fetchSectorsAsync();
  };

  onMounted(() => {
    if (!sectors.value) {
      reloadFetchRequest();
    }
  });
  return { sectors, sectorsFetchError, reloadFetchRequest };
};

import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserProfileStore } from '@/shared/model';
import { useUsersListStore } from '../model/useUsersList';

export const useGetUsersList = () => {
  const usersListStore = useUsersListStore();
  const { usersList } = storeToRefs(usersListStore);
  const { profile } = storeToRefs(useUserProfileStore());

  const reloadUserList = async () => {
    if (!profile.value.area_id) return;
    await usersListStore.fetchUsersListAsync(profile.value.area_id.toString());
  };

  watch(
    () => profile.value.area_id,
    () => !usersList.value && reloadUserList(),
  );

  return { usersList, reloadUserList };
};

import { DELIMITER, EXTRA_DELIMITER } from './constants';

export const translit = (str: string = '') => {
  const letters = new Map([
    ['а', 'a'],
    ['б', 'b'],
    ['в', 'v'],
    ['г', 'g'],
    ['д', 'd'],
    ['е', 'e'],
    ['ё', 'e'],
    ['ж', 'j'],
    ['з', 'z'],
    ['и', 'i'],
    ['й', 'i'],
    ['к', 'k'],
    ['л', 'l'],
    ['м', 'm'],
    ['н', 'n'],
    ['о', 'o'],
    ['п', 'p'],
    ['р', 'r'],
    ['с', 's'],
    ['т', 't'],
    ['у', 'u'],
    ['ф', 'f'],
    ['х', 'h'],
    ['ц', 'c'],
    ['ч', 'ch'],
    ['ш', 'sh'],
    ['щ', 'shch'],
    ['ъ', ''],
    ['ы', 'y'],
    ['ь', ''],
    ['э', 'e'],
    ['ю', 'u'],
    ['я', 'ya'],
  ]);

  return [...str].reduce(
    (string, letter) =>
      string +
      (letters.get(letter) ||
        (letters.get(letter.toLowerCase()) === undefined && letter) ||
        (letters.get(letter.toLowerCase()) || '').toUpperCase()),
    '',
  );
};

export const truncateTextCenter = (fullStr: string, stringLength = 20) => {
  if (fullStr.length <= stringLength) {
    return fullStr;
  }

  const stringLengthWithoutDots = stringLength - 3;
  const frontChars = Math.ceil(stringLengthWithoutDots / 2);
  const backChars = Math.floor(stringLengthWithoutDots / 2);

  return fullStr.slice(0, frontChars) + '...' + fullStr.slice(fullStr.length - backChars);
};

/**
 * Функция возвращает хэш строки
 * @param {String} s Строка для которой необходим хэш
 * @return {Number} 32-битное целое число
 */
export const hashCode = (s: string) => {
  let hash = 0,
    i,
    chr;

  if (s.length === 0) return hash;

  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
};

export const sortingStringLine = (str: string, delimiter = ' ') => {
  const stringArray = str.split('\\r').length > 1 ? str.split('\\r') : str.split('\r');

  return stringArray
    .sort((a, b) => {
      const firstSortId = a.split(delimiter);
      const firstSecondId = b.split(delimiter);
      return firstSortId[0] < firstSecondId[0] ? -1 : 1;
    })
    .map(m => m.split(delimiter).slice(1).join(' '))
    .join('\r\n');
};

export const nDivide = (str: string) => {
  if (!str) return;
  const changeDivideStr = str.replaceAll(DELIMITER, EXTRA_DELIMITER);
  const splitStr = changeDivideStr.indexOf(EXTRA_DELIMITER) >= 0
    ? changeDivideStr.split(EXTRA_DELIMITER)
    : changeDivideStr.split(new RegExp(EXTRA_DELIMITER));

  return splitStr.filter(item => item);
};

<script setup lang="ts">
import { h } from 'vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { storeToRefs } from 'pinia';
import { LogoIcon } from '@/shared/assets/svg';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import styles from './Preloader.module.scss';

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '24px',
  },
  spin: true,
});

const loadingStore = useLoadingStore();
const { loading, initialDataLoading } = storeToRefs(loadingStore);
</script>

<template>
  <!--Выводим прелоадер с фоном при загрузке первоначальных данных (авторизация, схема...) -->
  <Transition
    :enter-active-class="styles.fadeEnterActive"
    :leave-active-class="styles.fadeLeaveActive"
    :enter-from-class="styles.fadeEnterFrom"
    :leave-to-class="styles.fadeLeaveTo"
    v-if="Boolean(initialDataLoading.length)"
  >
    <div :class="styles.loading">
      <div :class="styles.logo"><component :is="LogoIcon" /></div>
      <a-spin :class="styles.icon" :indicator="indicator" />
      <div :class="styles.footer">
        <div :class="styles.name">Мобильное ТОРО</div>
        <div :class="styles.text">Проведение плановых обходов и ремонта оборудования.</div>
      </div>
    </div>
  </Transition>

  <!-- Выводим прозрачный прелоадер при подргузке данных в компоненты -->
  <div v-if="Boolean(loading.length) && !initialDataLoading.length" :class="styles.transparent">
    <a-spin />
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { OperationsList, OrderStatus, useGetOrderInfo, useOrdersStore } from '@/entities/Orders';
import { checkOrderIsInspection } from '@/entities/Orders/lib';
import { NAV_OPERATIONS_TAG_SLIDER } from '@/shared/lib';
import { PageHeading } from '@/shared/ui';
import { BottomButtons } from '@/shared/ui';
import styles from './OperationsTag.module.scss';

const route = useRoute();
const router = useRouter();
const { orderId, tagId } = route.params;
const { order } = useGetOrderInfo(orderId as string, tagId as string);
const orderName = (order.value?.EX_OPER_LIST[0] && order.value?.EX_OPER_LIST[0].EQKTX) || '';
const { updateLocalOperationStatusAndDefects } = useOrdersStore();

const handleButton = async () => {
  order.value?.EX_OPER_LIST.forEach(operation => {
    updateLocalOperationStatusAndDefects({
      orderId: operation.AUFNR,
      operationId: operation.VORNR,
      localStatus: OrderStatus.COMPLETED,
    });
  });
  handleBack();
};

const handleBack = () => {
  router.go(-2);
};
</script>

<template>
  <div :class="styles.page">
    <PageHeading :text="orderName" :goBack="true" :onHandleBack="handleBack" />
    <div :class="styles.wrap">
      <OperationsList
        :withTitle="false"
        v-if="order?.EX_OPER_LIST"
        :operations="order.EX_OPER_LIST"
        :url="`${NAV_OPERATIONS_TAG_SLIDER}/${orderId}`"
        :urlTag="true"
        showStatus
        :isInspectionOrder="checkOrderIsInspection(order)"
        isLink
      />
    </div>
    <BottomButtons>
      <a-button @click="handleButton" type="primary" size="large">Все в порядке</a-button>
    </BottomButtons>
  </div>
</template>

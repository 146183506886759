import type { FileItem, UserInformation } from '@/shared/interfaces';

export enum DefectStatuses {
  COMPLETED = 'СОЗА',
  IN_PROGRESS = 'СОРА',
  NEW = 'СООТ',
  CREATED = 'ЗКПР',
}

export interface DefectsListData {
  QMART: string;
  QMNUM: string; // Номер сообщения
  STAT: DefectStatuses; // Статус дефекта (СОЗА и СОРА)
  QMTXT: string; // Краткий текст сообщения
  DESCR: string; // Описание операции
  TPLNR: string; //Техническое место
  PLTXT: string;
  EQUNR: string; // Номер оборудования
  EQKTX: string; // Название оборудования
  PRIOK: string; // Приоритет сообщения
  PRIOKX: string;
  QMNAM: string; // Табельный номер
  QMDAT: string; // Дата сообщения
  MZEIT?: string; // Время сообщения
  LTRMN?: string; // Требуемый срок окончания - дата
  LTRUR?: string; // Требуемый срок окончания - время
  AUSVN?: string; // Начало простоя / неисправности (дата)
  AUZTV?: string; // Начало времени простоя / неисправности (время)
  AUSZT?: number;
  MAUEH?: string;
  EAUSZT?: number; // Продолжительность простоя
  MAUEH_TXT?: string; // Единица измерения простоя
  userInfo?: UserInformation;
  localStatus?: DefectStatuses;
}

export interface DefectsListResponse {
  EX_DEFECT_LIST: DefectsListData[];
  EX_FILES_LIST: FileItem[];
}

export interface IdlesListResponse {
  EX_PROSTOI_LIST: DefectsListData[];
  EX_FILES_LIST: FileItem[];
}

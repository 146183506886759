import { computed } from 'vue';
import type { OrdersHeadersWithOperListAndComponentHistoryAndComponent } from '../interfaces';
import { useGetOrdersHistory } from './useGetOrdersHistory';

export const useGetOrdersHistoryInfo = (orderId: string) => {
  const { ordersHistory, ordersHistoryFetchError, reloadFetchRequest } =
    useGetOrdersHistory(orderId);
  const ordersHistoryInfo = computed(() => {
    let info = {} as OrdersHeadersWithOperListAndComponentHistoryAndComponent;
    if (ordersHistory.value) {
      ordersHistory.value.EX_HEADERS.forEach(item => {
        if (item.AUFNR === orderId) {
          info = {
            ...item,
            EX_HISTORY_ORDER: ordersHistory?.value?.EX_HISTORY_ORDER.find(
              historyItem => historyItem.AUFNR === item.AUFNR,
            ),
            EX_OPER_LIST: ordersHistory?.value?.EX_OPER_LIST.filter(
              historyItem => historyItem.AUFNR === item.AUFNR,
            ),
            EX_COMPONENT: ordersHistory?.value?.EX_COMPONENT.filter(
              historyItem => historyItem.AUFNR === item.AUFNR,
            ),
          };
        }
      });
    }
    return info;
  });

  return { ordersHistoryInfo, ordersHistory, ordersHistoryFetchError, reloadFetchRequest };
};

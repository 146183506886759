import { ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { useTechnologistStore } from '@/entities/Users';
import { OrderStatus } from '../interfaces';
import { useOrdersStore } from '../model/useOrdersStore';

export const useTechnologistWidgetData = () => {
  const initialInfoValues = {
    totalOrders: 0,
    completedOrders: 0,
    canceledOrders: 0,
    id: undefined,
    name: '',
  };
  const initialInfo = {
    ['Все исполнители']: {
      ...initialInfoValues,
    },
  };
  const { orders } = storeToRefs(useOrdersStore());
  const { technologist } = storeToRefs(useTechnologistStore());
  const technologistWidgetInfo = ref(
    initialInfo as {
      [key: string]: {
        totalOrders: number;
        completedOrders: number;
        canceledOrders: number;
        id?: string;
        name?: string;
      };
    },
  );

  watchEffect(() => {
    if (!orders.value || !technologist.value) return;
    const technologistInfo: {
      [key: string]: {
        totalOrders: number;
        completedOrders: number;
        canceledOrders: number;
        id?: string;
        name?: string;
      };
    } = {
      ['Все исполнители']: {
        ...initialInfoValues,
      },
    };
    technologist.value.forEach(unit => {
      const teamOrdersIds = unit.work_orders.map(order => order.order_id);
      const fmOrdersOfTeam = orders?.value?.EX_HEADERS.filter(order =>
        teamOrdersIds.includes(order.AUFNR),
      );
      const unitName = `${unit.last_name} ${unit.first_name.charAt(0)}. ${unit.middle_name.charAt(0)}.`;
      technologistInfo[unitName] = {
        totalOrders: 0,
        completedOrders: 0,
        canceledOrders: 0,
        id: unit.sap_client_id,
        name: unitName,
      };

      fmOrdersOfTeam?.forEach(order => {
        technologistInfo['Все исполнители'].totalOrders += 1;
        technologistInfo[unitName].totalOrders += 1;
        const orderIsCompleted = [
          OrderStatus.COMPLETED,
          OrderStatus.CLOSED,
          OrderStatus.CLOSED_WITH_ERRORS,
        ].includes(order.STTXT);
        if (orderIsCompleted) {
          technologistInfo['Все исполнители'].completedOrders += 1;
          technologistInfo[unitName].completedOrders += 1;
        }
        if (order.STTXT === OrderStatus.CANCELED) {
          technologistInfo['Все исполнители'].canceledOrders += 1;
          technologistInfo[unitName].canceledOrders += 1;
        }
      });
    });
    technologistWidgetInfo.value = technologistInfo;
  });

  return { technologistWidgetInfo, technologist };
};

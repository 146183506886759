<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useGetEquipmentInfo } from '@/entities/Equipments';
import { PageHeading, PageTabs } from '@/shared/ui';
import { nodeTabs, tabs } from '../lib/tabs';

const route = useRoute();
const { equipmentId } = route.params;
const equipmentIdValue = ref(equipmentId as string);
const titlePage = ref<string>('');
const currentTabs = ref(tabs);

watchEffect(() => {
  equipmentIdValue.value = route.params?.equipmentId as string;
  const { equipmentInfo } = useGetEquipmentInfo(equipmentIdValue.value as string);
  titlePage.value = equipmentInfo.value?.EQKTX || '';
  currentTabs.value = equipmentInfo.value?.isNode ? nodeTabs : tabs;
});
</script>

<template>
  <PageHeading :text="titlePage" :goBack="true" />
  <PageTabs :key="equipmentIdValue" :tabs="currentTabs" :noWrap="[0]" />
</template>

<script setup lang="ts">
import { StatusLabels } from '@/shared/interfaces';
import { OrderStatus } from '../../interfaces/Orders';
import styles from './OrderTimeStatus.module.scss';

interface Props {
  status: OrderStatus;
  currentTime?: string;
  timeSpent?: string;
}
const { status, currentTime, timeSpent } = defineProps<Props>();

function getStatusText(): string {
  switch (status) {
    case OrderStatus.IN_PROGRESS:
      return currentTime || '';
    case OrderStatus.COMPLETED:
    case OrderStatus.CLOSED:
    case OrderStatus.CLOSED_WITH_ERRORS:
      return timeSpent || '';
    case OrderStatus.CANCELED:
      return 'Отклонено';
    default:
      return '';
  }
}
</script>

<template>
  <a-typography-text
    :class="{
      [styles.text]: true,
      [styles.canceled]: status === OrderStatus.CANCELED,
    }"
  >
    {{ getStatusText() }}
  </a-typography-text>
</template>

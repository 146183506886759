<script setup lang="ts">
import { computed, onMounted, watch, watchEffect } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import 'ant-design-vue/dist/reset.css';
import { storeToRefs } from 'pinia';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { BottomPanel } from '@/widgets/BottomPanel';
import { Header } from '@/widgets/Header';
import { useOrdersOperPlanStore, useOrdersStore } from '@/entities/Orders';
import { ProfileBlock } from '@/entities/UserProfile';
import { useUsersListStore } from '@/entities/Users';
import { useStorage } from '@/shared/hooks';
import { useQueueRefresh } from '@/shared/hooks/useQueue';
import {
  NAV_DASHBOARD,
  NAV_ROOT,
  NAV_SHIFT_ORDER,
  STORAGE_LAST_ROUTE,
  noBottomPanelPages,
  transparentHeaderPages,
} from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { AlertInfo, Preloader } from '@/shared/ui';
import styles from './App.module.scss';
import AppBanner from './AppBanner.vue';
import { isInsideMobileApp, useReloadButton, useTitle } from './bridge';
import { useSync } from './sync';
import { getTheme } from './theme';

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  onRegisteredSW() {
    console.log(`Service Worker registered.`);
    console.log(`Registration.`);
  },
  onRegisterError() {
    console.log(`Service Worker registration failed`);
  },
  onNeedRefresh() {
    console.log(`Service Worker need refresh`);
  },
  onOfflineReady() {
    console.log(`Service Worker offline ready`);
  },
});

const { setItem } = useStorage();
const userProfileStore = useUserProfileStore();
const { profile } = storeToRefs(userProfileStore);
const ordersOperPlanStore = useOrdersOperPlanStore();
const { ordersOperPlan } = storeToRefs(ordersOperPlanStore);
const ordersStore = useOrdersStore();
const { orders } = storeToRefs(ordersStore);
const usersStore = useUsersListStore();
const { usersList } = storeToRefs(usersStore);

const { sync, updateLastSyncDate } = useSync();

useTitle('МТОРО');
useReloadButton(sync);
useQueueRefresh();

const route = useRoute();
const showBottomPanel = computed(() => !noBottomPanelPages.includes(route.name as string));

const loadingStore = useLoadingStore();
const { initialDataLoading } = storeToRefs(loadingStore);

const currentTheme = computed(() => getTheme(profile.value.role_id));
const transparent = computed(() =>
  transparentHeaderPages.includes(route?.name?.toString() || route.path),
);

onMounted(async () => {
  await sync(true);
});

watchEffect(() => {
  if (ordersOperPlan.value && orders.value && usersList.value) updateLastSyncDate();
});

watch(
  () => route.path,
  path => setItem(STORAGE_LAST_ROUTE, path),
);
</script>

<template>
  <a-config-provider :theme="currentTheme">
    <div
      v-if="!initialDataLoading.length"
      :class="{ [styles.wrapper]: true, [styles.transparent]: transparent }"
    >
      <AppBanner
        :offlineReady="offlineReady"
        :needRefresh="needRefresh"
        :onUpdateClick="() => updateServiceWorker(true)"
        :onOfflineReadyClick="() => (offlineReady = false)"
      />
      <!-- Скрываем эту шапку, если мы внутри мобильного приложения или если находимся на экране принятия смены (корневой странице) -->
      <Header
        v-if="!(isInsideMobileApp || $route.path === NAV_ROOT || $route.name === NAV_SHIFT_ORDER)"
      />
      <ProfileBlock v-if="route.path === NAV_DASHBOARD" />
      <a-layout-content
        :class="{
          [styles.content]: true,
          [styles.withBottomPanel]: showBottomPanel,
        }"
      >
        <RouterView />
      </a-layout-content>
      <BottomPanel v-if="showBottomPanel" />
    </div>
    <Preloader />
    <AlertInfo />
  </a-config-provider>
</template>

import { computed, ref, watchEffect } from 'vue';
import type { OrdersHeadersWithOperListAndComponent, OrdersOperList } from '../interfaces/Orders';
import { useGetOrders } from './useGetOrders';

export const useGetOrderInfo = (orderId: string, tagId?: string) => {
  const { orders, ordersFetchError, reloadOrders } = useGetOrders();
  const order = ref<OrdersHeadersWithOperListAndComponent | undefined>(undefined);
  const getOperationsOfOrder = () => {
    const operations: OrdersHeadersWithOperListAndComponent['EX_OPER_LIST'] = [];
    let operationsHaveVps = false;
    orders.value?.EX_OPER_LIST.forEach(operation => {
      const operationForOrder =
        operation.AUFNR === orderId && (tagId ? operation.TIDNR === tagId : true);
      if (operationForOrder) {
        const vpsOfOperation = orders.value?.EX_VPS.filter(
          vps => vps.VORNR === operation.VORNR && vps.AUFNR === operation.AUFNR,
        );
        if (vpsOfOperation?.length) operationsHaveVps = true;
        operations.push({
          ...operation,
          EX_VPS: vpsOfOperation || [],
        });
      }
    });
    return { operations, operationsHaveVps };
  };

  const getOrderTags = computed(
    () =>
      orders.value?.EX_OPER_LIST.reduce((acc: OrdersOperList[], cur) => {
        // Собираем только с непустыми значениями меток
        if (cur.AUFNR === orderId && !acc.find(item => item.TIDNR === cur.TIDNR) && cur.TIDNR) {
          acc.push(cur);
        }

        return acc;
      }, []) || [],
  );

  const getOperationsByTag = computed(
    () =>
      orders.value?.EX_OPER_LIST.reduce((acc: OrdersOperList[], cur) => {
        // Собираем только с непустыми значениями меток
        if (cur.AUFNR === orderId && cur.TIDNR) {
          acc.push(cur);
        }

        return acc;
      }, []) || [],
  );

  watchEffect(() => {
    const currentOrder = orders.value?.EX_HEADERS.find(order => {
      return order.AUFNR === orderId;
    });
    if (currentOrder) {
      const { operations, operationsHaveVps } = getOperationsOfOrder();
      order.value = {
        ...currentOrder,
        EX_COMMENT: orders.value?.EX_COMMENT.filter(order => order.AUFNR === orderId),
        EX_OPER_LIST: operations,
        EX_COMPONENT: orders.value?.EX_COMPONENT.filter(order => order.AUFNR === orderId),
        operationsHaveVps,
        countTags: getOrderTags.value.length,
      };
    }
  });

  return { order, getOrderTags, getOperationsByTag, ordersFetchError, reloadOrders };
};

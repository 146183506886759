<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useOrderAssigneeStore } from '@/features/OrderManagement';
import { useGetOrderInfo } from '@/entities/Orders/hooks/useGetOrderInfo';
import { UserItem, UsersGroup, useTeamsStore, useTechnologistStore } from '@/entities/Users';
import type {
  TechnologistsResponse,
  UsersList,
  WorkgroupData,
  WorksOrderUserData,
} from '@/entities/Users/interfaces';
import { InfoRegular } from '@/shared/assets/svg';
import { NAV_DASHBOARD, differenceInHoursAndMinutes } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { BottomButtons, DrawerCustomize, HandleRequest, PageHeading } from '@/shared/ui';
import styles from './Reassignment.module.scss';

const route = useRoute();
const router = useRouter();
const orderId = route.params.orderId;
const { order } = useGetOrderInfo(orderId as string);

const orderAssigneeStore = useOrderAssigneeStore();
const { workOrdersDeleteError } = storeToRefs(orderAssigneeStore);

const teamsStore = useTeamsStore();
const { teams } = storeToRefs(teamsStore);

const technologistStore = useTechnologistStore();
const { technologist } = storeToRefs(technologistStore);

const userProfileStore = useUserProfileStore();
const { profile, userIsRepairSupervisor, userIsTechnologSupervisor } =
  storeToRefs(userProfileStore);

const isInfoOpened = ref<boolean>(false);
const selected = ref<string[]>([]);

const currentTeam = ref();
const currentTeams = ref();
const currentTeamId = ref<string>();
const requestLoading = ref(false);
const currentTeamOrder = ref<WorkgroupData | undefined>();
const currentTechnologistOrder = ref<TechnologistsResponse | undefined>();

const onSaveClick = async () => {
  if (order.value) {
    const currentOrder = (
      currentTeamOrder.value ?? currentTechnologistOrder.value
    )?.work_orders.find(workOrder => workOrder.order_id === order.value?.AUFNR);

    if (currentOrder) {
      requestLoading.value = true;
      await orderAssigneeStore.workOrdersDeleteAsync(currentOrder.id);

      if (!workOrdersDeleteError.value) {
        await orderAssigneeStore.orderAssigneeAsync(
          {
            user_id: profile.value.user_id,
            workgroup_id: currentTeamOrder.value ? selected.value[0] : null,
            technologist_id: currentTechnologistOrder.value ? selected.value[0] : null,
            order_id: currentOrder.order_id,
            operations: currentOrder.operations,
            start_date: currentOrder.start_date,
            end_date: currentOrder.end_date,
          } as WorksOrderUserData,
          'Задание успешно переназначено',
          selected.value[0],
        );
        router.push(NAV_DASHBOARD);
        requestLoading.value = false;
      }
    }
  }
};

const handleSelect = (id: string, checked: boolean) => {
  // Доступен только единичный выбор
  if (checked) {
    selected.value = [id];
  } else {
    selected.value = [];
  }
};

watchEffect(() => {
  if (order.value && !requestLoading.value) {
    currentTeamOrder.value = teamsStore.getTeamByOrderId(order.value?.AUFNR);
    currentTechnologistOrder.value = technologistStore.getTechnologistByOrderId(order.value?.AUFNR);
    currentTeam.value = currentTeamOrder.value ?? currentTechnologistOrder.value;
    currentTeamId.value = currentTeamOrder.value?.id ?? currentTechnologistOrder.value?.user_id;
  }
});

watchEffect(() => {
  if (currentTeamId.value) {
    currentTeams.value =
      teams.value?.data?.filter(team => team.id !== currentTeamId.value) ??
      technologist.value?.filter(team => team.user_id !== currentTeamId.value);
  }
});

const getUsersCount = (item: any) => {
  let current = item.workers_count;
  let total = item.workers_count;

  if (userIsTechnologSupervisor.value) {
    current = 1;
    total = 1;

    if (!item.working) {
      if (item.work_duration) {
        total = 0;
      } else {
        current = 0;
        total = 0;
      }
    }
  }
  return {
    current,
    total,
  };
};

const getHoursCount = (item: any) => {
  return {
    current: userIsRepairSupervisor.value ? item.workgroup_capacity : item.working ? 8 : 0,
    total: userIsRepairSupervisor.value ? item.workgroup_capacity_max : +item.work_duration,
  };
};
</script>

<template>
  <PageHeading text="Переназначение" :goBack="true">
    <InfoRegular @click="isInfoOpened = true" />
  </PageHeading>
  <header v-if="currentTeam">
    <div :class="styles.headerTitle">
      {{ order?.KTEXT }}
    </div>
    <div :class="styles.headerSubtitle">
      <span>
        {{ currentTeam.name }}
      </span>
      <span>
        {{
          differenceInHoursAndMinutes(
            new Date(`${order?.GLTRP} ${order?.GLUZP}`),
            new Date(`${order?.GSTRP} ${order?.GSUZP}`),
          )
        }}
      </span>
    </div>
  </header>
  <HandleRequest
    v-if="currentTeam"
    :noData="!currentTeams?.length"
    :textEmpty="`Нет доступных ${userIsRepairSupervisor ? 'бригад' : 'исполнителей'} для переназначения`"
    noFixed
  />
  <div :class="styles.list" v-if="currentTeams?.length">
    <UsersGroup
      v-for="team in currentTeams"
      :key="team.id"
      :title="team.name"
      :usersCount="getUsersCount(team)"
      :hoursCount="getHoursCount(team)"
    >
      <template v-if="userIsRepairSupervisor && team.supervisor" #default>
        <UserItem
          :key="team.supervisor.personnel_number"
          :onSelect="(checked: boolean) => handleSelect(team.id, checked)"
          :user="{ ...team.supervisor, phone_number: [team.supervisor.phone_number] } as UsersList"
          :swipeAble="false"
          :showUserPosition="true"
          :avatarSize="40"
          textDismiss="Отсутствует"
          isSupervisor
          :checked="selected.includes(team.id)"
        />
      </template>
      <template v-if="userIsTechnologSupervisor && team" #default>
        <UserItem
          :key="team.user_id"
          :onSelect="(checked: boolean) => handleSelect(team.user_id, checked)"
          :user="{ ...team, phone_number: [team.phone_number] } as UsersList"
          :swipeAble="false"
          :showUserPosition="true"
          :avatarSize="40"
          textDismiss="Отсутствует"
          :checked="selected.includes(team.user_id)"
        />
      </template>
      <template v-if="!userIsTechnologSupervisor" #more>
        <UserItem
          v-for="(worker, index) in team.workers_data"
          :key="worker.personnel_number"
          :user="{ ...worker, phone_number: [worker.phone_number] } as UsersList"
          :index="index"
          :showUserPosition="true"
          :swipeAble="false"
          hidePhone
        />
      </template>
    </UsersGroup>
  </div>
  <BottomButtons>
    <a-button type="primary" size="large" @click="onSaveClick" :disabled="!selected.length">
      Сохранить
    </a-button>
  </BottomButtons>

  <DrawerCustomize title="Информация" :open="isInfoOpened" @close="isInfoOpened = false">
    <div :class="styles.info">
      Выберите заказ, на который необходимо переназначить
      {{ userIsRepairSupervisor ? 'бригаду' : '' }}
      {{ userIsTechnologSupervisor ? 'исполнителя' : '' }}
    </div>
  </DrawerCustomize>
</template>

import { StatusLabels } from '@/shared/interfaces';

export const nfcList = [
  {
    id: 1,
    name: 'Проверь утечки масла',
    description: '1. Осмотреть привод дробилки на утечки масла из под уплотнений',
    status: StatusLabels.CANCELED,
    statusText: 'Замечание',
    confirm: true,
    count: 2,
  },
  {
    id: 2,
    name: 'Визуальный осмотр состояния роликов',
    description: '1. Осмотреть привод дробилки на утечки масла из под уплотнений',
    status: StatusLabels.COMPLETED,
    statusText: 'Без замечаний',
    confirm: false,
  },
  {
    id: 3,
    name: 'Проверка исправности звуковой сигнализации',
    description: '1. Осмотреть привод дробилки на утечки масла из под уплотнений',
    status: StatusLabels.COMPLETED,
    statusText: 'Без замечаний',
    confirm: false,
  },
  {
    id: 4,
    name: 'Проверка заземления корпуса ЭД',
    description: '1. Осмотреть привод дробилки на утечки масла из под уплотнений',
    status: StatusLabels.COMPLETED,
    statusText: 'Без замечаний',
    confirm: false,
  },
];

<script setup lang="ts">
import { declOfNum } from '@/shared/lib';
import { useGetInfoWidgetOrderData } from '../../hooks/useGetInfoWidgetOrderData';
import styles from './TasksPanel.module.scss';

interface Props {
  shiftView?: boolean;
}

const { shiftView } = defineProps<Props>();

const { tasksInfo } = useGetInfoWidgetOrderData();
const highlightActive = shiftView && tasksInfo.value.inProgress > 0;
</script>

<template>
  <div :class="styles.panel">
    <a-typography-text :class="styles.text">Панель задач</a-typography-text>
    <a-typography-title :level="3" style="margin: 0" :class="styles.title"
      >{{ tasksInfo.total }} {{ declOfNum(tasksInfo.total, ['Задача', 'Задачи', 'Задач']) }} на
      смену</a-typography-title
    >
    <ul :class="styles.list">
      <li
        :class="{
          [styles.item]: true,
          [styles.highlight]: highlightActive,
        }"
      >
        <b :class="styles.count">{{ tasksInfo.inProgress }}</b
        ><span :class="styles.status">В работе</span>
      </li>
      <li
        :class="{
          [styles.item]: true,
          [styles.first]: shiftView,
        }"
      >
        <b :class="styles.count">{{ tasksInfo.completed }}</b
        ><span :class="styles.status">Выполнено</span>
      </li>
      <li :class="styles.item">
        <b :class="styles.count">{{ tasksInfo.canceled }}</b
        ><span :class="styles.status">Отклонено</span>
      </li>
    </ul>
  </div>
</template>

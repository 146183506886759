<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import RejectionReasons from '@/features/RejectionReasons/ui/RejectionReasons.vue';
import {
  OrderItem,
  OrderStatus,
  OrdersGroupInfo,
  type OrdersHeaders,
  type OrdersHeadersWithOperList,
  useOrdersStore,
} from '@/entities/Orders';
import {
  checkAllOperationsRejectedAfterConfirmSync,
  checkOrderIsInspection,
  checkOrderMaintenanceOrRepair,
} from '@/entities/Orders/lib';
import { useTechnologistStore } from '@/entities/Users';
import { useTeamsStore } from '@/entities/Users/model/useTeamsStore';
import { NAV_REASSIGNMENT, NAV_SHIFT_ORDER, getHoursBetween } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import type { WorkgroupDataWithFmpOrders } from '../interfaces';
import { getTeamsWithFmpOrders } from '../lib';
import { getTechnologistWithFmpOrders } from '../lib/getTechnologistWithFmpOrders';

interface Props {
  shiftView?: boolean;
  title?: string;
}
const { shiftView, title } = defineProps<Props>();
const router = useRouter();
const teamsStore = useTeamsStore();
const { teams, teamsOrders, teamsFetchError } = storeToRefs(teamsStore);
const technologistStore = useTechnologistStore();
const { technologist, technologistFetchError } = storeToRefs(technologistStore);
const ordersStore = useOrdersStore();
const { orders, ordersFetchError } = storeToRefs(ordersStore);

const { profile } = storeToRefs(useUserProfileStore());
const openReasons = ref<boolean>(false);
const currentOrderId = ref<string>('');
const orderIsMaintenanceOrRepair = ref<boolean>(false);

const handleReloadTeams = () => {
  teamsStore.fetchTeamsAsync({
    supervisorId: profile.value.user_id,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
};

const handleReloadTechnologist = () => {
  technologistStore.fetchTechnologistAsync({
    supervisorTechnologistId: profile.value.user_id,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
};

const teamsWithFmpOrders = computed(() => {
  if (teams.value?.data.length && orders.value?.EX_HEADERS.length) {
    return getTeamsWithFmpOrders(orders.value, teams.value.data);
  }
  return [];
});

const technologistWithFmpOrders = computed(() => {
  if (technologist.value?.length && orders.value?.EX_HEADERS.length) {
    return getTechnologistWithFmpOrders(orders.value, technologist.value);
  }
  return [];
});

const handleReloadOrders = () => {
  ordersStore.fetchOrdersAsync(teamsOrders.value);
};

const handleReload = () => {
  if (teamsFetchError) {
    handleReloadTeams();
  }
  if (ordersFetchError) {
    handleReloadOrders();
  }
  if (technologistFetchError) {
    handleReloadTechnologist();
  }
};

const onReassign = (orderId: number) => {
  router.push({ name: NAV_REASSIGNMENT, params: { orderId } });
};

const getTeamMaxCapacity = (team: WorkgroupDataWithFmpOrders) => {
  const allFmpOrdersTime = team.fmpOrders.reduce(
    (acc, order) =>
      acc +
      getHoursBetween(
        new Date(`${order.GLTRP} ${order.GLUZP}`),
        new Date(`${order.GSTRP} ${order.GSUZP}`),
      ),
    0,
  );
  return allFmpOrdersTime * team.workers_count;
};

const handleModalReasons = (value: boolean) => {
  openReasons.value = value;
};
const onReject = (itemId: number, maintenanceOrRepair?: boolean) => {
  currentOrderId.value = itemId.toString();
  orderIsMaintenanceOrRepair.value = maintenanceOrRepair ? true : false;

  handleModalReasons(true);
};

const checkOrderCanBeRejectedOrReassigned = (
  order: OrdersHeadersWithOperList,
  reassign?: boolean,
) => {
  if (!reassign && checkAllOperationsRejectedAfterConfirmSync(order)) return false;
  const allowedStatuses = [
    OrderStatus.NOT_STARTED,
    OrderStatus.IN_PROGRESS,
    !reassign && OrderStatus.PENDING,
  ];
  if (checkOrderMaintenanceOrRepair(order) || checkOrderIsInspection(order)) {
    if (allowedStatuses.includes(order?.STTXT)) {
      return true;
    }
  }
};
</script>

<template>
  <div :class="$style.widget">
    <HandleRequest
      :noData="!teams?.data.length || !orders?.EX_HEADERS.length || !technologist?.length"
      :errorRequest="teamsFetchError || ordersFetchError || technologistFetchError"
      :onRepeat="handleReload"
    />
    <a-typography-title
      v-if="!shiftView && teamsWithFmpOrders.length"
      :level="4"
      :class="$style.title"
      >{{ title ?? 'Задания' }}</a-typography-title
    >
    <ul v-if="teamsWithFmpOrders.length" :class="$style.list">
      <li v-for="team in teamsWithFmpOrders" :class="$style.item">
        <OrdersGroupInfo
          v-if="team.fmpOrders.length"
          :brigadeName="team.name"
          :seniorName="`${team.supervisor.last_name} ${team.supervisor.first_name.charAt(0)}. ${team.supervisor.middle_name.charAt(0)}.`"
          :teamMaxCapacity="String(getTeamMaxCapacity(team))"
          :workgroup_capacity="String(team.workgroup_capacity)"
        />
        <ul :class="$style.orders">
          <OrderItem
            v-for="order in team.fmpOrders"
            :key="order.AUFNR + order.STTXT"
            :order="order"
            :isLink="!shiftView"
            :url="shiftView ? NAV_SHIFT_ORDER : undefined"
            :swipeAble="!shiftView"
            :onReassign="
              checkOrderCanBeRejectedOrReassigned(order, true)
                ? (itemId: number) => onReassign(itemId)
                : undefined
            "
            :onReject="
              checkOrderCanBeRejectedOrReassigned(order)
                ? (itemId: number) => onReject(itemId, checkOrderMaintenanceOrRepair(order))
                : undefined
            "
          />
        </ul>
      </li>
    </ul>

    <ul v-if="technologistWithFmpOrders.length" :class="$style.list">
      <template v-for="technologist in technologistWithFmpOrders">
        <li v-if="technologist.fmpOrders.length" :class="$style.item">
          <OrdersGroupInfo
            v-if="technologist.fmpOrders.length"
            :brigadeName="`${technologist.last_name} ${technologist.first_name.charAt(0)}. ${technologist.middle_name.charAt(0)}.`"
            :workgroup_capacity="technologist.working ? '8' : '0'"
            :teamMaxCapacity="String(technologist.work_duration || 0)"
          />
          <ul :class="$style.orders">
            <OrderItem
              v-for="order in technologist.fmpOrders"
              :key="order.AUFNR + order.STTXT"
              :order="order"
              :isLink="!shiftView"
              :url="shiftView ? NAV_SHIFT_ORDER : undefined"
              :swipeAble="!shiftView"
              :onReassign="
                checkOrderCanBeRejectedOrReassigned(order, true)
                  ? (itemId: number) => onReassign(itemId)
                  : undefined
              "
              :onReject="
                checkOrderCanBeRejectedOrReassigned(order)
                  ? (itemId: number) => onReject(itemId, checkOrderMaintenanceOrRepair(order))
                  : undefined
              "
            />
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <RejectionReasons
    v-if="openReasons"
    :onClose="() => handleModalReasons(false)"
    :open="openReasons"
    :maintenanceOrRepair="orderIsMaintenanceOrRepair"
    :orderId="currentOrderId"
  />
</template>

<style lang="scss" module>
.widget {
  .title {
    margin-bottom: 8px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.orders {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>

<script setup lang="ts">
import { h, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { ArrowBackIcon, CircleLoadingIcon, DownloadIcon, NoPhoto } from '@/shared/assets/svg';
import type { FileItem, FilesData } from '@/shared/interfaces';
import { getAttachmentData, getAttachmentIsVideo, getSrcFromBinaryString } from '@/shared/lib';
import { useFileStore } from '@/shared/model/useFiles';
import { ComponentWithZoomMeta } from '../ComponentWithZoomMeta';
import styles from './SliderMedia.module.scss';

interface Props {
  filesList: FileItem[];
  className?: string;
  fullscreenTitle?: string;
  miniSlider?: boolean;
  autoLoadFiles?: boolean;
}

const { filesList, fullscreenTitle, className, miniSlider, autoLoadFiles } = withDefaults(
  defineProps<Props>(),
  {
    className: '',
    miniSlider: true,
  },
);

const fileListStore = useFileStore();
const { filesFetchError } = storeToRefs(fileListStore);

const loading = ref(false);
const multimedia = ref([] as FilesData[]);
const imageError = ref(false);

const slideIndex = ref(0);
const fullScreenMedia = ref([] as FilesData[]);

const slickRef = ref();
const currentSlide = ref(1);

const showFullScreenSlide = (index: number, media: FilesData[]) => {
  fullScreenMedia.value = media;
  slideIndex.value = index;
  document.documentElement.style.overflow = 'hidden';
};

const onInitCarousel = () => {
  slickRef.value.goTo(slideIndex.value, true);
};

const onBeforeChange = (from: number, to: number) => {
  currentSlide.value = to + 1;
};

const handleBack = () => {
  currentSlide.value = 1;
  fullScreenMedia.value = [];
  document.documentElement.style.overflow = '';
};

const handleDownloadFile = async (filesList: FileItem[]) => {
  loading.value = true;
  const fileIds = filesList.map(file => file.FILE_ID);
  await fileListStore.fetchFileAsync(fileIds, true);
  multimedia.value = fileListStore.getFilesByIds(fileIds);
  loading.value = false;
};

onMounted(async () => {
  if (autoLoadFiles && filesList.length) {
    await handleDownloadFile(filesList);
  } else {
    const fileIds = filesList.map(file => file.FILE_ID);
    const storeFiles = fileListStore.getFilesByIds(fileIds);
    if (storeFiles.length) {
      multimedia.value = storeFiles;
    }
  }
});

onUnmounted(() => {
  handleBack();
});

const isMediaVideo = (media: FilesData) =>
  getAttachmentIsVideo(
    filesList.map(file => (file.FILE_ID === media.FILE_ID ? file.FILE_EXT : '')).join(''),
  );

const getMediaSrc = (media: FilesData) => {
  const attachmentObject = getAttachmentData(filesList, media);
  if (!attachmentObject) return;
  return getSrcFromBinaryString(
    attachmentObject.binaryString,
    attachmentObject.attachmentExtension,
  );
};

const handleImageError = () => {
  imageError.value = true;
};
</script>

<template>
  <div :class="{ [styles.imagesWrapper]: !miniSlider }">
    <div
      v-if="filesList.length && !imageError"
      :class="{
        [className]: className,
        [styles.imagesOuter]: true,
        [styles.carousel]: miniSlider === true,
        [styles.error]: filesFetchError,
      }"
    >
      <div
        v-if="!loading && !multimedia.length"
        :class="{ [styles.imageWrap]: true, [styles.download]: true }"
        @click.stop.prevent="handleDownloadFile(filesList)"
      >
        <DownloadIcon />
      </div>
      <div
        v-else-if="loading && !multimedia.length"
        :class="{ [styles.imageWrap]: true, [styles.loading]: true }"
        @click.stop.prevent="handleDownloadFile(filesList)"
      >
        <CircleLoadingIcon />
      </div>

      <template v-else-if="multimedia.length && !loading">
        <div v-if="miniSlider" :class="{ [styles.imageWrap]: true, [styles.carouselWrap]: true }">
          <a-carousel>
            <div
              v-for="(media, index) in multimedia"
              :key="media.FILE_ID"
              @click.stop.prevent="showFullScreenSlide(index, multimedia)"
            >
              <video
                :style="{
                  maxHeight: '219px',
                  width: '100%',
                }"
                v-if="isMediaVideo(media)"
                preload="null"
                :class="styles.videoPreview"
                :src="`${getMediaSrc(media)}#t=0.1`"
                controls
                controlsList="nodownload"
              ></video>

              <img v-else :src="getMediaSrc(media)" @error="handleImageError" alt="" />
            </div>
          </a-carousel>
        </div>
        <template v-else>
          <div :class="styles.imageWrap" @click.stop.prevent="showFullScreenSlide(0, multimedia)">
            <video
              v-if="isMediaVideo(multimedia[0])"
              preload="null"
              :class="styles.videoPreview"
              :src="`${getMediaSrc(multimedia[0])}#t=0.1`"
              controlsList="nodownload"
            ></video>
            <img v-else :src="getMediaSrc(multimedia[0])" @error="handleImageError" alt="" />
          </div>
        </template>
      </template>
    </div>
    <div v-else @click.stop.prevent :class="styles.imageWrap"><NoPhoto /></div>
  </div>
  <div v-if="fullScreenMedia.length" :class="styles.modalCarousel">
    <ComponentWithZoomMeta>
      <div :class="styles.modalCarousel__title">
        <div @click.stop.prevent="handleBack" :class="styles.modalCarousel__back">
          <a-button :class="styles.button" type="primary" :icon="h(ArrowBackIcon)" />
        </div>
        <div :class="styles.modalCarousel__current">
          {{ currentSlide }} из {{ fullScreenMedia.length }}
        </div>
      </div>
      <div v-if="fullscreenTitle" :class="styles.fullscreenTitle">{{ fullscreenTitle }}</div>
      <a-carousel
        :before-change="onBeforeChange"
        :dots="false"
        @init="onInitCarousel"
        ref="slickRef"
      >
        <div
          v-for="media in fullScreenMedia"
          :key="media.FILE_ID"
          :class="styles.modalCarousel__slide"
        >
          <video
            v-if="isMediaVideo(media)"
            controls
            preload="null"
            :class="styles.video"
            :src="`${getMediaSrc(media)}#t=0.1`"
            controlsList="nodownload"
          ></video>
          <a-image v-else :src="getMediaSrc(media)" @error="handleImageError" alt="" />
        </div>
      </a-carousel>
    </ComponentWithZoomMeta>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { APP_URL } from '@/shared/lib';
import { CircleFilledIcon, CircleRegularIcon, NoAvatar } from '@/shared/assets/svg';
import type { UsersList } from '../../interfaces';
import styles from './SelectUsers.module.scss';

interface Props {
  user: UsersList;
  selectedList: string[];
  onSelected: (id: string) => void;
}

const { user, selectedList, onSelected } = defineProps<Props>();
const isImageError = ref(false);
const imageError = computed({
  get: () => isImageError.value,
  set: (newValue) => {
    isImageError.value = newValue;
  },
});

const handleClick = () => onSelected(user.personnel_number);
const handleImageError = () => imageError.value = true;

const userPhoto = computed(() => user.photo_url ? `${APP_URL}${user.photo_url}` : undefined)
const userName = computed(() => `${user.last_name} ${user.first_name.charAt(0)}. ${user.middle_name.charAt(0)}.`);
</script>

<template>
  <div :class="styles.item" @click="handleClick">
    <div :class="styles.overlay">
      <CircleFilledIcon v-if="selectedList.includes(user.personnel_number)" :class="styles.checkboxChecked" />
      <CircleRegularIcon v-else :class="styles.checkbox" />
      <a-avatar
        v-if="userPhoto && !imageError"
        :size="44"
        shape="square"
        :src="userPhoto"
        :class="styles.avatar"
        :loadError="handleImageError"
      />
      <NoAvatar v-else :class="styles.avatar" />
      <div :class="styles.body">
        <a-typography-text
          :class="styles.name"
          >{{ userName }}</a-typography-text
        >
      </div>
    </div>
  </div>
</template>

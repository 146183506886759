<script setup lang="ts">
import { ref } from 'vue';
import { BookInformationIcon } from '@/shared/assets/svg';
import { nDivide } from '@/shared/lib';
import { SwipeModal } from '@/shared/ui';
import type { EditMeasureItem } from '../../interfaces';
import styles from './MeasuringPointsInstruction.module.scss';

interface Props {
  instruction: EditMeasureItem['DESCR'];
}

const { instruction } = defineProps<Props>();
const dividedInstruction = instruction ? nDivide(instruction) : [];

const isShownInstructionModal = ref<boolean>(false);
const handleShowInstructionModal = (show: boolean) => (isShownInstructionModal.value = show);
</script>

<template>
  <button
    v-if="instruction"
    :class="styles.button"
    type="button"
    @click="handleShowInstructionModal(true)"
  >
    <BookInformationIcon />
  </button>
  <SwipeModal
    v-if="isShownInstructionModal"
    :onShowModal="handleShowInstructionModal"
    :isShowModal="isShownInstructionModal"
    title="Инструкция"
  >
    <ul :class="styles.list">
      <li v-for="(item, index) in dividedInstruction" :key="index">
        {{ item }}
      </li>
    </ul>
  </SwipeModal>
</template>

import { ref, watchEffect } from 'vue';
import type { OrdersOperList } from '../interfaces/Orders';
import { useGetOrders } from './useGetOrders';

export const useGetOrderByNfc = (tagId: string, orderId?: string | string[]) => {
  const { orders } = useGetOrders();
  const operation = ref<OrdersOperList | undefined>(undefined);

  //TODO: Временно добавить поиск по отзеркаленной метке
  const tagIdReverseValue = tagId.toUpperCase().split(':').reverse().join(':');
  const tagIds = [tagId, tagIdReverseValue];

  watchEffect(() => {
    operation.value = orders.value?.EX_OPER_LIST?.find(operation => {
      return operation.AUFNR === orderId && tagIds.includes(operation.TIDNR);
    });
  });

  return { operation };
};

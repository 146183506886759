<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import {
  InspectionInfo,
  OperationsList,
  useGetOrderFiles,
  useGetOrderInfo,
} from '@/entities/Orders';
import { UsersList } from '@/entities/Users';
import { convertFileListToLinkListItems } from '@/shared/lib';
import { LinksList } from '@/shared/ui';
import styles from './InspectionWidget.module.scss';

const route = useRoute();
const { orderId } = route.params;
const { order } = useGetOrderInfo(orderId as string);
const { files } = useGetOrderFiles(orderId as string);

const fileItems = computed(() => convertFileListToLinkListItems(files.value));
</script>

<template>
  <div :class="styles.widget">
    <InspectionInfo v-if="order" :order="order" />
    <div :class="styles.wrap">
      <UsersList
        :orderId="orderId as string"
        :showPosition="true"
        :addUser="true"
        :swipeAble="true"
      />
      <OperationsList
        :withTitle="true"
        v-if="order?.EX_OPER_LIST"
        :operations="order.EX_OPER_LIST"
        isLink
      />
      <LinksList v-if="fileItems" :title="'Документы'" :items="fileItems" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { DefectsList, useGetDefectsList } from '@/entities/Defects';
import { HandleRequest, PageHeading } from '@/shared/ui';
import styles from './DefectHistory.module.scss';

const route = useRoute();
const { unitId } = route.params;

const { defectsList, filesList, defectsFetchError, reloadFetchRequest } = useGetDefectsList({
  IM_WERKS: '',
  IM_TPLNR: '',
  IM_EQUNR: [],
  IM_EQUNR_HISTORY: [[unitId as string]],
  IM_QMNUM: '',
});
</script>

<template>
  <PageHeading text="История дефектов" :goBack="true" />
  <HandleRequest
    :noData="!defectsList?.EX_DEFECT_LIST.length"
    :errorRequest="defectsFetchError"
    :onRepeat="reloadFetchRequest"
  />
  <div v-if="defectsList" :class="styles.page">
    <DefectsList :items="defectsList.EX_DEFECT_LIST" :filesList="filesList" :autoLoadFiles="true" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { EquipmentParams, useGetEquipList, useGetEquipmentInfo } from '@/entities/Equipments';
import { useGetEquipmentByParentNodes } from '@/entities/Equipments/hooks/useGetEquipmentByParentNodes';
import {
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_DOCUMENT_DETAIL,
  convertFileListToLinkListItems,
} from '@/shared/lib';
import { HandleRequest, LinksList } from '@/shared/ui';
import styles from './EquipmentCharacteristicsWidget.module.scss';

const { equipmentId } = useRoute().params;

const { equipmentListFetchError, reloadFetchRequest } = useGetEquipList();
const { equipmentInfo } = useGetEquipmentInfo(equipmentId as string);
const { equipmentsNodesByParentNodes } = useGetEquipmentByParentNodes();

const fileItems = computed(() =>
  convertFileListToLinkListItems(equipmentInfo.value?.EX_FILES_LIST, NAV_EQUIPMENT_DOCUMENT_DETAIL),
);

const unitsItems = computed(() => {
  // выводим только тех у кого есть вхождение УДЕФ так как они узлы
  const unitsList = equipmentsNodesByParentNodes?.value?.[equipmentId as string] ?? [];

  // Сортируем по возрастанию EQUNR
  return unitsList
    .sort((a, b) => (a.EQUNR < b.EQUNR ? -1 : 1))
    .map(item => ({
      id: item.EQUNR,
      label: parseInt(item.EQUNR, 10).toString(),
      value: item.EQKTX,
      url: `${NAV_EQUIPMENT_DETAIL}/${item.EQUNR}`,
    }));
});
</script>

<template>
  <div :class="styles.page">
    <HandleRequest
      :noData="!equipmentInfo"
      :errorRequest="equipmentListFetchError"
      :onRepeat="reloadFetchRequest"
    />
    <template v-if="equipmentInfo">
      <EquipmentParams :equipmentInfo="equipmentInfo" />
      <div :class="styles.wrap">
        <!-- <UnitsList /> -->
        <LinksList v-if="unitsItems?.length" :title="'Узлы'" :items="unitsItems" />
        <LinksList v-if="fileItems?.length" :title="'Документы'" :items="fileItems" />
      </div>
    </template>
  </div>
</template>

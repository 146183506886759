import { httpBackendClient } from '@/shared/api';
import { API_ORDERS_OPER_PLAN } from '@/shared/lib';
import type { OrdersOperPlanRequest } from '@/entities/Orders/interfaces/Orders';

export const fetchOrdersOperPlan = async (request: OrdersOperPlanRequest) => {
  return await httpBackendClient.get(
    API_ORDERS_OPER_PLAN
      .replace(':userId', request.userId)
      .replace(':startDate', request.startDate)
      .replace(':endDate', request.endDate),
  );
};

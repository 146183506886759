import { onMounted, ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { EquipmentStatuses } from '@/shared/interfaces';
import type { EquipmentInfo } from '../interfaces';
import { useEquipmentStore } from '../model/useEquipmentStore';

export const useGetEquipmentInfo = (equipmentId: string, tagId?: string) => {
  const equipmentStore = useEquipmentStore();
  const { equipmentList } = storeToRefs(equipmentStore);
  const equipmentInfo = ref<EquipmentInfo | undefined>(undefined);

  onMounted(() => {
    if (!equipmentList.value) {
      equipmentStore.fetchEquipmentListAsync();
    }
  });

  watchEffect(() => {
    const currentEquipment = equipmentList.value?.EX_EQUIP_LIST?.find(item =>
      tagId ? item.TIDNR === tagId : item.EQUNR === equipmentId,
    );
    if (currentEquipment) {
      equipmentInfo.value = {
        ...currentEquipment,
        EX_FILES_LIST:
          equipmentList.value?.EX_FILES_LIST.filter(file => file.EQUNR === equipmentId) || [],
        EX_MEASURE:
          equipmentList.value?.EX_MEASURE.filter(item => item.EQUNR === equipmentId) || [],
        isNode: (currentEquipment.STTXU?.split(' ') || []).includes(
          EquipmentStatuses.DEFECTS_LEVEL,
        ),
      };
    }
  });

  return {
    equipmentInfo,
  };
};

export const getTimerValue = (timeBegin: number | string, timer: boolean = true) => {
  const diffSeconds = timer
    ? Math.trunc((new Date().getTime() - +timeBegin) / 1000)
    : +timeBegin / 1000;
  const getHours = Math.trunc(diffSeconds / 3600);
  const getMinutes = Math.trunc((diffSeconds / 60) % 60);

  return `${getHours < 10 ? `0${getHours}` : getHours}:${getMinutes < 10 ? `0${getMinutes}` : getMinutes}`;
};

export const getTimeDurationValue = (time: string) => {
  const exTime = time.split(':');

  if (exTime.length > 1) {
    const getHours = parseInt(exTime[0]) * 3600;
    const getMinutes = parseInt(exTime[1]) * 60;
    const getTime = (getHours + getMinutes) * 1000;

    return getTime || 0;
  }

  return 0;
};

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { NoOrders, OrderTypesName, OrdersList, useGetOrderByType } from '@/entities/Orders';
import { NAV_SHIFT_ORDER } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest } from '@/shared/ui';

const { ordersByType, ordersFetchError, reloadOrders } = useGetOrderByType();

const { userIsRepairer } = storeToRefs(useUserProfileStore());
</script>

<template>
  <HandleRequest
    :noData="false"
    :errorRequest="ordersFetchError"
    :onRepeat="reloadOrders"
    :noFixed="true"
  />
  <NoOrders v-if="!ordersFetchError && !ordersByType?.[OrderTypesName.ALL].length" />
  <template v-if="!ordersFetchError && ordersByType">
    <div>
      <a-typography-title
        v-if="userIsRepairer && ordersByType?.[OrderTypesName.INSPECTION].length"
        :level="4"
        :class="$style.title"
        >Осмотры</a-typography-title
      >
      <OrdersList
        :orders="
          userIsRepairer
            ? ordersByType?.[OrderTypesName.INSPECTION]
            : ordersByType?.[OrderTypesName.ALL]
        "
        :minimalView="true"
        :url="userIsRepairer ? NAV_SHIFT_ORDER : undefined"
      />
    </div>
    <div :class="$style.maintenances" v-if="userIsRepairer">
      <div v-if="Boolean(ordersByType?.[OrderTypesName.MAINTENANCE].length)">
        <a-typography-title :level="4" :class="$style.title"
          >Техническое обслуживание</a-typography-title
        >
        <OrdersList
          :isLink="userIsRepairer ? true : false"
          :url="userIsRepairer ? NAV_SHIFT_ORDER : undefined"
          :orders="ordersByType?.[OrderTypesName.MAINTENANCE]"
        />
      </div>
      <div v-if="Boolean(ordersByType?.[OrderTypesName.CURRENT_REPAIR].length)">
        <a-typography-title :level="4" :class="$style.title">Текущие ремонты</a-typography-title>
        <OrdersList
          :isLink="userIsRepairer ? true : false"
          :url="userIsRepairer ? NAV_SHIFT_ORDER : undefined"
          :orders="ordersByType?.[OrderTypesName.CURRENT_REPAIR]"
        />
      </div>
    </div>
  </template>
</template>

<style lang="scss" module>
.maintenances {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
}

.title {
  margin-bottom: 4px;
}
</style>

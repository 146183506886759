<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useGetEquipmentFileById } from '@/entities/Equipments';
import { useGetOrderFileById } from '@/entities/Orders';
import { NAV_ORDER_DOCUMENT_DETAIL } from '@/shared/lib';
import { PageHeading } from '@/shared/ui';
import AttachmentViewer from '@/shared/ui/AttachmentViewer/AttachmentViewer.vue';
import styles from './DocumentDetail.module.scss';

const route = useRoute();
const { id } = route.params;

const getFileFunc =
  route.name === NAV_ORDER_DOCUMENT_DETAIL ? useGetOrderFileById : useGetEquipmentFileById;
const { orderFile } = getFileFunc(id as string);
</script>

<template>
  <PageHeading :text="orderFile?.FILE_NAME ?? ''" :goBack="true" />
  <div :class="styles.page">
    <AttachmentViewer v-if="orderFile" :file="orderFile" />
  </div>
</template>

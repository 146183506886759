import { StatusLabels } from '@/shared/interfaces';
import { DefectStatuses } from '../interfaces';

export const getDefectStatusData = (status: string, statusValue?: string) => {
  const statusArray = status?.split(' ') || [];

  if (statusArray.includes(DefectStatuses.IN_PROGRESS)) {
    return {
      status: StatusLabels.IN_PROGRESS,
      statusText: statusValue?.toString() ?? 'В работе',
    };
  } else if (statusArray.includes(DefectStatuses.NEW)) {
    return {
      status: StatusLabels.NEW,
      statusText: 'Новый',
    };
  } else {
    return {
      status: StatusLabels.COMPLETED,
      statusText: 'Устранен',
    };
  }
};
export const getDefectStatusName = (value?: number, type?: string) => {
  if (!value && !type) return;

  return `${value} ${type}`;
};

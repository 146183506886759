<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { OrderStatus, useGetOrderInfo, useOrdersStore } from '@/entities/Orders';
import { SelectedIcon } from '@/shared/assets/svg';
import { NAV_DASHBOARD } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { DrawerCustomize, HandleRequest, InformationDialog } from '@/shared/ui';
import { useGetRejectReasons } from '../hooks/useGetRejectReasons';
import type { RejectReason } from '../interfaces';
import { useCreateConfirmStore } from '../model/useCreateConfirmStore';
import { useOrderInputStore } from '../model/useOrderInputStore';
import styles from './RejectionReasons.module.scss';

interface Props {
  open: boolean;
  orderId: string;
  onClose: () => void;
  onAfterSubmit?: () => void;
  maintenanceOrRepair?: boolean;
  operationId?: string;
}

const { open, orderId, onClose, onAfterSubmit, maintenanceOrRepair, operationId } =
  defineProps<Props>();

const { rejectReasons, rejectReasonsFetchError, reloadFetchRequest } = useGetRejectReasons();
const createConfirmStore = useCreateConfirmStore();
const orderInputStore = useOrderInputStore();
const { updateLocalOrderStatus, updateLocalOperationStatusAndDefects } = useOrdersStore();

const reasonsWithAdditionalOption = computed(() => [
  ...(rejectReasons.value?.EX_REASON_LIST || []),
  { GRUND: '', GRDTX: 'Добавить комментарий...' },
]);

const value = ref<string>('');
const reason = ref<string>();
const showTextarea = ref<boolean>(false);
const errorModalOpen = ref<boolean>(false);

const { userIsSupervisor } = storeToRefs(useUserProfileStore());

const router = useRouter();

const handleToggleErrorModal = (value: boolean) => (errorModalOpen.value = value);

const handleReason = (item: RejectReason) => {
  if (item.GRUND) {
    reason.value = item.GRUND;
  } else if (!item.GRUND && reason.value) {
    showTextarea.value = true;
  } else {
    errorModalOpen.value = true;
  }
};

const handleClose = (submit?: boolean) => {
  if (showTextarea.value && !submit) {
    showTextarea.value = false;
    value.value = '';
    return;
  }
  onClose();

  setTimeout(() => {
    reason.value = undefined;
    showTextarea.value = false;
    value.value = '';
  }, 300);
};

const onSubmit = async () => {
  const { order } = useGetOrderInfo(orderId);
  if (order.value) {
    if (!operationId) {
      maintenanceOrRepair
        ? await orderInputStore.fetchOrderInputAsync(
            order.value.AUFNR,
            order.value.EX_OPER_LIST,
            reason.value ? +reason.value : null,
            value.value,
            undefined,
            false,
            order.value?.timeDuration,
          )
        : await createConfirmStore.fetchCreateConfirmAsync(
            order.value.AUFNR,
            order.value.EX_OPER_LIST,
            reason.value,
            value.value,
          );

      updateLocalOrderStatus(orderId as string, OrderStatus.CANCELED);
      router.push(NAV_DASHBOARD);
    } else {
      updateLocalOperationStatusAndDefects({
        orderId,
        operationId,
        localStatus: OrderStatus.COMPLETED,
        localCancel: true,
        localRejection: {
          ReasonId: reason.value ? +reason.value : null,
          Comment: value.value || '',
        },
        ...(userIsSupervisor.value ? { localChecked: true } : {}),
      });
    }
  }

  await onAfterSubmit?.();
  handleClose(true);
};
</script>

<template>
  <DrawerCustomize
    :title="!showTextarea ? 'Выберите причину' : 'Комментарий'"
    :open="open"
    @close="() => handleClose()"
  >
    <template v-if="!rejectReasonsFetchError && rejectReasons?.EX_REASON_LIST.length">
      <div v-if="!showTextarea" :class="styles.list">
        <div
          v-for="item in reasonsWithAdditionalOption"
          :key="item.GRUND"
          :class="styles.item"
          @click="handleReason(item)"
        >
          {{ item.GRDTX }}
          <SelectedIcon v-if="item.GRUND === reason" />
        </div>
      </div>
      <a-textarea v-else v-model:value="value" show-count :bordered="false" maxlength="40" />
      <div :class="styles.buttons">
        <a-button
          type="primary"
          size="large"
          :class="styles.button"
          @click="onSubmit"
          :disabled="(!reason && !showTextarea) || (showTextarea && value.trim().length < 3)"
          >Отправить</a-button
        >
      </div></template
    >
    <HandleRequest
      v-else
      :noData="!rejectReasons?.EX_REASON_LIST.length"
      :errorRequest="rejectReasonsFetchError"
      :onRepeat="reloadFetchRequest"
    />
    <InformationDialog
      v-if="errorModalOpen"
      title="Ошибка"
      text="Для ввода комментария необходимо выбрать причину отклонения"
      :onClose="() => handleToggleErrorModal(false)"
    />
  </DrawerCustomize>
</template>

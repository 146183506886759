import { ref, watchEffect } from 'vue';
import type { FileItem } from '@/shared/interfaces';
import { useGetEquipList } from './useGetEquipList';

export const useGetEquipmentFileById = (fileId: string) => {
  const { equipmentList } = useGetEquipList();
  const orderFile = ref<FileItem | undefined>(undefined);

  watchEffect(() => {
    equipmentList.value?.EX_FILES_LIST.forEach(fileItem => {
      if (fileItem.FILE_ID === fileId) {
        orderFile.value = fileItem;
      }
    });
  });
  return { orderFile };
};

import { DocumentIcon, MediaFileIcon, Pdf } from '@/shared/assets/svg';
import { imagesExtension, videosExtension } from '@/shared/lib/constants';

export const fileIconRenderHelper = (ext: string) => {
  if ([...imagesExtension, ...videosExtension].includes(ext)) {
    return MediaFileIcon;
  }
  if (ext === 'pdf') {
    return Pdf;
  } else {
    return DocumentIcon;
  }
};

export enum SymptomCodeGropes {
  TORSO = '1000003', // ТОРО
}

export interface Symptom {
  CODEGRUPPE: SymptomCodeGropes; // Группа кодов
  KURZTEXT: string; // Краткое описание группы
  CODE: string; //Код
  CODE_TXT: string; //Краткий текст для кода
  RBNR: string; //Каталог кодов ТОРО
}

export interface SymptomsResponse {
  EX_TORO_SYMPTOMS: Symptom[];
}

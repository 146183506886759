<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRejectReasonsStore } from '@/features/RejectionReasons';
import { ErrorOutlineIcon } from '@/shared/assets/svg';

interface Props {
  reasonId: number;
}

const { reasonId } = defineProps<Props>();

const { rejectReasons } = storeToRefs(useRejectReasonsStore());

const rejectReasonText = computed(() => {
  const rejectReason = rejectReasons.value?.EX_REASON_LIST.find(reason => {
    return parseInt(reason.GRUND) === reasonId;
  });

  return rejectReason?.GRDTX;
});
</script>

<template>
  <div :class="$style.reason">
    <div :class="$style.wrap">
      <a-typography-text :class="$style.label">Причина отклонения</a-typography-text>
      <ErrorOutlineIcon :class="$style.icon" />
    </div>
    <a-typography-text :class="$style.text"> {{ rejectReasonText }}</a-typography-text>
  </div>
</template>

<style lang="scss" module>
.reason {
  padding: 12px;
  background-color: #fff;
  background: var(--colorErrorOutline, #fff1f0);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.label {
  color: var(--color-weak, #999);
  font-size: 15px;
  line-height: 140%;
}
.icon {
  width: 24px;
  height: 24px;
  color: var(--red-5, #ff4d4f);
}
.text {
  font-size: 17px;
  line-height: 140%;
}
</style>
